//lib
import * as R from "ramda";
import {AxiosError} from "axios";
import {useQuery, QueryCache} from "react-query";

//src
import {axiosCall} from "services/api";
import {endpoints, queryConstants} from "constants/";

export const useFetchPortfolioValue = () => {
  return useQuery(
    queryConstants.PORTFOLIO_VALUE,
    () => {
      return axiosCall({url: endpoints.PORTFOLIO_VALUE});
    },
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );
};

export const useFetchUnrealizedGainsOverallValue = () => {
  return useQuery(
    queryConstants.UNREALIZED_GAINS_OVERALL_VALUE,
    () => {
      return axiosCall({url: endpoints.UNREALIZED_GAINS_OVERALL_VALUE});
    },
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );
};

export const useFetchTotalAssets = () => {
  const queryCache = new QueryCache({
    onError: error => {
      console.log(error);
    },
    onSuccess: data => {
      console.log(data);
    },
  });
  return useQuery(
    queryConstants.TOTAL_ASSETS,
    () => {
      return axiosCall({url: endpoints.TOTAL_ASSETS});
    },
    {
      onSuccess: () => {},
      onError: () => {
        queryCache.clear();
      },
    },
  );
};

export const useFetchProfitLossHistory = (
  activeFilter: {
    name: string;
    value: string;
  },
  dateRange: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  return useQuery(
    [queryConstants.PROFIT_LOSS_HISTORY, activeFilter, dateRange],
    () => {
      return axiosCall({
        url: endpoints.PROFIT_LOSS_HISTORY(activeFilter, dateRange),
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );
};

export const useFetchTotalTransactionsCount = (
  activeFilter: {
    name: string;
    value: string;
  },
  dateRange?: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  return useQuery(
    [
      queryConstants.TOTAL_TRANSACTIONS_COUNT_DASHBOARD,
      activeFilter,
      dateRange,
    ],
    () => {
      return axiosCall({
        url: endpoints.TOTAL_TRANSACTIONS_COUNT(activeFilter, dateRange),
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );
};

export const useFetchTotalAssetsHistory = (
  currenciesArray: string[],
  activeFilter: {
    name: string;
    value: string;
  },
  dateRange: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  return useQuery(
    [
      queryConstants.TOTAL_ASSETS_HISTORY,
      currenciesArray,
      activeFilter,
      dateRange,
    ],
    () => {
      return axiosCall({
        url: endpoints.TOTAL_ASSETS_HISTORY(
          currenciesArray,
          activeFilter,
          dateRange,
        ),
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );
};
export const useFetchBestWorstTrades = (
  activeFilter: {
    name: string;
    value: string;
  },
  dateRange: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  return useQuery(
    [queryConstants.BEST_WORST_TRADES, activeFilter, dateRange],
    () => {
      return axiosCall({
        url: endpoints.BEST_WORST_TRADES(activeFilter, dateRange),
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );
};

type useFetchTopTradersProps = {
  activeFilter: {
    name: string;
    value: string;
  };
  isViewByLocallyActive: boolean;
  taxResidentCountry: string;
  dateRange: {
    startDate: string;
    endDate: string;
  } | null;
  handleError: (responseCode: number | undefined) => void;
};
export const useFetchTopTraders = (props: useFetchTopTradersProps) => {
  const {
    activeFilter,
    dateRange,
    handleError,
    isViewByLocallyActive,
    taxResidentCountry,
  } = props;
  return useQuery(
    [
      queryConstants.TOP_TRADERS,
      activeFilter,
      isViewByLocallyActive,
      taxResidentCountry,
      dateRange,
    ],
    () => {
      return axiosCall({
        url: endpoints.TOP_TRADERS(
          activeFilter,
          isViewByLocallyActive,
          taxResidentCountry,
          dateRange,
        ),
      });
    },
    {
      onSuccess: () => {},
      onError: (error: AxiosError) => {
        handleError(R.pathOr(undefined, ["response", "status"], error));
      },
    },
  );
};

export const useFetchTotalTradeTransactionsWidgetData = (
  activeFilter: {
    name: string;
    value: string;
  },
  dateRange: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  return useQuery(
    [queryConstants.TOTAL_TRADE_TRANSACTIONS_DATA, activeFilter, dateRange],
    () => {
      return axiosCall({
        url: endpoints.TOTAL_TRADE_TRANSACTIONS_DATA(activeFilter, dateRange),
      });
    },
  );
};

export const useFetchRankAndPercentile = (taxResidentCountry: string) => {
  return useQuery(
    queryConstants.FETCH_RANK_AND_PERCENTILE,
    () => {
      return axiosCall({
        url: endpoints.FETCH_RANK_AND_PERCENTILE(taxResidentCountry),
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );
};

type useFetchPerformanceChartProps = {
  taxResidentCountry: string;
  handleError: (responseCode: number | undefined) => void;
};
export const useFetchPerformanceChartData = (
  props: useFetchPerformanceChartProps,
) => {
  const {taxResidentCountry, handleError} = props;
  return useQuery(
    queryConstants.FETCH_PERFORMANCE_CHART_DATA,
    () => {
      return axiosCall({
        url: endpoints.FETCH_PERFORMANCE_CHART_DATA(taxResidentCountry),
      });
    },
    {
      onSuccess: () => {},
      onError: (error: AxiosError) => {
        handleError(R.pathOr(undefined, ["response", "status"], error));
      },
    },
  );
};

export const useFetchAssetHoldingTabularData = () => {
  return useQuery(
    queryConstants.FETCH_ASSET_HOLDING_TABULAR_DATA,
    () => {
      return axiosCall({
        url: endpoints.FETCH_ASSET_HOLDING_TABULAR_DATA,
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );
};

export const useFetchTaxSummariesData = (taxYear: number | string) => {
  return useQuery(
    [queryConstants.FETCH_TAX_SUMMARIES_TAX_MANAGEMENT, taxYear],
    () => {
      return axiosCall({
        url: endpoints.FETCH_TAX_SUMMARIES(taxYear),
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
      enabled: !!taxYear,
    },
  );
};

type useFetchAssetHoldingHistoryDataTypes = {
  handleError: (responseCode: number | undefined) => void;
};

export const useFetchAssetHoldingHistoryData = (
  props: useFetchAssetHoldingHistoryDataTypes,
) => {
  const {handleError} = props;
  return useQuery(
    [queryConstants.FETCH_ASSET_HOLDING_HISTORY_DATA],
    () => {
      return axiosCall({
        url: endpoints.FETCH_ASSET_HOLDING_HISTORY_DATA(),
      });
    },
    {
      onSuccess: () => {},
      onError: (error: any) => {
        handleError(R.pathOr(undefined, ["response", "status"], error));
      },
    },
  );
};

export const useFetchTransactionsCountYearly = (taxYear: string) => {
  return useQuery(
    [queryConstants.FETCH_TRANSACTIONS_COUNT_YEARLY, taxYear],
    () => {
      return axiosCall({
        url: endpoints.FETCH_TRANSACTION_COUNT_YEARLY(taxYear),
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
      enabled: !!taxYear,
    },
  );
};

export const useFetchTotalTransactionsPerYear = () => {
  return useQuery(
    [queryConstants.FETCH_TOTAL_TRANSACTIONS_PER_YEAR],
    () => {
      return axiosCall({
        url: endpoints.FETCH_TOTAL_TRANSACTIONS_PER_YEAR,
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );
};

export const useFetchTotalPortfolioValue = (taxYear: number | string) => {
  return useQuery(
    [queryConstants.TOTAL_PORTFOLIO_VALUE, taxYear],
    () => {
      return axiosCall({url: endpoints.TOTAL_PORTFOLIO_VALUE(taxYear)});
    },
    {
      onSuccess: () => {},
      onError: () => {},
      enabled: !!taxYear,
    },
  );
};
