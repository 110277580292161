//lib
import {useQuery} from "react-query";

//src
import {axiosCall, useClientMutation} from "services/api";
import {endpoints, queryConstants} from "constants/";

export const useSyncTaxSummaryWithYear = (id: string | number) => {
  const payload = {
    url: endpoints.TAX_CALCULATION_SYNC_WITH_YEAR(id),
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: false,
  });

  return {
    syncTaxSummaryWithYear: mutate,
    ...rest,
  };
};

export const useEditLossCF = (taxYearID: number | string) => {
  const payload = {
    url: endpoints.EDIT_LOSS_CARRIED_FWD(Number(taxYearID)),
    method: "patch",
  };
  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    editLCF: mutate,
    ...rest,
  };
};

export const useSyncTaxSummary = () => {
  const payload = {
    url: endpoints.TAX_CALCULATION_SYNC,
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: false,
  });

  return {
    syncTaxSummary: mutate,
    ...rest,
  };
};

// export const useGetTaxReportInfo = (id: string | number) => {
//   const payload = {
//     url: endpoints.TAX_REPORT_INFO_BY_TAX_YEAR(id),
//     method: "get",
//   };

//   const { mutate, ...rest } = useClientMutation({
//     payload,
//     uiLoading: true,
//   });

//   return {
//     getTaxReportInfo: mutate,
//     ...rest,
//   };
// };

export async function fetchTaxReportInfo(taxYearID: number | string) {
  const resp = await axiosCall({
    url: `${endpoints.TAX_REPORT_INFO_BY_TAX_YEAR(taxYearID)}`,
  });
  return resp;
}

export const useGenerateTaxReport = (id: string | number) => {
  const payload = {
    url: endpoints.TAX_REPORT_INFO_BY_TAX_YEAR(id),
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: false,
  });

  return {
    generateTaxReport: mutate,
    ...rest,
  };
};
export const useDownloadTaxReport = () => {
  const payload = {
    method: "get",
    responseType: "blob",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    downloadTaxReport: mutate,
    ...rest,
  };
};

export const useFetchTaxCalculationOverView = (
  selectedTaxYearId: number | string,
) => {
  return useQuery(
    [queryConstants.TAX_CALCULATION_OVERVIEW_PROGRESS, selectedTaxYearId],
    () => {
      return axiosCall({
        url: endpoints.TAX_CALCULATION_OVERVIEW(selectedTaxYearId),
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );
};

export const useFetchUserTaxStatus = (isDagProcessing = false) => {
  return useQuery(
    [queryConstants.TAX_CALCULATION_STATUS],
    () => {
      return axiosCall({
        url: endpoints.TAX_CALCULATION_STATUS,
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
      refetchInterval: !isDagProcessing ? 10000 : 3000,
      keepPreviousData: false,
      staleTime: 0,
    },
  );
};
