// lib
import {Box, IconButton, TableContainer, Typography} from "@mui/material";
import {styled} from "@mui/material/styles";
import Tooltip, {TooltipProps, tooltipClasses} from "@mui/material/Tooltip";
import {isSomething} from "utils";

// src
import {appThemeConfigs} from "../theme/theme";

interface TooltipExtendedProps extends TooltipProps {
  padding?: string;
  borderRadius?: string;
  margin?: string;
  backgroundColor?: string;
  id?: string;
}

export const CustomTooltip = styled(
  ({className, ...props}: TooltipExtendedProps) => {
    return (
      <Tooltip
        {...props}
        arrow
        classes={{popper: className}}
        id="tooltip-info"
      />
    );
  },
)(({theme, ...props}) => {
  const {
    padding,
    borderRadius,
    backgroundColor = theme.palette.text.primary,
  } = props;
  return {
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: backgroundColor,
      color: "#ffffff",
      padding: isSomething(padding) ? padding : "14px 16px",
      ...(isSomething(borderRadius) ? {borderRadius} : {}),
      lineHeight: 1.43,
    },
    [`& .${tooltipClasses.arrow}`]: {
      color: backgroundColor,
    },
  };
});

export const ErrorSvgWrapper = styled(Box)(() => ({
  display: "grid",
  placeItems: "Center",
}));

export const HeadTitle = styled("h4")(({theme}) => ({
  fontSize: "24px",
  fontWeight: "bold",
  margin: "0",
  color: theme.palette.text.primary,
}));

export const SectionTitle = styled("h4")(({theme}) => ({
  fontSize: "24px",
  margin: "0",
  marginBottom: "8px",
  color: theme.palette.text.primary,
}));

export const ItemTitle = styled("h5")(({theme}) => ({
  fontSize: "18px",
  fontWeight: "bold",
  margin: "0",
  marginBottom: "8px",
  color: theme.palette.text.primary,
}));

export const SubsectionTitle = styled("h5")(({theme}) => ({
  fontSize: "18px",
  fontWeight: "500",
  margin: "0",
  marginBottom: "8px",
  color: theme.palette.text.primary,
}));

export const ImportantInfo = styled("h6")(({theme}) => ({
  fontSize: "16px",
  margin: "0",
  color: theme.palette.text.primary,
  fontWeight: 500,
}));

export const Title = styled("h3")(({theme}) => ({
  fontSize: "22px",
  margin: "0 auto 8px",
  color: theme.palette.text.secondary,
}));

export const Description = styled("span")(({theme}) => ({
  fontSize: "16px",
  color: theme.palette.text.secondary,
}));

export const SmallDescription = styled("span")(({theme}) => ({
  fontSize: "14px",
  color: theme.palette.text.secondary,
}));

export const DimSubtitle = styled("span")(({theme}) => ({
  fontSize: "12px",
  color: theme.palette.text.secondary,
}));

interface titleType {
  theme?: typeof appThemeConfigs;
  size?: "xxsm" | "xsm" | "sm" | "md" | "lg" | "xl" | "xl1" | "xxl" | "xxxl";
  color?: "primary" | "secondary" | "tertiary" | "error" | "active" | "success";
  bold?: boolean;
  mediumBold?: boolean;
  noPadding?: boolean;
  id?: string;
}

const titleSizeMap = {
  xxsm: "16px",
  xsm: "18px",
  sm: "22px",
  md: "24px",
  lg: "26px",
  xl: "28px",
  xl1: "36px",
  xxl: "40px",
  xxxl: "48px",
};

export const TitleStyled = styled(Typography)((props: titleType) => {
  const {
    size = "sm",
    color = "primary",
    bold = false,
    mediumBold = false,
    noPadding = false,
    theme,
    id,
  } = props;
  const fontWeight = bold ? "bold" : mediumBold ? "500" : "normal";

  return {
    color: theme?.palette.text[color],
    fontSize: titleSizeMap[size],
    fontWeight,
    padding: noPadding ? "" : `5px 0px`,
    id: id,
  };
});

interface descriptionType {
  theme?: typeof appThemeConfigs;
  size?:
    | "xxsm"
    | "xsm"
    | "sm"
    | "sm1"
    | "md"
    | "lg"
    | "xl"
    | "xxl"
    | "xxxl"
    | "xxxxl";
  color?: "primary" | "secondary" | "tertiary" | "error" | "active" | "success";
  bold?: boolean;
  mediumBold?: boolean;
  noPadding?: boolean;
  fontStyle?:
    | "italic"
    | "normal"
    | "oblique"
    | "revert"
    | "unset"
    | "inherit"
    | "revert-layer";
}

const descriptionSizeMap = {
  xxsm: "10px",
  xsm: "11px",
  sm: "12px",
  sm1: "13px",
  md: "14px",
  lg: "16px",
  xl: "18px",
  xxl: "20px",
  xxxl: "22px",
  xxxxl: "24px",
  xxxxxl: "36px",
};

export const DescriptionStyled = styled(Typography)(
  (props: descriptionType) => {
    const {
      size = "lg",
      color = "primary",
      bold = false,
      mediumBold = false,
      noPadding = false,
      theme,
      fontStyle = "normal",
    } = props;
    const fontWeight = bold ? "600" : mediumBold ? "500" : "normal";

    return {
      color: theme?.palette.text[color],
      fontSize: descriptionSizeMap[size],
      fontWeight,
      padding: noPadding ? `0px 0px` : `5px 0px`,
      fontStyle,
    };
  },
);

interface TableContainerStyledProps {
  theme?: typeof appThemeConfigs;
  minWidth?: string | number;
}

export const DashboardTableContainer = styled(TableContainer)(
  (props: TableContainerStyledProps) => {
    const {theme} = props;
    return {
      marginTop: "10px",
      maxHeight: "392px",
      paddingRight: "10px",

      "& table": {minWidth: props.minWidth || 0},
      //  scrollbar style
      "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        width: "6px",
        height: "10px",
        borderRadius: 23,
      },
      "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
        borderRadius: 23,
        backgroundColor: "rgba(0, 0, 0, 0.1)",
        outline: " 1px solid transparent",
        minHeight: 24,
      },

      "& thead": {
        "& tr": {
          "& th": {
            fontSize: "12px",
            color: theme?.palette.text.secondary,
            padding: "5px 12px",
            backgroundColor: theme?.palette.text.tertiary,
          },
        },
      },

      "& tbody": {
        "& tr": {
          "& td": {
            borderBottom: `1px solid ${theme?.palette.divider}`,
            padding: "5px 12px",
          },
        },
      },
    };
  },
);

interface AdminAppTableStyledProps {
  theme?: typeof appThemeConfigs;
}

export const AdminAppTableContainer = styled(TableContainer)(
  (props: AdminAppTableStyledProps) => {
    const {theme} = props;
    return {
      maxHeight: "866px",
      border: `1px solid ${theme?.palette.divider}`,

      "& thead": {
        "& tr": {
          "& th": {
            backgroundColor: theme?.palette.info.light,
            fontSize: "16px",
            fontWeight: "bold",
            padding: "10px 30px",
            borderBottom: `1px solid ${theme?.palette.divider}`,
          },
        },
      },

      "& tbody": {
        "& tr": {
          backgroundColor: theme?.palette.background.paper,
          "& td": {
            borderBottom: `1px solid ${theme?.palette.divider}`,
            padding: "10px 30px",
            fontSize: "14px",
          },
        },
        "& tr:hover": {
          backgroundColor: theme?.palette.background.default,
        },
        "& tr:last-child td": {
          borderBottom: "none",
        },
      },
    };
  },
);

export const AppContainerStyle = styled(Box)(({theme}) => ({
  position: "relative",
  minHeight: "700px",
  margin: "100px auto",
  borderRadius: "12px",
  //paddingBottom: "15px",
  width: "100%",
  maxWidth: "1600px",
  [theme.breakpoints.down("md")]: {
    // width: "1215px",
    // border: "10px solid red",
  },
}));

interface IconButtonProps {
  theme?: typeof appThemeConfigs;
  iconHoverColor?: string;
  iconColor?: string;
  isActive?: boolean;
}

export const StyledIconButton = styled(IconButton)((props: IconButtonProps) => {
  const {iconHoverColor, isActive = false, iconColor = "#BEC2C4"} = props;

  return {
    color: isActive ? iconHoverColor : iconColor,
    "&:hover": {
      color: iconHoverColor,
    },
  };
});

export const CircularLoaderStyled = styled(Box)(() => ({
  width: "14px",
  height: "14px",
  border: "2px solid #1b2126",
  borderBottomColor: "transparent",
  borderRadius: "50%",
  display: "inline-block",
  boxSizing: "border-box",
  animation: "rotation 1s linear infinite",

  "@keyframes rotation": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
}));
