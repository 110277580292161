//lib
import React from "react";
import MuiAlert from "@mui/material/Alert";
import {Snackbar, Box} from "@mui/material";
import {useDispatch} from "react-redux";
import * as R from "ramda";

//src

import {setToaster} from "store/ui/ui-slice";
import TickFilledSuccess from "assets/svgs/TickFilledSuccess";
import {useSnackBarsStyles} from "components/toaster/toaster.styles";
import {InfoFilledIcon} from "assets/svgs/InfoFilled";
import {ErrorFilledIcon} from "assets/svgs/errorFilled";

const Alert = React.forwardRef(function Alert(props: any, ref: any) {
  return (
    <MuiAlert
      id="alert-message"
      elevation={6}
      ref={ref}
      variant="filled"
      {...props}
    />
  );
});

export interface toasterType {
  message: string | JSX.Element;
  type: "error" | "warning" | "success" | "info";
  vertical?: "top" | "bottom";
  horizontal?: "right" | "left";
  autoHideDuration?: number | "infinite";
  customIcon?: JSX.Element;
}

interface SnackBarType {
  state: toasterType | null;
}

const Toaster = (props: SnackBarType) => {
  const dispatch = useDispatch();
  const {state} = props;
  const classes = useSnackBarsStyles();

  const isToastOpen = !R.equals(state, null);
  const toastBarType = R.pathOr("success", ["type"], state);
  const vertical = R.pathOr("top", ["vertical"], state);
  const horizontal = R.pathOr("right", ["horizontal"], state);
  const message = R.path(["message"], state);
  const customIcon = R.path(["customIcon"], state);

  const getHideDuration: any = () => {
    if (state !== null) {
      if (state?.autoHideDuration === "infinite") {
        return null;
      }
      if (state?.autoHideDuration) {
        return state?.autoHideDuration;
      }
    }
    return 5000;
  };

  const autoHideDuration = getHideDuration();

  return (
    <Box className={classes.root}>
      <Snackbar
        open={isToastOpen}
        autoHideDuration={autoHideDuration}
        onClose={() => dispatch(setToaster(null))}
        anchorOrigin={{vertical, horizontal}}
      >
        <Alert
          iconMapping={{
            success: <TickFilledSuccess />,
            info: <InfoFilledIcon />,
            error: <ErrorFilledIcon />,
          }}
          icon={customIcon}
          onClose={() => dispatch(setToaster(null))}
          severity={toastBarType}
        >
          {message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Toaster;
