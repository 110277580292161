import * as R from "ramda";

export const isNothing = R.either(R.isEmpty, R.isNil);

export const isSomething = R.complement(isNothing);

export const fieldsValidator = R.curry(
  (validators: Array<Function>, value: string) => {
    let error: any;
    validators.some((func: Function) => {
      if (R.is(Function, func)) error = func(value);
      return isSomething(error);
    });
    return error;
  }
);
