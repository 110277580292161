// constants for
//DASHBOARD => PORTFOLIO => TOTAL ASSETS TABLE
export const NO_OF_SELECTABLE_CHIPS = 3;
export const DEFAULT_COLORS = {light: "#d3d3d3", dark: "#696969"};
export const TABLE_SMALL_THRESHOLD = 5;
export const COLOR_SCHEME_FOR_SELECTED_ROWS = [
  {
    name: "orange",
    dark: "#f57e42",
    light: "#fef2ec",
    lineColor: "rgba(245,126,66,1)",
    topColor: "rgba(245,126,66,0.7)",
    bottomColor: "rgba(245,126,66,0.1)",
  },
  {
    name: "green",
    dark: "#1da868",
    light: "#e9f6f0",
    lineColor: "rgba(29,168,104,1)",
    topColor: "rgba(29,168,104,0.7)",
    bottomColor: "rgba(29,168,104,0.1)",
  },
  {
    name: "blue",
    dark: "#2988df",
    light: "#dbedfb",
    lineColor: "rgba(41,136,223,1)",
    topColor: "rgba(41,136,223,0.7)",
    bottomColor: "rgba(41,136,223,0.1)",
  },
];

//DASHBOARD => PORTFOLIO => TOP TRADERS

export const COLOR_SCHEME_FOR_AVATAR = [
  "#2988df",
  "#1da868",
  "#fbbf21",
  "#d94a41",
];

//TAX MANAGEMENT ASSET HOLDING CHART

export const MAX_ITEMS_SELECTED_CURRENCY_LIST = 5;
export const DEFAULT_COLORS_ASSET_HOLDING = {
  light: "#d3d3d3",
  dark: "#696969",
  lineColor: "rgba(245,126,66,1)",
  topColor: "#d3d3d3",
  bottomColor: "#696969",
};

export const COLOR_SCHEMA_HOLDING_OVERVIEW = {
  taxApplicable: {
    fill: "rgba(245, 126, 66, 0.75)",
    stroke: "#f57e42",
  },
  taxFree: {fill: "rgba(29, 168, 104, 0.75)", stroke: "#1da868"},
};

export const COLOR_SCHEME_HOLDING_DETAILED = [
  {
    name: "red",
    dark: "#d94a41",
    light: "#f8efef",
    fill: "rgba(217, 74, 65, 0.75)",
    stroke: "#d94a41",
  },
  {
    name: "cyan",
    dark: "#3ea3bd",
    light: "rgba(62, 163, 189, 0.15)",
    fill: "rgba(62, 163, 189, 0.75)",
    stroke: "#3ea3bd",
  },
  {
    name: "blue",
    dark: "#2988df",
    light: "#edf6fd",
    fill: "rgba(41, 136, 223, 0.75)",
    stroke: "#2988df",
  },
  {
    name: "purple",
    dark: "#4b4090",
    light: "#ece9ff",
    fill: "rgba(75, 64, 144, 0.5)",
    stroke: "#4b4090",
  },
  {
    name: "yellow",
    dark: "#fbbf21",
    light: "#fffbf0",
    fill: "rgb(255,230,165,0.7)",
    stroke: "#fbbf21",
  },
];
