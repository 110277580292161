import * as R from "ramda";
import {RootState} from "store";
import {useSelector} from "react-redux";
import {
  isSomething,
  getTaxCalculationSyncStatus,
  getFilteredTaxDates,
} from "utils";
import {
  tagsListType,
  tagType,
} from "components/transaction-tags-modal/tags-modal";
import {useFetchTaxCalculationOverView} from "services/api/tax";
import {
  taxYearListType,
  taxYearType,
} from "screens/taxes/tax-report/components/tax-date/tax-date";
import {useFetchPaymentTaxYears} from "services/api/settings";
import {useFetchWalletsSummary} from "services/api/wallets";
import {ActiveProductType} from "./utils.types";

const symbolsArray = {
  eur: "€",
  usd: "$",
};
export const useGetUserCurrency = () => {
  const metaData = useSelector((state: RootState) => state.app);
  const userData = useSelector((state: RootState) => state.auth);
  const userCurrencyCode = R.pathOr(
    "",
    ["user", "profile", "baseCurrency"],
    userData,
  );
  const currenciesArray = R.pathOr([], ["meta", "currencies"], metaData);

  const currentCurrencyObject = R.find(
    R.propEq("code", userCurrencyCode),
    currenciesArray,
  );
  const userSelectedCurrencyCode = R.pathOr(
    "eur",
    ["code"],
    currentCurrencyObject,
  ).toLowerCase();
  const userSelectedCurrencySymbol = R.pathOr(
    "€",
    [userSelectedCurrencyCode],
    symbolsArray,
  );
  return {
    currencyCode: userSelectedCurrencyCode,
    currencySymbol: userSelectedCurrencySymbol,
  };
};

export const useGetUserLanguage = () => {
  const userData = useSelector((state: RootState) => state.auth);
  const userLanguageCode = R.pathOr(
    "",
    ["user", "profile", "language"],
    userData,
  );

  return {
    languageCode: userLanguageCode,
    languageFileName: userLanguageCode,
  };
};

export const useIsFiatCurrency = (currencyCode = "") => {
  const {fiatCurrenciesList} = useSelector((state: RootState) => state.app);

  return R.pipe(
    R.defaultTo([]),
    R.find(
      (x: any) =>
        R.propEq("code", currencyCode, x) && R.propEq("type", "fiat", x),
    ),
    isSomething,
  )(fiatCurrenciesList);
};

export const useIsThisRolePermitted = (permissionString: string) => {
  const {user} = useSelector((state: RootState) => state.auth);

  const userPermissionsList = R.pathOr("", ["role", "permissions"], user);
  const isSuperUser = R.pathEq(["is_superuser"], true, user);

  if (isSuperUser) return true;
  return R.pipe(
    // @ts-ignore
    R.pluck("name"),
    // @ts-ignore
    R.includes(permissionString, R.__),
  )(userPermissionsList);
};

export const useGetFilteredTagsList = (transactionType = "") => {
  const tagsList: tagsListType = useSelector((state: RootState) =>
    R.pipe(
      R.pathOr([], ["app", "tagsList"]),
      R.filter((tag: tagType) =>
        isSomething(transactionType)
          ? R.equals(tag.category, R.toLower(transactionType))
          : true,
      ),
    )(state),
  );
  return tagsList;
};

export const useGetTaxCalcProgressStatus = () => {
  const {app} = useSelector((state: RootState) => state);
  const selectedTaxYear = R.last(R.pathOr([], ["taxYears"], app));
  const selectedTaxYearId: string | number = R.propOr(0, "id", selectedTaxYear);

  const {data: taxData} = useFetchTaxCalculationOverView(selectedTaxYearId);
  const isTaxCalcInProgress = getTaxCalculationSyncStatus(taxData);

  return isTaxCalcInProgress;
};

export const useGetActiveProductInfo = (): ActiveProductType => {
  const state = useSelector((state: RootState) => state);

  const activeProduct = R.pathOr({}, ["auth", "user", "activeProduct"], state);

  return {
    productId: R.pathOr("", ["id"], activeProduct),
    productCreationDate: R.pathOr("", ["created"], activeProduct),
    productDescription: R.pathOr("", ["description"], activeProduct),
    productExpiryDate: R.pathOr("", ["expiry_date"], activeProduct),
    productName: R.pathOr("", ["name"], activeProduct),
    productKey: R.pathOr("", ["metadata", "product"], activeProduct),
    productType: R.pathOr("", ["type"], activeProduct),
    maxTransactionsSupport: R.pathOr(
      "",
      ["metadata", "num_transactions"],
      activeProduct,
    ),
    maxWalletsSupport: R.pathOr("", ["metadata", "num_wallets"], activeProduct),
    productPrice: R.divide(
      R.pathOr(0, ["prices", 0, "unit_amount"], activeProduct),
      100,
    ),
    productCurrency: R.pathOr("", ["prices", 0, "currency"], activeProduct),
    productInterval: R.pathOr(
      "",
      ["prices", 0, "recurring", "interval"],
      activeProduct,
    ),
  };
};

export const useGetTaxYearsWithSubscriptionInfo = () => {
  const {app, auth} = useSelector((state: RootState) => state);
  const taxYears: taxYearType[] = R.propOr([], "taxYears", app);
  const userProfile: [] = R.pathOr([], ["user", "profile"], auth);
  const {data: taxYearsSubscribed, isLoading} = useFetchPaymentTaxYears();

  const subscribedYearsIDs = R.pluck(
    // @ts-ignore
    "id",
    R.pathOr([], ["data", "results"], taxYearsSubscribed),
  );
  const yearsList: taxYearListType[] = getFilteredTaxDates(
    taxYears,
    R.pathOr(1, ["tax", "taxResidentCountry"], userProfile),
  );

  return {
    yearsList: R.map(
      year => ({
        ...year,
        isSubscribed: R.includes(
          R.pathOr(-1, ["id"], year),
          subscribedYearsIDs,
        ),
      }),
      yearsList,
    ),
    isTaxYearsLoading: isLoading,
  };
};

export const useGetWalletsSummary = () => {
  const {data: walletsSummaryData, isLoading: walletsSummaryLoading} =
    useFetchWalletsSummary();

  const parsedData = R.pathOr({}, ["data"], walletsSummaryData);

  const noOfTotalWallets = R.pathOr(0, ["total_wallets"], parsedData);
  const noOfSystemWallets = R.pathOr(0, ["system_wallets"], parsedData);
  const noOfRestrictedWallets = R.pathOr(0, ["restricted_wallets"], parsedData);

  const noOfUserAddedWalletsAuthorized = R.subtract(
    noOfTotalWallets,
    R.add(noOfSystemWallets, noOfRestrictedWallets),
  );

  const walletsSummary = {
    noOfTotalWallets: noOfTotalWallets,
    noOfSystemWallets: noOfSystemWallets,
    noOfRestrictedWallets: noOfRestrictedWallets,
    noOfUserAddedWalletsAuthorized: noOfUserAddedWalletsAuthorized,
    walletsSummaryLoading: walletsSummaryLoading,
  };

  return walletsSummary;
};
