const TickFilledSuccess = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9 0C4.032 0 0 4.032 0 9s4.032 9 9 9 9-4.032 9-9-4.032-9-9-9zM7.2 13.5 2.7 9l1.269-1.269L7.2 10.953l6.831-6.831L15.3 5.4l-8.1 8.1z"
        fill="#1DA868"
      />
    </svg>
  );
};

export default TickFilledSuccess;
