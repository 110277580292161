export const UNAUTHENTICATED_ROUTES = [
  {label: "features", url: "https://capital-bee.com/"},
  {label: "pricing", url: "https://capital-bee.com/"},
  {label: "resources", url: "https://capital-bee.com/"},
];

export const AUTHENTICATED_ROUTES = [
  {label: "dashboard", url: "/dashboard"},
  {label: "wallets", url: "/wallets"},
  {label: "transactions", url: "/transactions"},
  {label: "taxes", url: "/taxes"},
  {label: "watchlist", url: "/watchlist"},
];

// export const ADMIN_APP_ROUTES = [
//   {label: "Management", url: "/admin/management"},
//   {label: "Subscriptions", url: "/admin/subscriptions"},
//   {label: "Settings", url: "/admin/settings"},
// ];
