import {Skeleton, Box} from "@mui/material";
import {useTextSkeletonStyles} from "./text-skeleton.style";

interface textSkeletonProps {
  width?: number;
  height?: number;
  animate?: boolean;
  styling?: boolean;
  sx?: any;
}

const TextSkeleton = (props: textSkeletonProps) => {
  const classes = useTextSkeletonStyles();
  const {width = 135, height = 70, animate = true, styling = true, sx} = props;
  return (
    <Box className={styling ? classes.textSkeletonContainer : ""}>
      <Skeleton
        variant="text"
        height={`${height}px`}
        width={`${width}px`}
        animation={animate && "wave"}
        sx={sx}
      />
    </Box>
  );
};

export default TextSkeleton;
