import * as R from "ramda";
import classNames from "classnames";
import React, {useState} from "react";
import {useDispatch} from "react-redux";
import {useQueryClient} from "react-query";
import {
  Box,
  Stack,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableCell,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableContainer,
} from "@mui/material";
import {useTranslation, Trans} from "react-i18next";
import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";
import ThumbDownAltIcon from "@mui/icons-material/ThumbDownAlt";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

import {isSomething} from "utils";
import {setToaster} from "store/ui";
import {queryConstants} from "constants/";
import {planIconsMap} from "./payment-plans";
import {FREE, BEGINNER, PRO, ADVANCED} from "constants/";
import LoadingState from "components/loader/loading-state";
import {TitleStyled, DescriptionStyled, CustomTooltip} from "styles/common";
import {InfoTooltip} from "components/info-tooltip/info-tooltip";
import {useSettingsStyles} from "screens/settings/settings.styles";
import {
  useFetchPaymentPlansComparison,
  useSendFeatureVote,
} from "services/api/settings";
import {CustomButton} from "submodules/capital-bee-web-ui-kit/src/components/CustomButton";

interface PaymentPlansComparisonType {
  productPlans: any[];
  handleUpgrade: (plan: any) => void;
}

interface ComparisonDataObject {
  [key: string]: {
    free_product: string | boolean;
    beginner_product: string | boolean;
    pro_product: string | boolean;
    advanced_product: string | boolean;
    vote: {
      score: number;
      num_votes: number;
      upvote: boolean;
      downvote: boolean;
    };
  };
}

const PaymentPlansComparison = ({
  productPlans,
  handleUpgrade,
}: PaymentPlansComparisonType) => {
  const [expanded, setExpanded] = useState(false);

  const classes = useSettingsStyles();
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const {data, isLoading: comparisonLoading} = useFetchPaymentPlansComparison();
  const {voteForFeature} = useSendFeatureVote();

  const comparisonData: ComparisonDataObject | null = R.pathOr(
    null,
    ["data"],
    data,
  );

  const transformComparisonObject = (key: string) => {
    // @ts-ignore
    if (comparisonData !== null && isSomething(comparisonData[key]?.vote)) {
      return {
        feature: key,
        free: comparisonData[key][FREE],
        beginner: comparisonData[key][BEGINNER],
        advanced: comparisonData[key][ADVANCED],
        pro: comparisonData[key][PRO],
        vote: {
          score: comparisonData[key]["vote"]["score"],
          num_votes: comparisonData[key]["vote"]["num_votes"],
          upvote: comparisonData[key]["vote"]["upvote"],
          downvote: comparisonData[key]["vote"]["downvote"],
        },
      };
    }
    // @ts-ignore
    else if (comparisonData !== null && comparisonData[key]?.vote === null) {
      return {
        feature: key,
        free: comparisonData[key][FREE],
        beginner: comparisonData[key][BEGINNER],
        advanced: comparisonData[key][ADVANCED],
        pro: comparisonData[key][PRO],
        vote: null,
      };
    }
    return [];
  };

  const transformedData = R.pipe(
    R.keys,
    R.map((key: string) => transformComparisonObject(key)),
  )(comparisonData);

  const handleChange = () => {
    setExpanded(!expanded);
  };

  const isString = (value: any): value is string => {
    return typeof value === "string";
  };

  const isNumber = (value: any): value is number => {
    return typeof value === "number";
  };

  const showPlanFeature = (feature: string | boolean) => {
    if (isString(feature) || isNumber(feature)) {
      return t(`${feature}`);
    } else if (!!feature) {
      return <DoneTwoToneIcon fontSize="medium" sx={{color: "#1DA868"}} />;
    } else {
      return <HorizontalRuleIcon fontSize="medium" sx={{color: "#BEC2C4"}} />;
    }
  };

  const ComparisonRow = (props: any) => {
    const {
      details: {feature, free, beginner, advanced, pro, vote},
    } = props;
    const isVotedUp = R.pathOr(false, ["upvote"], vote);
    const isVotedDown = R.pathOr(false, ["downvote"], vote);
    const voteScore = R.pathOr(0, ["score"], vote);

    const [showVoteUp, setShowVoteUp] = useState(isVotedUp);
    const [showVoteDown, setShowVoteDown] = useState(isVotedDown);

    const handleVote = (feature: string, vote: number) => {
      if (vote === 1) {
        setShowVoteUp(true);
        setShowVoteDown(false);
      } else if (vote === -1) {
        setShowVoteUp(false);
        setShowVoteDown(true);
      } else {
        setShowVoteUp(false);
        setShowVoteDown(false);
      }
      voteForFeature(
        {feature, vote},
        {
          onSuccess: () => {
            queryClient.invalidateQueries(
              queryConstants.PAYMENT_PLANS_COMPARISON,
            );
            dispatch(
              setToaster({
                type: "success",
                message: t("vote_success_toast"),
              }),
            );
          },
          onError: () => {
            dispatch(
              setToaster({
                type: "error",
                message: t("something_went_wrong_try_again"),
              }),
            );
          },
        },
      );
    };
    return (
      <TableRow>
        <TableCell className={classes.firstCell}>
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <DescriptionStyled size="sm">
              {t(`${feature}_comparison`)}
            </DescriptionStyled>
            <InfoTooltip
              customClassName="tooltipClass"
              title={t(`${feature}_comparison`)}
            />
          </Box>
          {isSomething(vote) && (
            <Box display="flex" gap={1.5} alignItems={"center"}>
              <Box display="flex" gap={0.5}>
                {showVoteUp ? (
                  <ThumbUpAltIcon
                    onClick={() => handleVote(feature, 0)}
                    className={classes.thumbsFilled}
                  />
                ) : (
                  <CustomTooltip title={t("up_vote_tooltip") as string}>
                    <ThumbUpOffAltIcon
                      onClick={() => handleVote(feature, 1)}
                      className={classes.thumbsOutlined}
                    />
                  </CustomTooltip>
                )}
                {showVoteDown ? (
                  <ThumbDownAltIcon
                    onClick={() => handleVote(feature, 0)}
                    className={classes.thumbsFilled}
                  />
                ) : (
                  <CustomTooltip title={t("down_vote_tooltip") as string}>
                    <ThumbDownOffAltIcon
                      onClick={() => handleVote(feature, -1)}
                      className={classes.thumbsOutlined}
                    />
                  </CustomTooltip>
                )}
              </Box>
              <Box display="flex" gap={0.25} alignItems={"center"}>
                <DescriptionStyled size="sm">{`${t(
                  "feature_score",
                )}:`}</DescriptionStyled>
                <CustomTooltip title={t("feature_score_tooltip") as string}>
                  <DescriptionStyled mediumBold size="sm">
                    {voteScore}
                  </DescriptionStyled>
                </CustomTooltip>
              </Box>
            </Box>
          )}
        </TableCell>
        <TableCell align="center" className={classes.comparisonTableCell}>
          <Box className={classes.comparisonTableBox}>
            <DescriptionStyled size="sm" mediumBold>
              {showPlanFeature(free)}
            </DescriptionStyled>
          </Box>
        </TableCell>
        <TableCell align="center" className={classes.comparisonTableCell}>
          <Box className={classes.comparisonTableBox}>
            <DescriptionStyled size="sm" mediumBold>
              {showPlanFeature(beginner)}
            </DescriptionStyled>
          </Box>
        </TableCell>
        <TableCell align="center" className={classes.comparisonTableCell}>
          <Box
            className={classNames({
              [classes.comparisonTableBox]: true,
              [classes.advancedBG]: true,
            })}
          >
            <DescriptionStyled size="sm" mediumBold>
              {showPlanFeature(advanced)}
            </DescriptionStyled>
          </Box>
        </TableCell>
        <TableCell align="center" className={classes.comparisonTableCell}>
          <Box
            className={classNames({
              [classes.comparisonTableBox]: true,
              [classes.proBG]: true,
            })}
          >
            <DescriptionStyled size="sm" mediumBold>
              {showPlanFeature(pro)}
            </DescriptionStyled>
          </Box>
        </TableCell>
      </TableRow>
    );
  };

  const FeatureHeadingRow = ({heading}: {heading: string}) => {
    return (
      <TableRow>
        <TableCell
          className={classNames({
            [classes.featureHeadingsRow]: true,
            [classes.firstCell]: true,
          })}
        >
          <DescriptionStyled size="xxxl" bold>
            {t(heading)}
          </DescriptionStyled>
        </TableCell>
        <TableCell
          align="center"
          className={classes.comparisonTableCell}
        ></TableCell>
        <TableCell
          align="center"
          className={classes.comparisonTableCell}
        ></TableCell>
        <TableCell align="center" className={classes.comparisonTableCell}>
          <Box sx={{height: "107px"}} className={classes.advancedBG}></Box>
        </TableCell>
        <TableCell align="center" className={classes.comparisonTableCell}>
          <Box sx={{height: "107px"}} className={classes.proBG}></Box>
        </TableCell>
      </TableRow>
    );
  };

  const PlansRow = () => {
    return React.Children.toArray(
      productPlans.map((plan: any) => {
        const planKey: string = R.pathOr("", ["metadata", "product"], plan);
        const planPrice = R.pathOr("", ["prices", 0, "unit_amount"], plan);
        const actualPrice = isSomething(planPrice)
          ? R.divide(Number(planPrice), 100)
          : 0;
        const currency: string = R.pathOr(
          "eur",
          ["prices", 0, "currency"],
          plan,
        );

        const isFreePlan = planKey === FREE;
        const isAdvancedPlan = planKey === ADVANCED;
        const isProPlan = planKey === PRO;

        return (
          <TableCell
            align={"center"}
            className={classes.comparisonTableCell}
            sx={{backgroundColor: "#fff"}}
          >
            <Stack
              className={classNames({
                [classes.roundedBottom]: isFreePlan,
                [classes.roundedTop]: !isFreePlan,
                [classes.advancedBG]: isAdvancedPlan,
                [classes.proBG]: isProPlan,
              })}
              alignItems={"center"}
              marginBottom={isFreePlan ? 1.5 : 0}
            >
              {planIconsMap(planKey, "sm")}
              <DescriptionStyled size="lg" bold>
                {`${t(planKey)} ${t("plan")}`}
              </DescriptionStyled>
              <DescriptionStyled noPadding size="sm" color={"secondary"}>
                {R.equals(actualPrice, 0)
                  ? t("free")
                  : `${currency === "eur" ? "€" : "$"}${actualPrice}/${t(
                      "tax_year_lowercase",
                    )}`}
              </DescriptionStyled>
              {!isFreePlan && (
                <Box marginTop={3} marginBottom={2}>
                  <CustomButton
                    text={t("upgrade")}
                    variant={"contained"}
                    className={classes.comparisonUpgradeBtn}
                    onClick={() => handleUpgrade(plan)}
                  />
                </Box>
              )}
            </Stack>
          </TableCell>
        );
      }),
    );
  };

  return comparisonLoading ? (
    <LoadingState />
  ) : (
    <Accordion
      expanded={expanded}
      className={classes.accordionRoot}
      onChange={handleChange}
    >
      <AccordionSummary
        className={classes.accordionSummary}
        expandIcon={<ArrowDropDownIcon color="primary" />}
      >
        <CustomButton
          className={classes.accordionBtn}
          variant="info"
          text={expanded ? t("hide_comparison") : t("show_comparison")}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          <Box className={classes.comparisonWrapper}>
            <TitleStyled
              sx={{textAlign: "center", maxWidth: "950px"}}
              size="md"
            >
              <Trans i18nKey={"plan_comparison_heading"}>
                <strong className={classes.highlightedText}>
                  {{
                    plan_comparison_heading_strong: t(
                      "plan_comparison_heading_strong",
                    ),
                  }}
                </strong>
              </Trans>
            </TitleStyled>
            <TableContainer
              sx={{borderSpacing: "0px", maxHeight: "600px"}}
              className={classes.comparisonTableContainer}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{backgroundColor: "#fff"}}
                      className={classes.firstCell}
                    ></TableCell>
                    {PlansRow()}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {/* <FeatureHeadingRow heading="" /> */}
                  {React.Children.toArray(
                    transformedData.map(item => {
                      return <ComparisonRow details={item} />;
                    }),
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default PaymentPlansComparison;
