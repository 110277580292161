//lib
import * as R from "ramda";
import {useQuery} from "react-query";
import {useDispatch} from "react-redux";

//src
import {getLocalStorageToken, isSomething} from "utils";
import {setToken, setUser} from "store/auth";
import {axiosCall, useClientMutation} from "services/api";
import {setCostBasis, setCostTracking} from "store/app/app-slice";
import {endpoints, queryConstants} from "constants/";

//***********************************GENERAL ****************************************//

export const useAccountSetup = () => {
  const payload = {
    url: endpoints.ACCOUNT_SETUP,
    method: "patch",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    accountSetup: mutate,
    ...rest,
  };
};

///// change the name to fetch user info
type useAuthenticateUserProps = {
  handleSuccess?: (responseData: any) => void;
};

export const useAuthenticateUser = (props: useAuthenticateUserProps) => {
  const {handleSuccess} = props;
  const dispatch = useDispatch();

  return useQuery(
    queryConstants.GET_USER_INFO,
    () => {
      return axiosCall({url: endpoints.AUTHENTICATE_TOKEN});
    },
    {
      onSuccess: (data: any) => {
        handleSuccess && handleSuccess(R.propOr("", "data", data));
        dispatch(setToken(getLocalStorageToken()));
        dispatch(setUser(R.propOr("", "data", data)));
      },
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

//***********************************ACCOUNT SETTINGS TAB****************************************//

export const useChangePassword = () => {
  const payload = {
    url: endpoints.CHANGE_PASSWORD,
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    changePassword: mutate,
    ...rest,
  };
};

export const useDeactivateAccount = () => {
  const payload = {
    url: endpoints.DEACTIVATE_ACCOUNT,
    method: "delete",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    deactivateUserAccount: mutate,
    ...rest,
  };
};

export const useDeleteOtpDevice = (deviceID: string) => {
  const payload = {
    url: endpoints.DELETE_OTP_DEVICE(deviceID),
    method: "delete",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    deleteOtpDevice: mutate,
    ...rest,
  };
};
//***********************************SECURITY DETAILS****************************************//

export const useAdd2FADevice = (otpDeviceID = "") => {
  const isEditMode = isSomething(otpDeviceID);

  const payload = {
    url: isEditMode
      ? endpoints.EDIT_2FA_DEVICE(otpDeviceID)
      : endpoints.ADD_2FA_DEVICE,
    method: isEditMode ? "patch" : "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    add2FaDevice: mutate,
    ...rest,
  };
};
export const useVerify2FADevice = (deviceId: string) => {
  const payload = {
    url: endpoints.VERIFY_2FA_Device(deviceId),
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    verifyDevice: mutate,
    ...rest,
  };
};

export const useResendCodeToOtpDevice = (deviceId: string) => {
  const payload = {
    url: endpoints.RESEND_OTP_TO_OTP_DEVICE(deviceId),
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    resendOtpCodeToDevice: mutate,
    ...rest,
  };
};

export const useFetchLoginDevices = () => {
  return useQuery(
    queryConstants.LOGIN_DEVICES,
    () => {
      return axiosCall({url: endpoints.LOGIN_DEVICES});
    },
    {
      onSuccess: () => {},
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

//*****************************************TAX RULES************************************************//
type useCostBasisMethodProps = {
  handleSuccess?: (responseData: any) => void;
};

export const useFetchCostBasisMethod = (props: useCostBasisMethodProps) => {
  const {handleSuccess} = props;
  const dispatch = useDispatch();

  return useQuery(
    queryConstants.COST_BASIS,
    () => {
      return axiosCall({url: endpoints.COST_BASIS});
    },
    {
      onSuccess: (data: any) => {
        handleSuccess && handleSuccess(data.data.results);
        dispatch(setCostBasis(data.data.results));
      },
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

type useCostTrackingMethodProps = {
  handleSuccess?: (responseData: any) => void;
};

export const useFetchCostTrackingMethod = (
  props: useCostTrackingMethodProps,
) => {
  const {handleSuccess} = props;
  const dispatch = useDispatch();

  return useQuery(
    queryConstants.COST_TRACKING,
    () => {
      return axiosCall({url: endpoints.COST_TRACKING});
    },
    {
      onSuccess: (data: any) => {
        handleSuccess && handleSuccess(data.data.results);
        dispatch(setCostTracking(data.data.results));
      },
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

//*****************************************OFFERED PLANS************************************************//

export const useFetchInvoicesDetails = () => {
  return useQuery(
    queryConstants.INVOICES_DETAILS,
    () => {
      return axiosCall({url: endpoints.INVOICES_DETAILS});
    },
    {
      onSuccess: () => {},
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

export const useFetchPaymentPlans = () => {
  return useQuery(
    queryConstants.PAYMENT_PLANS,
    () => {
      return axiosCall({url: endpoints.PAYMNENT_PLANS});
    },
    {
      onSuccess: () => {},
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

export const useFetchPaymentPlansComparison = () => {
  return useQuery(
    queryConstants.PAYMENT_PLANS_COMPARISON,
    () => {
      return axiosCall({url: endpoints.PAYMENT_PLANS_COMPARISON});
    },
    {
      onSuccess: () => {},
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

export const useFetchPaymentSubscription = () => {
  return useQuery(
    queryConstants.PAYMENT_SUBSCRIPTON,
    () => {
      return axiosCall({
        url: endpoints.PAYMENT_SUBSCRIPTON,
      });
    },
    {
      onSuccess: () => {},
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

export const useFetchPaymentMethods = () => {
  return useQuery(
    queryConstants.FETCH_PAYMENT_METHODS,
    () => {
      return axiosCall({url: endpoints.PAYMENT_METHODS});
    },
    {
      onSuccess: () => {},
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

export const useDeletePaymentMethod = (methodId: string) => {
  const payload = {
    url: endpoints.DELETE_PAYMENT_METHOD(methodId),
    method: "delete",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    deletePaymentMethod: mutate,
    ...rest,
  };
};

export const useMakeDefaultPaymentMethod = (id: string) => {
  const payload = {
    url: endpoints.MAKE_DEFAULT_PAYMENT_METHOD(id),
    method: "put",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    makeDefaultMethod: mutate,
    ...rest,
  };
};

export const useUpdateBillingDetails = (methodId: string) => {
  const payload = {
    url: endpoints.UPDATE_BILLING_DETAILS(methodId),
    method: "put",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    updateBillingDetails: mutate,
    ...rest,
  };
};

export const useFetchPaymentTaxYears = () => {
  return useQuery(
    queryConstants.PAYMENT_TAX_YEARS,
    () => {
      return axiosCall({
        url: endpoints.PAYMENT_TAX_YEARS,
      });
    },
    {
      onSuccess: () => {},
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

export const useFetchPaymentTaxYearsProduct = () => {
  return useQuery(
    queryConstants.PAYMENT_TAX_YEARS_PRODUCT,
    () => {
      return axiosCall({
        url: endpoints.PAYMENT_TAX_YEARS_PRODUCT,
      });
    },
    {
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

export const useGenerateInvoice = (
  productID: string,
  priceID: string,
  taxYearID: string,
) => {
  const payload = {
    url: endpoints.GENERATE_INVOICE(productID, priceID, taxYearID),
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    generateInvoice: mutate,
    ...rest,
  };
};

export const useUnsubscribePackage = (id: string) => {
  const payload = {
    url: endpoints.CANCEL_PLAN_SUBSCRIPTION(id),
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    unSubscribe: mutate,
    ...rest,
  };
};

export const useGetInvoice = (invoiceID: string) => {
  return useQuery(
    [queryConstants.GET_INVOICE, invoiceID],
    () => {
      return axiosCall({
        url: endpoints.GET_INVOICE(invoiceID),
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
    },
  );
};

export const usePayForPlan = (invoiceID: string) => {
  const payload = {
    url: endpoints.PAY_FOR_PLAN(invoiceID),
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    payForPlan: mutate,
    ...rest,
  };
};

export const useSendFeatureVote = () => {
  const payload = {
    url: endpoints.FEATURE_VOTE,
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    voteForFeature: mutate,
    ...rest,
  };
};
