// lib
import {styled} from "@mui/system";

// src
import Loader from "../../assets/svgs/Loader";

interface ContainerProps {
  isRelative?: boolean;
  minHeightInherit?: boolean;
  componentLoader?: boolean;
}

export const Container = styled("div")((props: ContainerProps) => {
  const {
    isRelative = false,
    minHeightInherit = false,
    componentLoader = false,
  } = props;
  return {
    position: isRelative ? "relative" : "fixed",
    background: "rgba(255, 255, 255, 0.5)",
    zIndex: componentLoader ? "9" : "10000000",
    height: "100%",
    width: "100%",
    right: "0",
    top: "0",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    ...(minHeightInherit ? {minHeight: "inherit"} : {}),
  };
});

export const RotatingContainer = styled("div")(() => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: "22px",
  fontWeight: "800",
  ".beeLoader": {
    width: "88px",
    height: "88px",
    position: "relative",
  },
  ".rotateRight": {
    animation: "rotateRight 2000ms linear infinite normal forwards",
  },
  "@keyframes rotateRight ": {
    from: {
      transform: "translate(17.966899px,17.969159px) rotate(360deg)",
    },
    to: {
      transform: "translate(17.966899px,17.969159px) rotate(720deg)",
    },
  },
  ".rotateLeft": {
    animation: "rotateLeft 2000ms linear infinite normal forwards",
  },
  "@keyframes rotateLeft ": {
    from: {
      transform: "translate(17.985289px,17.969164px) rotate(720deg)",
    },
    to: {
      transform: "translate(17.985289px,17.969164px) rotate(360deg)",
    },
  },
  // animationName: "spin",
  // animationDuration: "2000ms",
  // animationIterationCount: "infinite",
  // animationTimingFunction: "linear",
  //
  // "@keyframes spin ": {
  //   from: {
  //     transform: "rotate(0deg)",
  //   },
  //   to: {
  //     transform: "rotate(360deg)",
  //   },
  // },
}));

interface LoadingStateProps {
  isRelative?: boolean;
  minHeightInherit?: boolean;
  componentLoader?: boolean;
  label?: string;
}

const LoadingState = (props: LoadingStateProps) => {
  const {
    isRelative = false,
    minHeightInherit = false,
    componentLoader = false,
    label = "",
  } = props;
  return (
    <Container
      isRelative={isRelative}
      minHeightInherit={minHeightInherit}
      componentLoader={componentLoader}
    >
      <RotatingContainer>
        <Loader />
        {label}
      </RotatingContainer>
    </Container>
  );
};

export default LoadingState;
