//lib
import {useIsMutating, useQuery} from "react-query";

//src
import {axiosCall, useClientMutation} from "services/api";
import {endpoints, queryConstants} from "constants/";

export const useFetchAvailableWalletList = () => {
  return useQuery(queryConstants.AVAILABLE_WALLET_LIST, () => {
    return axiosCall({url: endpoints.AVAILABLE_WALLETS});
  });
};

type useFetchUserWalletListProps = {
  handleSuccess?: (responseData: any) => void;
  polling?: boolean;
  filter?: string;
  enabled?: boolean;
};

export const useFetchUserWalletList = (props: useFetchUserWalletListProps) => {
  const {
    handleSuccess,
    polling,
    filter = "?ordering=-created_at",
    enabled = true,
  } = props;

  const isMutating = useIsMutating();
  return useQuery(
    [queryConstants.USER_WALLETS_LIST, filter],
    () => {
      return axiosCall({url: endpoints.USER_WALLETS(filter)});
    },
    {
      onSuccess: (data: any) => {
        handleSuccess && handleSuccess(data.data.results);
      },
      onError: (error: any) => {
        console.log(error);
      },
      ...(polling && !isMutating && {refetchInterval: 2000}),
      enabled: enabled,
    },
  );
};

export const useFetchWalletsSummary = () => {
  return useQuery([queryConstants.USER_WALLETS_SUMMARY], () => {
    return axiosCall({url: endpoints.USER_WALLETS_SUMMARY});
  });
};

export const useAddWallet = () => {
  const payload = {
    url: endpoints.ADD_NEW_WALLET,
    method: "post",
    headers: {"Content-Type": "application/json"},
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    addNewWallet: mutate,
    ...rest,
  };
};

export const useSyncWallet = (id: string) => {
  const payload = {
    url: endpoints.SYNC_WALLET(id),
    method: "put",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: false,
  });

  return {
    syncWallet: mutate,
    ...rest,
  };
};

export const useSyncBulkWallets = (walletsIdList: string[]) => {
  const payload = {
    url: endpoints.SYNC_BULK_WALLET(walletsIdList),
    method: "put",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    syncBulkWallets: mutate,
    ...rest,
  };
};

export const useDeleteWallet = (id: string) => {
  const payload = {
    url: endpoints.DELETE_WALLET(id),
    method: "delete",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    deleteWallet: mutate,
    ...rest,
  };
};

export const useDeleteBulkWallets = (walletsIdList: string[]) => {
  const payload = {
    url: endpoints.DELETE_BULK_WALLET(walletsIdList),
    method: "delete",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    deleteBulkWallet: mutate,
    ...rest,
  };
};

export const useFetchSearchedWallets = (searchString: string) => {
  return useQuery(
    [queryConstants.SEARCH_WALLETS, searchString],
    () => {
      return axiosCall({
        url: endpoints.SEARCH_WALLETS(searchString),
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
      enabled: !!searchString,
    },
  );
};
