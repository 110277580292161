interface IconProps {
  width?: number;
  height?: number;
}
export const ErrorFilledIcon = (props: IconProps) => {
  const {width = "32", height = "33"} = props;

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 32 33"
      fill="none"
    >
      <path
        d="M16 3.167A13.321 13.321 0 0 0 2.667 16.5c0 7.373 5.96 13.333 13.333 13.333s13.333-5.96 13.333-13.333S23.373 3.166 16 3.166zm6.667 18.12-1.88 1.88L16 18.38l-4.787 4.787-1.88-1.88L14.12 16.5l-4.787-4.787 1.88-1.88L16 14.62l4.787-4.787 1.88 1.88L17.88 16.5l4.787 4.787z"
        fill="#D94A41"
      />
    </svg>
  );
};
