// lib
import * as R from "ramda";
import classNames from "classnames";
import {Box, MenuItem} from "@mui/material";
import {useTranslation} from "react-i18next";

// src
import {DescriptionStyled} from "styles/common";
import {useTaxDateStyles} from "./tax-date.styles";
import CustomChip from "components/custom-chip/custom-chip";

type dateCardType = {
  year?: string;
  startDate?: string;
  endDate?: string;
  selectedDate?: number;
  index: number;
  updateData?: (index: number) => void;
  selectedYear?: string;
  isTaxesTab?: boolean;
  alreadySubscribed?: boolean;
  id?: string;
  isRestricted?: boolean;
};

const DateCard = (props: dateCardType) => {
  const {
    year,
    startDate,
    endDate,
    selectedDate,
    index,
    updateData = () => {},
    isTaxesTab = false,
    alreadySubscribed = false,
    isRestricted = false,
  } = props;
  const classes = useTaxDateStyles();
  const selected = R.equals(index, selectedDate);

  const {t} = useTranslation();

  return (
    <MenuItem
      className={classNames({
        [classes.menuItem]: true,
        [classes.selectedMenuItem]: selected,
      })}
      onClick={() => updateData(index)}
      key={index}
      id={`${year}-opt`}
    >
      <Box className={classes.rowFlex} id="title">
        <DescriptionStyled
          size="lg"
          bold={selected}
          className={!selected ? classes.bolder : ""}
        >
          {year}
        </DescriptionStyled>

        {isTaxesTab && alreadySubscribed && (
          <CustomChip
            label={"Subscribed"}
            color={"primary"}
            variant={"outlined"}
            isRoundedChip
          />
        )}
        {isRestricted && (
          <CustomChip
            label={t("transactions_limit_exceeded")}
            color={"error"}
            variant={"outlined"}
            isRoundedChip
          ></CustomChip>
        )}
      </Box>
      <DescriptionStyled
        id="description"
        size="md"
        color="secondary"
        className={classes.despMargin}
        // bold={selected}
      >
        {`${startDate} - ${endDate}`}
      </DescriptionStyled>
    </MenuItem>
  );
};

export default DateCard;
