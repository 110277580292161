// user auth
export const GET_USER_INFO = "userAuthentication";
export const GET_QR_TOKEN = "getQrToken";
export const LOGIN_VIA_QR = "loginViaQr";

// for fetching the transactions data
export const TOTAL_TRANSACTIONS = "totalTransactionsList";
export const ERRORS_TRANSACTIONS = "errorsTransactionsList";
export const EDITED_TRANSACTIONS = "editedTransactionsList";

// for fetching the transactions data to get the counts in transactions tab
export const TOTAL_TRANSACTIONS_COUNT = "totalTransactionsCount";
export const EDITED_TRANSACTIONS_COUNT = "editedTransactionsCount";
export const ERROR_TRANSACTIONS_COUNT = "errorTransactionsCount";
export const MISSING_COST_ERROR_TRANSACTIONS = "missingCostError";
export const MISSING_FEE_ERROR_TRANSACTIONS = "missingcFeeError";
export const MISSING_RATE_ERROR_TRANSACTIONS = "missingRateError";

export const FETCH_EXCHANGE_RATE = "fetchExchangeRate";

export const META_DATA_QUERY = "metaData";

export const USER_WALLETS_LIST = "userWalletList";
export const USER_WALLETS_SUMMARY = "userWalletSummary";
export const AVAILABLE_WALLET_LIST = "availableWalletList";
export const SEARCH_WALLETS = "userSearchWallet";

export const PAYMENT_PLANS = "paymentPlans";
export const PAYMENT_PLANS_COMPARISON = "paymentPlansComparison";
export const FETCH_PAYMENT_METHODS = "paymentMethods";
export const PAYMENT_TAX_YEARS = "paymentTaxYears";
export const PAYMENT_TAX_YEARS_PRODUCT = "paymentTaxYearsProduct";
export const LOGIN_DEVICES = "loginDevices";
export const GENERATE_INVOICE = "generateInvoice";
export const GET_INVOICE = "getInvoice";
export const PAY_FOR_PLAN = "payForPlan";

export const INVOICES_DETAILS = "invoicesDetails";

export const PAYMENT_SUBSCRIPTON = "paymentSubscription";

export const COST_BASIS = "costBasisMethod";
export const COST_TRACKING = "costTrackingMethod";

export const TAX_YEARS = "taxYears";
export const TAX_CALCULATION_OVERVIEW = "taxCalculationOverview";
export const TAX_CALCULATION_OVERVIEW_PROGRESS =
  "taxCalculationOverviewProgress";

export const TAX_CALCULATION_STATUS = "taxCalculationStatus";

export const CURRENCIES_LIST = "currenciesList";
export const SEARCH_CURRENCIES_LIST = "searchCurrenciesList";

export const TAGS_LIST = "tagsList";

export const TRANSACTIONS_CSV_IMPORT = "transactionsCsvImport";

export const FETCH_TRANSACTION_WRT_ID = (id: string | number) =>
  `fetchTransactionWrtId${id}`;

//DASHBOARD
export const PORTFOLIO_VALUE = "portfolioValue";
export const UNREALIZED_GAINS_OVERALL_VALUE = "unrealizedGainsOverallValue";
export const TOTAL_ASSETS = "totalAssets";
export const PROFIT_LOSS_HISTORY = "profitLossHistory";
export const TOTAL_TRANSACTIONS_COUNT_DASHBOARD =
  "totalTransactionsCountDashboard";
export const BEST_WORST_TRADES = "bestWorstTrades";

export const FETCH_TAX_REPORT_INFO = "fetchTaxReportInfo";
export const TOTAL_ASSETS_HISTORY = "totalAssetsHistory";
export const TOTAL_GAIN_VALUE = "totalGainValue";
export const TOP_TRADERS = "topTraders";
export const SUCCESS_RATE = "successRate";
export const TOTAL_TRADE_TRANSACTIONS_DATA = "TotalTradeTransactionTileData";
export const FETCH_RANK_AND_PERCENTILE = "fetchRankAndPercentile";
export const FETCH_PERFORMANCE_CHART_DATA = "fetchPerformanceChartData";

export const FETCH_ASSET_HOLDING_TABULAR_DATA = "fetchAssetHoldingTabularData";
export const FETCH_ASSET_HOLDING_HISTORY_DATA = "fetchAssetHoldingHistoryData";
//TAX_MANAGEMENT
export const FETCH_TOTAL_TRANSACTIONS_PER_YEAR = "totalTransactionsPerYear";
export const FETCH_TRANSACTIONS_COUNT_YEARLY =
  "totalTransactionsCountTaxManagement";
export const FETCH_TAX_SUMMARIES_TAX_MANAGEMENT = "taxSummariesYearly";
export const TOTAL_PORTFOLIO_VALUE = "totalPortfolioValue";

// WATCHLIST
export const ASSET_PRICES = "assetPrices";
export const WATCHLIST_CURRENCY = "watchlistCurrency";
export const WATCHLIST_CURRENCY_LIST = "watchlistCurrencyList";
export const REMOVE_WATCHLIST_CURRENCY = "removeWatchlistCurrency";
export const COIN_INFO = "coinInfo";
export const COIN_PRICE_HISTORY = "coinpriceHistory";
export const COIN_PEER_GROUP_HISTORY = "coinPeerGroupHistory";
export const COIN_NEWS = "coinNews";
export const COIN_MARKET_CAP_HISTORY = "coinMarketCapHistory";
export const SEARCH_ASSET_PRICES = "searchAssetPrices";
export const SEARCH_WATCHLIST = "searchWatchlist";
export const SEARCH_COINS_WATCHLIST = "searchCoinsWatchlist";

//ADMIN
export const FETCH_ADMIN_USERS = "fetchAdminUsers";
export const FETCH_ROLES = "fetchRoles";
export const FETCH_PERMISSIONS = "fetchPermissions";
export const ADMIN_SUBSCRIPTIONS = "adminSubscriptions";
export const BLOCKING_REASONS = "blockingReasons";
export const SEARCH_ROLES = "searchRoles";
export const SEARCH_SUBSCRIPTIONS = "searchSubscriptions";
export const SEARCH_USERS = "searchUsers";
export const GET_USERS_COUNT = "getUsersCount";
//SETTINGS
export const FETCH_SUPPORTED_COUNTRIES = "fetchSupportedCountries?page=1";
export const FETCH_SUPPORTED_CURRENCIES = "fetchSupportedCurrencies?page=1";
export const FETCH_SUPPORTED_LANGUAGES = "fetchSupportedLanguages?page=1";
export const FETCH_VERIFICATION_METHODS = "fetchVerificationMethods?page=1";
export const FETCH_TAX_RULES = "fetchTaxRules?page=1";
