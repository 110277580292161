import {makeStyles} from "@mui/styles";
import {styled} from "@mui/material/styles";
import {Box, Menu} from "@mui/material";

export const useTaxDateStyles = makeStyles((theme: any) => ({
  root: {},
  rowFlex: {
    display: "flex",
    alignItems: "center",
    gap: 12,
    "& .MuiChip-root": {
      fontSize: 12,
      borderRadius: 12,
    },
  },
  dateMenu: {
    padding: "20px 40px 20px 20px",
  },
  despMargin: {
    marginTop: "-3px",
  },
  menuItem: {
    padding: "0 5px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    rowGap: 4,
  },
  bolder: {
    fontWeight: "600",
  },

  upgradePlanModalWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "516px",
    backgroundColor: theme.palette.common.white,
    padding: "23px",
    borderRadius: "6px",
    justifyContent: "center",
  },
  upgradePlanButtonWrapper: {
    margin: "35px 0 10px 0",
    "& :first-child": {
      marginRight: "10px",
      boxShadow: "none",
    },
    "& button": {
      fontWeight: "bold",
    },
  },
  upgradePlanInnerWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  updragePlanDesp: {
    margin: "0 42px",
    textAlign: "center",
  },
  remainingReportsWrapper: {
    paddingLeft: "5px",
    margin: "10px 0",
  },
  selectedMenuItem: {
    backgroundColor: theme?.palette.action.hover,
  },
  chipStyle: {
    borderRadius: "16px",
    height: "26px",
    margin: "0 5px",
  },
}));

export const DateMenuStyled = styled(Menu)(() => ({
  "& .MuiPaper-root": {
    marginTop: "2px",
    maxHeight: "400px",

    //  scrollbar style
    "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      width: "6px",
      height: "10px",
      borderRadius: 23,
    },
    "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
      borderRadius: 23,
      backgroundColor: "rgba(0, 0, 0, 0.1)",
      outline: " 1px solid transparent",
      minHeight: 24,
    },

    "& ul": {
      paddingTop: "24px",

      "& li": {
        padding: "9px 83px 9px 30px ",
      },
    },
  },
}));

export const DateMenuAnchor = styled(Box)(() => ({
  height: "39px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  "& svg": {
    height: "18px",
    width: "28px",
  },
}));
