// lib
import * as R from "ramda";
import {
  format,
  formatDistanceToNowStrict,
  formatDuration,
  intervalToDuration,
  startOfYear,
  sub,
} from "date-fns";
import {QueryClient} from "react-query";
import {de, enUS} from "date-fns/locale";
// import { zonedTimeToUtc } from "date-fns-tz";
// src
import {costBasisListType} from "screens/settings/components/tax-rules/tax-rules";
import {
  taxYearListType,
  taxYearResidenceType,
  taxYearType,
} from "screens/taxes/tax-report/components/tax-date/tax-date";
import store from "store";
import {Trans} from "react-i18next";
import {FREE, storageKey} from "constants/";

export const isNothing = R.either(R.isEmpty, R.isNil);

export const isSomething = R.complement(isNothing);

export const getLocalStorageToken = () => {
  return localStorage.getItem(storageKey.LOCAL_STORAGE_USER_TOKEN_KEY);
};
export const getLocalStorageI18Lang = () =>
  localStorage.getItem(storageKey.LOCAL_STORAGE_I18_LANGUAGE_KEY);
export const setLocalStorageToken = (token: string) => {
  return localStorage.setItem(storageKey.LOCAL_STORAGE_USER_TOKEN_KEY, token);
};

export const clearLocalStorageToken = () => {
  return localStorage.removeItem(storageKey.LOCAL_STORAGE_USER_TOKEN_KEY);
};

export const setLocalStorageLanguage = (languageCode: string) => {
  return localStorage.setItem(
    storageKey.LOCAL_STORAGE_CONTENT_LANGUAGE_KEY,
    languageCode,
  );
};

export const getLocalStorageLanguage = () => {
  return localStorage.getItem(storageKey.LOCAL_STORAGE_CONTENT_LANGUAGE_KEY);
};

export const clearLocalStorageYearsData = () => {
  localStorage.removeItem(storageKey.LOCAL_STORAGE_TAX_MGMT_YEAR);
  localStorage.removeItem(storageKey.LOCAL_STORAGE_TAX_YEAR_KEY);
};

export const getTaxYear = () => {
  try {
    const taxYear =
      localStorage.getItem(storageKey.LOCAL_STORAGE_TAX_YEAR_KEY) || "";
    return JSON.parse(taxYear);
  } catch (e) {
    return "";
  }
};

export const getTaxMgmtActiveYear = () => {
  try {
    const taxMgmtActiveYear =
      localStorage.getItem(storageKey.LOCAL_STORAGE_TAX_YEAR_KEY) || "";
    return JSON.parse(taxMgmtActiveYear);
  } catch (e) {
    return "";
  }
};

export const getIsMobileView = () => {
  const isView = localStorage.getItem(storageKey.LOCAL_STORAGE_IS_MOBILE_VIEW);
  return R.equals(isView, "mobile");
};

export const getUidAndTokenFromURL = (url: string) => {
  const uid = url.slice(url.indexOf("uid=") + 4, url.indexOf("&token"));
  const token = url.slice(url.indexOf("&token") + 7);
  return {uid, token};
};

export const getParsedErrorMessage = (data: any) => {
  const FALLBACK_MESSAGE = `Something went wrong. Please try again later.`;
  const errors = R.pathOr("", ["response", "data", "errors"], data);
  return R.head(errors) || FALLBACK_MESSAGE;

  // let errorMessage = "";
  // errorMessage = R.pathOr("", ["response", "data", "nonFieldErrors"], data);
  // if (isSomething(errorMessage)) return errorMessage;

  // errorMessage = R.pathOr("", ["response", "data", "error"], data);
  // if (isSomething(errorMessage)) return errorMessage;

  // errorMessage = R.pathOr("", ["response", "data", "details"], data);
  // if (isSomething(errorMessage)) return errorMessage;

  // errorMessage = R.pathOr("", ["response", "data", "detail"], data);
  // if (isSomething(errorMessage)) return errorMessage;

  // errorMessage = R.pathOr("", ["data", "error"], data);
  // if (isSomething(errorMessage)) return errorMessage;
  // return R.pathOr("", ["message"], data);
};

export const getMaskedPhoneNumber = (number: string | undefined) => {
  if (!number) return "";
  const length = number.length;
  if (length < 9) return number;
  return number.replace(
    number.substring(3, length - 3),
    `${"*".repeat(length - 6)}`,
  );
};

export const getCurrencyLanguageCode = (languageCode: string) => {
  switch (languageCode) {
    case "en":
      return "en-US";
    case "de":
      return "de-DE";

    default:
      return "en-US";
  }
};

function getLengthBeforeDecimal(number: number): number {
  const numberString = String(Math.abs(number));
  const decimalIndex = R.indexOf(".", numberString);

  if (decimalIndex === -1) return numberString.length;

  return decimalIndex;
}

export const getFormattedCurrency = (
  amount: string | number,
  otherProps?: {
    decimalNum?: number;
    compactDisplay?: "long" | "short" | undefined;
    maxRange?: number;
    strictMode?: boolean;
    useDefaultCountryCode?: boolean;
    isSummary?: boolean;
  },
): string => {
  if (!isSomething(amount)) return "";

  const state = store.getState();
  const userLanguageCode = R.pathOr(
    "en",
    ["auth", "user", "profile", "language"],
    state,
  );

  const currencyCode = getCurrencyLanguageCode(userLanguageCode);

  const {
    decimalNum = 2,
    compactDisplay = "long",
    maxRange = 100000000,
    useDefaultCountryCode = false,
    isSummary = true,
  } = otherProps || {};
  const numb: number =
    typeof amount === "string" ? parseFloat(amount.replace(",", "")) : amount;

  if (isNaN(numb)) {
    return "";
  }

  const defaultCurrencyCode = useDefaultCountryCode ? "en-Us" : currencyCode;
  const hasLeadingZeros = R.lt(numb, 0.01) && R.gt(numb, -0.01);
  const isNumberInRangeOfOne = R.gt(numb, -1) && R.lt(numb, 1);
  const isNumberGtMillion = R.gte(numb, 1000000) || R.lte(numb, -1000000);
  const lengthBeforeDecimal: number = getLengthBeforeDecimal(numb);

  if (isSummary) {
    const formatNumber = Intl.NumberFormat(defaultCurrencyCode, {
      useGrouping: true,
      maximumFractionDigits: decimalNum,
      ...(!isNumberGtMillion && {
        maximumSignificantDigits: lengthBeforeDecimal + 2,
      }),
      ...((hasLeadingZeros || isNumberInRangeOfOne) && {
        maximumSignificantDigits: 4,
      }),
      ...(lengthBeforeDecimal >= 4 &&
        !isNumberGtMillion && {
          maximumSignificantDigits: lengthBeforeDecimal,
        }),
      ...(lengthBeforeDecimal >= 4 &&
        isNumberGtMillion &&
        numb <= maxRange && {
          maximumSignificantDigits: lengthBeforeDecimal,
        }),
      ...(numb >= maxRange && {
        notation: "compact",
        compactDisplay,
      }),
    });
    return formatNumber.format(numb);
  }

  const formatNumber = Intl.NumberFormat(defaultCurrencyCode, {
    useGrouping: true,
    maximumFractionDigits: decimalNum,
    ...((hasLeadingZeros || isNumberInRangeOfOne) && {
      maximumSignificantDigits: 4,
    }),
    maximumSignificantDigits: lengthBeforeDecimal + 2,
    ...(numb >= maxRange && {
      notation: "compact",
      compactDisplay,
    }),
  });
  return formatNumber.format(numb);
};

export const getFormattedCurrencyExtended = (
  amount: string | number,
  otherProps?: {
    roundOff?: boolean;
    decimalNum?: number;
    compactDisplay?: "long" | "short" | undefined;
    maxRange?: number;
    strictMode?: boolean;
    isSummary?: boolean;
  },
): string | JSX.Element => {
  if (!isSomething(amount)) return "";

  const numb: number = typeof amount === "string" ? parseFloat(amount) : amount;
  const isNumberInRangeOfOne = R.gt(numb, -1) && R.lt(numb, 1);

  const roundedOffNumber = Math.round(numb);
  const showRoundedOffNumb = otherProps?.roundOff && numb >= 1;

  const formattedNumber = getFormattedCurrency(
    showRoundedOffNumb ? roundedOffNumber : amount,
    otherProps,
  );
  const isNumberTooSmall =
    isNumberInRangeOfOne && R.gt(R.length(R.toString(formattedNumber)), 13);

  return (
    <span title={isNumberTooSmall ? formattedNumber : ""}>
      {!isNumberTooSmall
        ? formattedNumber
        : `${R.slice(0, 5, formattedNumber)}...`}
    </span>
  );
};

// getFormattedNumber is for the amounts that we need to format for using in
// input fields as the are strings including commas (,)
// which input fields don't accept
export const getFormattedNumber = (
  amount: string | number,
  otherProps?: {
    decimalNum?: number;
    compactDisplay?: "long" | "short" | undefined;
    maxRange?: number;
    strictMode?: boolean;
    useDefaultCountryCode?: boolean;
    isSummary?: boolean; //using this props means we are using default country code of en-Us
  },
): string | JSX.Element => {
  if (!isSomething(amount)) return "";

  const formattedCurrency = getFormattedCurrency(amount, otherProps);
  const formattedNumber = R.pipe(R.split(","), R.join(""))(formattedCurrency); //formatting amount to remove commas (,)

  return formattedNumber;
};

export const mapIndexed = R.addIndex(R.map);

export const metaTransformerFunction = (data: any) => {
  const meta = R.pipe(
    R.mapObjIndexed((x: any) =>
      R.map(
        (y: any) => ({
          value: R.propOr("", "code", y),
          name: R.propOr("", "title", y),
        }),
        x,
      ),
    ),
  )(data);
  const currenciesList = R.pathOr([], ["currencies"], meta);
  const languagesList = R.pathOr([], ["languages"], meta);
  const taxResidencesList = R.pathOr([], ["taxResidences"], meta);
  return {currenciesList, languagesList, taxResidencesList};
};

const getLanguage = (languageCode: string) => {
  switch (languageCode) {
    case "en":
      return enUS;
    case "de":
      return de;

    default:
      return enUS;
  }
};

export const getTimeAgo = (timeStamp: any, localeStr: string = "en") => {
  if (!timeStamp) return;
  const date = new Date(timeStamp);

  // const comparison = formatDistanceToNowStrict(date);
  const comparison = formatDistanceToNowStrict(date, {
    locale: getLanguage(localeStr),
  });
  return comparison;
};

export const getDuration = (timeStamp: any, localeStr: string = "en") => {
  if (!timeStamp) return;
  let duration = intervalToDuration({
    start: new Date(),
    end: new Date(timeStamp),
  });

  const comparison = formatDuration(duration, {
    locale: getLanguage(localeStr),
    format: ["years", "months", "days", "hours"],
  });
  return comparison;
};

interface GetFormattedDateWithLocaleProps {
  timeStamp: any;
  localeStr: string;
  dateFormat?: string;
}

export const getFormattedDateWithLocale = (
  props: GetFormattedDateWithLocaleProps,
) => {
  const {dateFormat = "dd MMM,yyyy", timeStamp, localeStr = "en"} = props;
  return format(timeStamp, dateFormat, {
    locale: getLanguage(localeStr),
  });
};

export const getparsedTransactionsInfo = (data: any) => {
  const totalPages = R.pathOr(1, ["data", "num_pages"], data);
  const transactionsExist = isSomething(
    R.pathOr([], ["data", "results"], data),
  );
  const totalNumber = R.pathOr(0, ["data", "total"], data);
  return {totalPages, transactionsExist, totalNumber};
};

export const inValidateListOfQueries = (
  queriesArray: string[],
  queryClient: QueryClient,
) =>
  R.map((query: string) => {
    queryClient.invalidateQueries(query);
  })(queriesArray);

export const transactionFeeCustomValidation = (val: any) => {
  if (!isSomething(val)) return true;
  if (val >= 0) return true;
  if (val < 0) return false;
  return true;
};

export const paginationInputValidationHelper = (
  pageInput: any,
  totalPages: number,
) => {
  if (!isSomething(pageInput)) return true;
  if (pageInput >= 1 && pageInput <= totalPages) return true;
  return false;
};

export const sortObjectByDate = (
  data: any,
  path: string[],
  ascending = false,
) => {
  const diff = function (a: any, b: any) {
    return (
      new Date(R.pathOr("", path, ascending ? a : b)).getTime() -
      new Date(R.pathOr("", path, ascending ? b : a)).getTime()
    );
  };
  return R.sort(diff, data);
};

export const convertEmptyValuesToNull = (data: any) => {
  const removeEmptyStrings = (value: any) => {
    return isSomething(value) ? value : null;
  };
  return R.mapObjIndexed(removeEmptyStrings, data);
};

export const addAssetValueObjectInFormData = (data: any) => {
  const buyValueAsset = R.pathOr("", ["buy_value_asset", "code"], data);
  const buyValueQuantity = R.pathOr("", ["buy_value_quantity"], data);
  const sellValueAsset = R.pathOr("", ["sell_value_asset", "code"], data);
  const sellValueQuantity = R.pathOr("", ["sell_value_quantity"], data);
  const feeValueAsset = R.pathOr("", ["fee_value_asset", "code"], data);
  const feeValueQuantity = R.pathOr("", ["fee_value_quantity"], data);
  const feeQuantity = R.pathOr("", ["fee_quantity"], data);
  const updatedData = R.pipe(
    R.omit([
      "buy_value_quantity",
      "buy_value_asset",
      "sell_value_asset",
      "sell_value_quantity",
      "fee_value_asset",
      "fee_value_quantity",
    ]),
    object => ({
      ...object,
      ...(isSomething(buyValueQuantity) && {
        buy_value: {[R.toLower(buyValueAsset)]: Number(buyValueQuantity)},
      }),
      ...(isSomething(sellValueQuantity) && {
        sell_value: {[R.toLower(sellValueAsset)]: Number(sellValueQuantity)},
      }),
      ...(isSomething(feeValueQuantity) && {
        fee_value: {[R.toLower(feeValueAsset)]: Number(feeValueQuantity)},
      }),
      //if fee quantity is null, fee_asset would also be null
      ...(!isSomething(feeQuantity) && {
        fee_asset: null,
      }),
    }),
  )(data);

  return updatedData;
};

export const removeUnchangedField = (data: any, dirtyFields: string[]) => {
  const omitfee =
    !R.includes("fee_quantity", dirtyFields) &&
    !R.includes("fee_asset", dirtyFields);

  const omitSellKeys =
    !R.includes("sell_quantity", dirtyFields) &&
    !R.includes("sell_asset", dirtyFields);

  const omitBuyKeys =
    !R.includes("buy_quantity", dirtyFields) &&
    !R.includes("buy_asset", dirtyFields);

  const feeQuantity = R.pathOr("", ["fee_quantity"], data);
  const sellQuantity = R.pathOr("", ["sell_quantity"], data);
  const buyQuantity = R.pathOr("", ["buy_quantity"], data);

  const updatedData = R.pipe(
    R.omit([
      `${
        !R.includes("destination_address", dirtyFields) && "destination_address"
      }`,
      `${omitfee && "fee_asset"}`,
      `${omitfee && "fee_quantity"}`,
      `${omitSellKeys && "sell_asset"}`,
      `${omitSellKeys && "sell_quantity"}`,
      `${omitBuyKeys && "buy_asset"}`,
      `${omitBuyKeys && "buy_quantity"}`,
      `${!R.includes("timestamp", dirtyFields) && "timestamp"}`,
      `${!R.includes("user_wallet", dirtyFields) && "user_wallet"}`,
      `${!R.includes("buyer_user_wallet", dirtyFields) && "buyer_user_wallet"}`,
      `${!R.includes("tag", dirtyFields) && "tag"}`,
    ]),
  )(data);

  // if quantity of an asset is null asset should also be made null
  return {
    ...updatedData,
    ...(!isSomething(feeQuantity) &&
      !omitfee && {
        fee_asset: null,
      }),
    ...(!isSomething(sellQuantity) &&
      !omitSellKeys && {
        sell_asset: null,
      }),
    ...(!isSomething(buyQuantity) &&
      !omitBuyKeys && {
        buy_asset: null,
      }),
  };
};

export const getFormattedPayloadEditTransactions = (
  data: any,
  dirtyFieldsArray: string[],
) => {
  // remove the keys with empty values or null
  const dataWithNoNullValues = convertEmptyValuesToNull(data);

  //add {fieldinitial}_value:{eur:number} objects to the payload
  const dataWithValueObj = addAssetValueObjectInFormData(dataWithNoNullValues);

  // remove the unchanged keys from the payload
  const transformedData = removeUnchangedField(
    dataWithValueObj,
    dirtyFieldsArray,
  );
  return transformedData;
};

export const getParsedMetaList = (
  data: any,
  isCountry: boolean,
): costBasisListType =>
  R.map((method: {id: number; label: string}) => {
    return {
      value: R.propOr("", "code", method),
      name: R.propOr("", "title", method),
    };
  }, data);

export const getFilteredTaxDates: any = (
  data: taxYearType[],
  countryCode: number,
) =>
  R.pipe(
    R.filter(R.pathEq(["tax_residence", "code"], countryCode)),
    R.map(yearData => {
      const startDate: Date = new Date(R.propOr("", "start_date", yearData));
      const endDate: Date = new Date(R.propOr("", "end_date", yearData));
      const id: number = R.propOr(0, "id", yearData);
      const taxResidence: taxYearResidenceType = R.propOr(
        {},
        "tax_residence",
        yearData,
      );

      return {
        id,
        year: format(startDate, "yyyy"),
        startDate: format(startDate, "MMMM dd, yyyy"),
        endDate: format(endDate, "MMMM dd, yyyy"),
        taxResidence,
        // adding dummy subscription flag until backend sends one
        subscription: Number(id) != 4,
      };
    }),
    //@ts-ignore
    R.sortBy(R.prop("year")),
    R.reverse,
  )(data);

export const getLocalizedDate = (date: Date, languageFileName: string) =>
  date.toLocaleDateString(languageFileName, {
    month: "long",
    day: "2-digit",
    year: "numeric",
    localeMatcher: "best fit",
  });
export const getFormattedYearValueWithMMM = (
  id: string,
  data: taxYearListType[],
) => {
  const yearData = R.find(R.propEq("id", Number(id)), data);

  const startDate: Date = new Date(R.propOr("", "startDate", yearData));
  const endDate: Date = new Date(R.propOr("", "endDate", yearData));

  return `${R.propOr("", "year", yearData)} — ${format(
    startDate,
    "MMM dd, yyyy",
  )} to ${format(endDate, "MMM dd, yyyy")}`;
};

export const getValueFromState = (
  prop: string[],
  list: Object[],
  userProfile: [],
  filter?: string,
) => {
  return R.pipe(
    R.filter(R.propEq("code", R.pathOr("", prop, userProfile))),
    R.head,
    R.propOr("", filter || "title"),
  )(list) as string;
};

export const getStartAndEndUTCDate = (activeFilter: {
  name: string;
  value: string;
}) => {
  const endDate = new Date();

  const getMonthlyDiffential = (filter: string) => {
    if (filter === "1M") return 1;
    if (filter === "6M") return 6;
    else return 0;
  };
  let startDate = sub(endDate, {
    years: activeFilter.name === "1Y" ? 1 : 0,
    months: getMonthlyDiffential(activeFilter.name),
    weeks: activeFilter.name === "7D" ? 1 : 0,
    days: activeFilter.name === "24H" ? 1 : 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  if (activeFilter.name === "YTD") {
    const startYearDate = startOfYear(endDate);
    const userTimezoneOffset = startYearDate.getTimezoneOffset() * 60000;
    startDate = new Date(startYearDate.getTime() - userTimezoneOffset);
  }
  const startDateUtc = startDate.toISOString().split(".")[0] + "Z";
  const endDateUtc = endDate.toISOString().split(".")[0] + "Z";

  // console.log("utc date range:", { startDateUtc, endDateUtc });
  return {
    startDateUtc,
    endDateUtc,
    activeFilter,
  };
};

export const getFormattedCurrencyProp = (
  item: Object,
  currencyCode: string,
  decimalNum?: number,
  compactDisplay?: "long" | "short" | undefined,
  isSummary?: boolean,
): string | JSX.Element =>
  getFormattedCurrencyExtended(R.propOr("", currencyCode, item), {
    compactDisplay,
    decimalNum,
    isSummary,
  });

export const isPositiveValue = (value: Object, currencyCode: string) => {
  return !(
    R.lt(Number(R.propOr("", currencyCode, value)), 0) ||
    R.startsWith("-", R.propOr("", currencyCode, value))
  );
};

export const getValueOrNone = (value: string) =>
  isSomething(value) ? value : "--";

export const getValueOrNoneFromObject = (
  value: Object,
  currencyCode: string,
  formattedValue: string | JSX.Element,
) => (isSomething(R.propOr("", currencyCode, value)) ? formattedValue : "--");

export const getComparator = <Key extends keyof any>(
  order: "asc" | "desc",
  orderBy: string,
): ((
  a: {[key in Key]: number | string},
  b: {[key in Key]: number | string},
) => number) => {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

export const descendingComparator = (a: any, b: any, orderBy: string) => {
  // for string values
  if (typeof a[orderBy] == "string" && typeof b[orderBy] == "string") {
    if (b[orderBy].toLowerCase() < a[orderBy].toLowerCase()) {
      return -1;
    }
    if (b[orderBy].toLowerCase() > a[orderBy].toLowerCase()) {
      return 1;
    }
  }

  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getWalletsSyncStatus = (walletsData: any) => {
  return R.pipe(
    R.pathOr([], ["data", "results"]),
    R.any(
      (wallet: any) =>
        R.propEq("status", "in_progress", wallet) ||
        R.propEq("status", "initial", wallet),
    ),
  )(walletsData);
};

export const getTaxCalculationSyncStatus = (taxData: any) => {
  return R.pipe(
    R.pathOr("", ["data", "results", 0, "status"]),
    R.equals("IN_PROGRESS"),
  )(taxData);
};

export const getSyncingToastMessage = (
  isAnyUserWalletSyncing: boolean,
  isTaxCalcInProgress: boolean = false,
) => {
  if (isAnyUserWalletSyncing && isTaxCalcInProgress) {
    return <Trans i18nKey="wallet_and_tax_toast_message" />;
  }
  if (isAnyUserWalletSyncing) {
    return <Trans i18nKey="wallet_sync_toast_message" />;
  }
  if (isTaxCalcInProgress) {
    return <Trans i18nKey="tax_calc_toast_message" />;
  }

  return "";
};

export const cleanNullValuesInObject: any = (data: any) =>
  R.pipe(
    R.reject(R.either(R.isNil, R.isEmpty)),
    R.map(R.when(R.is(Object), cleanNullValuesInObject)),
  )(data);

export const getGraphValue = (val: any) => {
  const formattedNumber = getFormattedCurrency(val);
  if (
    R.gt(Number(formattedNumber), -1) &&
    R.lt(Number(formattedNumber), 1) &&
    R.length(formattedNumber) > 13
  ) {
    return `0`;
  }
  return formattedNumber;
};

export const languageTranslationFunction = (
  languagesList: {value: string; name: string}[],
  t: (key: string) => string,
) => {
  return languagesList.map(({value, name}) => ({
    value,
    name: t(name.toLowerCase().replace(/ /g, "_")),
  }));
};

export const maskTextwithEllipsis = (
  text: string,
  limit: number = 12,
  options: {
    initialTextLength?: number;
    finalTextLength?: number;
  } = {
    initialTextLength: 10,
    finalTextLength: 3,
  },
) => {
  const defaultInitialTextLength = Math.floor(R.length(text) / 2);
  const initialTextLen: number = R.propOr(
    defaultInitialTextLength,
    "initialTextLength",
    options,
  );
  const initialText: string = R.slice(
    0,
    initialTextLen >= R.length(text)
      ? defaultInitialTextLength
      : initialTextLen,
    text,
  );
  const finalTextLen: number = R.propOr(0, "finalTextLength", options);
  const finalText: string = R.equals(0, finalTextLen)
    ? ""
    : R.slice(-finalTextLen, R.length(text), text);

  const ellipsis = R.equals(initialTextLen + finalTextLen, R.length(text))
    ? ""
    : "...";

  return R.length(text) > limit
    ? `${initialText}${ellipsis}${finalText}`
    : text;
};

export const filterByProduct = R.pipe(
  R.toPairs,
  R.filter(
    ([key, value]) => value.metadata && !R.equals(value.metadata.product, FREE),
  ),
  R.map(([key]) => key),
);
