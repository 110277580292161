// transaction tabs names
export const TOTAL_TRANSACTION_TAB = "total-transactions";
export const EDITED_TRANSACTION_TAB = "edited-transactions";
export const ERRORS_TRANSACTION_TAB = "error-transactions";

//filters endpoints
export const transactionFilters = {
  isEdited: "&is_edited=true",
  hasError: "&error_type__ne=none",
  missingCostError: "&error_type=missing_cost",
  missingFeeError: "&error_type=missing_fee",
  missingRateError: "&error_type=missing_rate",
  typeWithdrawl: "&type=Withdrawal",
  typeDeposit: "&type=Desposit",
  all: "",
};
