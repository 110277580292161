import {makeStyles} from "@mui/styles";
import {TableContainer} from "@mui/material";
import {styled} from "@mui/system";
import {appThemeConfigs} from "theme/theme";

export const useSettingsStyles = makeStyles((theme: any) => ({
  //common
  tabContentContainer: {
    padding: "35px 60px",
  },
  highlightedText: {
    fontWeight: "500",
    color: theme.palette.buttonPrimary,
  },
  thumbsFilled: {
    width: "18px",
    height: "18px",
    cursor: "pointer",
    color: "#FBBF21",
  },

  thumbsOutlined: {
    width: "18px",
    height: "18px",
    cursor: "pointer",
    color: "#6C7780",
  },

  comparisonUpgradeBtn: {
    width: "100px",
    fontSize: "14px",
    padding: "8px",
  },

  tooltipClass: {
    display: "flex",
    alignItems: "start",
  },

  flexCol: {
    display: "flex",
    flexDirection: "column",
    "& button": {
      color: "#000000",
    },
  },

  header: {
    justifyContent: "flex-start",
    alignItems: "baseline",
    gap: "4px",
    position: "absolute",
    width: "100%",
    top: "-80px",
    left: "0",
  },

  // settingsStyles
  root: {
    backgroundColor: theme.palette.common.white,
    display: "flex",
    minHeight: "80vh",
    maxHeight: "auto",
    marginTop: "93px",
    paddingBottom: "0",
  },
  noMinWidth: {minWidth: 0},
  BackBox: {
    position: "absolute",
    top: "-80px",
  },
  backContainer: {
    display: "flex",
    columnGap: "5px",
    cursor: "pointer",
  },
  headerBox: {
    position: "absolute",
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
    top: "-60px",
    alignItems: "center",
  },

  backBtnContainer: {
    "& button": {
      color: "#000000",
    },
  },

  headerBtnContainer: {
    display: "flex",
    columnGap: "16px",
    alignItems: "center",
  },
  stickyContainer: {
    position: "fixed",
    top: "89px",
    left: 0,
    right: 0,
    maxWidth: "1700px",
    minWidth: "700px",
    width: "100%",
    margin: "auto",
    zIndex: "10",
    // transition: "all 0.2s linear",
    padding: "0 50px",
    backgroundColor: "#F2F5FA",
  },
  tabsContainer: {
    border: `1px solid ${theme.palette.divider}`,
    borderTop: "none",
    borderTopRightRadius: "0px",
    borderBottomRightRadius: "0px",
    borderRadius: "12px",
    minWidth: "260px",
    textAlign: "left",

    "@media (max-width:1025px)": {
      minWidth: 209,
    },

    "& button": {
      width: "fit-content",
      textTransform: "none",
      fontSize: "18px",
      [theme?.breakpoints.down("lg")]: {
        fontSize: "16px",
      },
      [theme?.breakpoints.down("md")]: {
        fontSize: "15px",
      },
    },
    "& .MuiTabs-indicator": {
      left: "0",
      backgroundColor: theme.palette.primary.dark,
      width: "3px",
    },
  },

  btnContainer: {
    width: "100%",
    padding: "5px",
    borderBottom: `1px solid ${theme.palette.divider}`,

    "& button": {
      fontWeight: 400,
    },

    "& span": {
      position: "relative",
    },
  },
  activeClass: {
    backgroundColor: theme.palette.info.light,
    color: theme.palette.primary.dark,
    "& button": {
      fontWeight: "700",
    },
  },

  //Tabs common
  fieldsContainer: {
    width: "420px",

    [theme?.breakpoints.down(1171)]: {
      width: "350px",
    },
    [theme?.breakpoints.down("md")]: {
      width: "330px",
    },
  },
  //General Tab Styles
  containerMarginTop: {
    marginTop: "33px",
  },

  // Account Tab Styles
  divider: {width: "100%", marginTop: "20px"},
  accountContentWrapper: {
    width: "100%",
    marginTop: "25px",
    marginBottom: "25px",
  },
  deleteBtnWrapper: {
    width: "250px",
    fontSize: "12px",
    marginTop: "20px",
  },

  verifymodalWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-75%, -80%)",
    width: "377px",
    backgroundColor: theme.palette.common.white,
    padding: "32px",
    borderRadius: "10px",
  },
  buttonWrap: {
    display: "flex",
    columnGap: "15px",
    marginTop: "32px",
    marginBottom: "10px",
  },
  buttonContainer: {
    display: "flex",
    columnGap: "10px",

    "& button": {
      padding: 0,
    },
    // "& button:first-child": {
    //   marginLeft: "-10px",
    // },
  },

  //modal changePassword
  changePasswordWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "530px",
    backgroundColor: theme.palette.common.white,
    padding: "32px",
    borderRadius: "10px",
  },
  passwordInputsWrapper: {
    width: "100%",
    margin: "auto",
    padding: "20px 20px",
    paddingBottom: "0px",
  },
  otpWrapper: {
    width: "100%",
    padding: "20px 0px",
  },

  //modal Deactivate Account
  deactivateAcountWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "475px",
    backgroundColor: theme.palette.common.white,
    padding: "32px",
    borderRadius: "10px",
  },

  // Security Tab Styles

  mainWrapper: {
    marginTop: "50px",
    [theme?.breakpoints.down("lg")]: {
      marginTop: "30px",
    },
    [theme?.breakpoints.down("md")]: {
      marginTop: "20px",
    },
  },
  wrapper: {
    // width: "641px",
    width: "680px",
    marginTop: "15px",
    padding: "40px",
    paddingRight: "10px",
    backgroundColor: theme.palette.active.secondary,
    [theme?.breakpoints.down("lg")]: {
      padding: "20px",
      width: "600px",
    },
    [theme?.breakpoints.down("md")]: {
      padding: "20px",
      width: "auto",
    },
  },
  innerWrapper: {
    width: "100%",
    maxWidth: "220px",
    [theme?.breakpoints.down("lg")]: {
      width: "100%",
      maxWidth: "180px",
      minWidth: "180px",
    },
    [theme?.breakpoints.down("md")]: {
      maxWidth: "auto",
      minWidth: "auto",
      width: "auto",
    },
  },
  contentWrapper: {
    display: "flex",
    columnGap: "70px",
    [theme?.breakpoints.down("lg")]: {
      columnGap: "40px",
    },
    [theme?.breakpoints.down("md")]: {
      columnGap: "20px",
      flexWrap: "wrap",
    },
  },

  contentWrapperBottom: {
    display: "flex",
    columnGap: "70px",
    [theme?.breakpoints.down("lg")]: {
      columnGap: "35px",
    },
    [theme?.breakpoints.down("md")]: {
      columnGap: "15px",
      flexDirection: "column",
    },
  },
  emailChipWrapper: {
    display: "flex",
    width: "100%",
    maxWidth: "411px",
    justifyContent: "space-between",
    [theme?.breakpoints.down(1200)]: {
      maxWidth: "100%",
      width: "100%",
    },
    [theme?.breakpoints.down("md")]: {
      columnGap: "10px",
    },
  },

  authChipWrapper: {
    display: "flex",
    width: "100%",
    maxWidth: "411px",
    justifyContent: "space-between",
    [theme?.breakpoints.down(1200)]: {
      width: "100%",
      maxWidth: "100%",
    },
    [theme?.breakpoints.down("md")]: {
      columnGap: "10px",
    },
  },
  tickMargin: {
    marginTop: "5px",
  },
  securityDivider: {width: "100%", marginTop: "10px", marginBottom: "35px"},
  tableWrapper: {
    marginTop: "30px",
    "& th": {
      textAlign: "left",
    },
    "& td": {
      textAlign: "left",
    },
    "& thead": {
      backgroundColor: theme.palette.info.light,
      "& th": {
        fontWeight: "bold",
      },
    },
  },

  // Google Authentication Modal
  googleAuthModalWrapper: {
    maxWidth: "742px",
    width: "90vw",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    background: theme.palette.common.white,
    padding: "30px",
    borderRadius: "6px",
  },
  googleAuthModalDescription: {
    margin: "20px 0",
  },
  googleAuthModalQrSection: {
    display: "flex",
    columnGap: "20px",
    height: "200px",
  },
  qrContainer: {
    width: "200px",
    height: "200px",
    "& *": {
      width: "100%",
      height: "100%",
    },
  },
  qrInfoContainer: {
    width: "425px",
    background: theme.palette.background.default,
    padding: "10px",
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: "5px",
  },
  googleAuthModalFieldWrapper: {
    width: "400px",
    margin: "20px 0",
  },
  googleAuthModalButtonsContainer: {
    display: "flex",
    columnGap: "14px",
  },
  codesContainer: {
    color: theme.palette.text.secondary,
    marginBottom: "52px",
    border: `1px solid ${theme.palette.divider}`,
    height: "250px",
    overflowY: "auto",
  },

  // offered plans styles
  offeredPlanTableWrapper: {
    marginTop: "20px",
  },
  marginBottom: {
    marginBottom: "10px",
  },

  plansTypeWrapper: {
    paddingTop: "30px",
    display: "flex",
    justifyContent: "space-between",
  },
  dividerPadding: {
    paddingTop: "15px",
  },
  TaxPlanMargin: {
    marginLeft: "50px",
  },
  boderedSkeleton: {
    backgroundColor: theme?.palette.background.paper,
    padding: "0 20px",
    justifyContent: "space-between",
    border: `2px solid ${theme.palette.divider}`,
    gap: "10px",
    "& > *": {
      height: "55px",
    },
    "&:not(:last-child )": {
      borderBottom: "none",
    },
  },
  skeletonContainer: {marginTop: "32px", overflowX: "hidden"},
  centeredSkelton: {
    display: "grid",
    alignItems: "center",
  },
  skeletonAlign: {
    alignItems: "center",
  },
  skeletonMargin: {
    marginLeft: "8px",
  },
  skeletonIconText: {
    display: "flex",
    alignItems: "center",
  },

  //offered Plansmodal

  offeredPlansModalWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "700px",
    backgroundColor: theme.palette.common.white,
    padding: "32px",
    borderRadius: "10px",
  },
  paymentWrapper: {
    display: "flex",
    columnGap: "16px",
    alignItems: "center",
    position: "relative",

    "& svg": {
      color: theme.palette.icon.default,
    },
  },

  blueTick: {
    "& svg": {
      color: theme.palette.info.main,
    },
  },
  currency: {
    textTransform: "capitalize",
  },
  palnSubscribedChip: {
    position: "absolute",
    right: 0,
  },
  plansFeaturesContainer: {
    marginTop: "3px",
    // height: "145px",
    display: "flex",
    flexDirection: "column",
    gap: 8,
    marginBottom: "5px",
  },
  activePlan: {
    border: "2px solid #1da868 !important",
  },
  planWrapper: {
    border: `2px solid ${theme.palette.divider} `,
    borderRadius: "10px",
    padding: "21px 23px",
    cursor: "pointer",
    transition: "all 0.2s ease",

    "&:hover": {
      backgroundColor: "rgba(219, 237, 251,0.5)",
    },
  },
  paymentGridWrapper: {
    marginTop: "32px",
    marginBottom: "57px",
  },
  iconPadding: {
    paddingLeft: "5px",
  },

  // Tax Rules styles
  taxRulesForm: {
    display: "flex",
    flexDirection: "column",
    gap: 5,
    width: "500px",
    marginTop: "40px",

    [theme?.breakpoints.down(1171)]: {
      width: "300px",
    },
  },
  extendedFieldsWrapper: {
    width: "735px",

    [theme?.breakpoints.down(1171)]: {
      width: "515px",
    },
    [theme?.breakpoints.down(1024)]: {
      width: "300px",
    },
  },
  customWidth: {
    // width: "calc(100% + 38px)",
    // width: 460,
  },
  //emails and newsletters
  toggleSwicthesContainer: {
    marginTop: "40px",
    width: "300px",
    display: "flex",
    flexDirection: "column",
    rowGap: "40px",
  },

  //  no data state
  noDataStateWrapper: {
    padding: "50px",
  },

  // plan & payment
  paymentTabContainer: {
    width: "60vw",
    maxWidth: "820px",

    [theme?.breakpoints.down(1140)]: {
      width: "55vw",
    },
    [theme?.breakpoints.down(1024)]: {
      width: "calc(60vw - 25px)",
    },
  },
  borderedBox: {
    border: `1px solid ${theme.palette.divider}`,
    display: "flex",
    justifyContent: "space-between",
    borderRadius: "8px",
    padding: "20px 25px",
    margin: "15px 0 0 0",
    alignItems: "center",
  },
  flexRow: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  flexWrap: {
    flexWrap: "wrap",
    marginBottom: 60,
  },
  skeletonColumnFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    width: "100%",
    marginTop: 50,
  },
  flexRowWrapped: {flexWrap: "wrap"},
  cardIcon: {
    width: "41px",
    height: "41px",
    marginRight: "8px",
  },
  borderedBoxWidth: {
    width: "48%",
    height: "100px",
    minWidth: 300,
  },
  isSkeletonCard: {backgroundColor: "#f4f4f4"},
  checkoutWidth: {
    width: "90%",
    height: "100px",
  },
  checkoutFullWidth: {
    width: "100%",
    marginBottom: 0,
  },
  checkoutWidthPayment: {
    width: "49%",
    height: "100px",
    minWidth: 400,
  },

  iconsWidth: {
    width: "62px",
    justifyContent: "space-between",
  },
  addPaymentMethod: {
    border: `2px dashed ${theme.palette.divider}`,
    borderImage: 'url("https://i.ibb.co/SchbXZ8/dashed-border.png") 2 round',
    display: "flex",
    justifyContent: "center",
    borderRadius: "8px",
    padding: "20px 30px",
    margin: "15px 0 0 0",
    alignItems: "center",
  },
  paymentMethodChange: {
    fontSize: "15px",
    fontWeight: "bold",
  },
  buttonDesp: {
    color: "#000",
    fontSize: "17px",
    fontWeight: "500",
    marginRight: "15px",
  },
  styledTitle: {
    alignItems: "center",
    display: "flex",
    // width: "202px",
    justifyContent: "space-between",
    color: theme?.palette.info.main,
    fontWeight: "500",
  },
  billingDetailsRoot: {
    //padding: "20px",
    maxWidth: "545px",
    marginBottom: "60px",

    "& .MuiFormControlLabel-root": {
      marginLeft: "-9px",

      columnGap: "0",
    },
    "& .MuiTextField-root": {
      backgroundColor: "white",
    },
    "& .MuiTextField-root .Mui-disabled": {
      backgroundColor: "#f2f5fa",
    },
  },
  billingDetailsRootPadding: {
    // padding: "30px",
  },
  customBtnColor: {
    color: theme.palette.text.error,
  },
  customBtnSize: {
    fontSize: "16px",
    fontWeight: "500",
  },
  boxWidth: {
    width: "200px",
  },
  billingDetailsTopFlex: {gap: 32, minHeight: 48},
  boxWidthLg: {
    // width: "210px",
    display: "flex",
    gap: 10,
  },
  isHidden: {
    // visibility: "hidden",
    display: "none",
  },
  editStyle: {
    // backgroundColor: theme.palette.background.default,
    // borderRadius: "8px",
  },
  topBoxStyle: {
    margin: "0 10px 15px 0",
  },
  fullWidth: {
    width: "100%",
  },
  pointerCursor: {
    cursor: "pointer",
  },
  primaryCardChip: {
    borderRadius: 14,
    padding: "12px 6px",
    marginLeft: "10px",
    fontSize: "12px",
  },
  statusCardChip: {
    borderRadius: 10,
    padding: "16px 10px",
    fontSize: "12px",
  },
  billingHistoryRoot: {
    marginBottom: "80px",
  },
  billingHistoryPlan: {
    minWidth: "130px",
  },
  billingHistoryAmount: {
    textTransform: "uppercase",
    textAlign: "right",
  },
  inactiveText: {
    color: theme?.palette.text.secondary,
    marginRight: "10px",
  },
  paymentPlanRoot: {
    display: "flex",
    flexDirection: "column",
    margin: "20px auto",
    padding: "0 32px",
    width: "100%",
  },
  planBoxStyle: {
    borderRadius: "20px",
    border: `1px solid ${theme?.palette.icon.default}`,
    height: "590px",
    width: "310px",
    minWidth: "310px",
    padding: "0 20px 32px",
    justifyContent: "flex-start",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  greenBorder: {
    border: `3px solid ${theme?.palette.success.dark}`,
  },
  bestSellingPlanBorder: {
    // border: `3px solid ${theme?.palette.info.main}`,
  },
  currentPlanBorder: {
    // border: `3px solid ${theme?.palette.success.dark}`,
  },

  simpleBorder: {
    borderColor: theme?.palette.text.secondary,
  },
  centeredTitle: {
    textAlign: "center",
    margin: "10px 0 40px 0",
  },
  plansBoxRoot: {
    flexWrap: "wrap",
    justifyContent: "center",
    // margin: "0 auto",
    gap: 32,
  },
  planCardsMobileView: {
    gap: 10,
    justifyContent: "center",
    flexWrap: "wrap",
    width: "auto",
  },
  planChip: {
    justifyContent: "right",
    display: "flex",
    padding: "12px 6px 6px 6px",
    height: "38px",
    width: "100%",
  },

  justifyStart: {
    justifyContent: "left",
  },
  justifyBetween: {
    justifyContent: "space-between",
  },

  chipStyle: {
    borderRadius: "4px",
    height: "28px",
    fontWeight: 500,
  },
  planIcon: {
    height: "75px",
    width: "75px",
    borderRadius: "8px",
  },
  flexRowSimple: {
    display: "flex",
    alignItems: "end",
  },
  intervalMargin: {
    marginBottom: "9px",
  },
  planNameBox: {
    margin: "15px 0 0 15px",
  },
  boldLinkButton: {
    fontWeight: "bold",
  },
  upgradePlanButton: {
    backgroundColor: theme?.palette.info.main,
    color: theme?.palette.text.tertiary,
    border: "none",
    "&:hover": {
      backgroundColor: theme?.palette.info.main,
      color: theme?.palette.text.tertiary,
    },
  },
  upgradePlanOutlinedButton: {
    color: theme?.palette.info.main,
    borderColor: theme?.palette.info.main,
    "&:hover": {
      backgroundColor: theme?.palette.info.main,
      color: theme?.palette.text.tertiary,
      borderColor: theme?.palette.info.main,
    },
  },

  disabledStyle: {
    pointerEvents: "inherit !important" as "inherit",
    "&.Mui-disabled": {
      border: "none",
      outline: "none",
    },
    "&:hover": {
      borderColor: theme?.palette.icon.default,
      backgroundColor: theme?.palette.text.tertiary,
      color: theme?.palette.icon.default,
      cursor: "not-allowed",
    },
  },
  buttonsMargin: {
    marginTop: "auto",
  },
  cancelSubscriptionBtn: {
    color: theme.palette.info.main,
  },
  comparisionButton: {
    color: theme?.palette.text.secondary,
    marginTop: "35px",
  },
  footer: {
    width: "100%",
    marginTop: "40px",
  },
  footerButtonContainer: {
    margin: "25px 0",
    padding: "0 30px",
    display: "grid",
    gridTemplateColumns: "1fr auto",
    "& button": {
      width: "126px",
    },
  },
  planYearSelectionButtonWrapper: {
    margin: "25px 0 10px 0",
    display: "flex",
    gap: 10,
    flexWrap: "wrap",

    "& :first-child": {
      boxShadow: "none",
    },

    "& button": {
      fontWeight: "bold",
    },
  },
  planYearSelectionModalWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "475px",
    backgroundColor: theme.palette.common.white,
    padding: "32px",
    borderRadius: "6px",
  },
  fullWidthModal: {maxWidth: 450, width: "90%"},
  fieldWrapper: {
    width: "100%",
    "& svg": {color: "#BEC2C4 !important"},
  },
  planYearSelectFieldWrapper: {
    width: "80%",
    height: "42px",
    margin: "10px 0 20px 0",
  },
  placeholderStyle: {
    color: theme?.palette.text.secondary,
  },
  billingDetailsCheckoutWidth: {
    width: "90%",
    marginBottom: "60px",
  },
  billingDetailsCheckoutFullWidth: {width: "100%"},
  flexStart: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",

    "& button": {
      marginLeft: "5px",
      fontSize: "16px",
    },
  },
  titleMargin: {
    marginRight: "8px",
  },
  planDesp: {
    height: "fit-content",
  },
  chipMargin: {
    marginRight: "5px",
  },
  planCardChip: {
    display: "flex",
    alignItems: "center",
    gap: "7.5px",
  },
  iconMargin: {
    marginRight: "18px",
  },
  menuClass: {
    "& .MuiPaper-root": {
      "&::before": {
        width: "0px",
        height: "0px",
      },
    },

    "& li": {
      padding: "13px 15px",
      // width: "250px",
      minWidth: "250px",

      "& p": {
        display: "flex",

        "& svg": {
          marginRight: "10px",
        },
      },
    },
    "& li:last-child div p": {
      color: theme.palette.text.error,
    },
  },
  confirmationModalWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "475px",
    backgroundColor: theme.palette.common.white,
    padding: "32px",
    borderRadius: "10px",

    [theme?.breakpoints.down("md")]: {
      width: "90%",
      maxWidth: "475px",
    },

    "& div": {
      display: "flex",

      "& label": {
        justifyContent: "flex-start",
        width: "100px",
      },
    },
  },
  confirmationButtonWrap: {
    display: "flex",
    columnGap: "15px",
    marginTop: "20px",
    marginBottom: "10px",
  },
  featuresText: {
    textAlign: "left",
  },
  titleWrapper: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  titleWidth: {
    width: "90%",
    // width: "62%",
    minWidth: "300px",
    "& button": {fontSize: "16px", marginLeft: "7px"},
  },
  diaplayContent: {display: "contents"},
  iconMarginBot: {"& svg": {marginBottom: "2px"}},

  comparisonWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "48px",
    width: "100%",
  },

  comparisonTableContainer: {
    margin: "64px 0px",
  },

  firstCell: {
    width: "400px",
  },

  comparisonTableCell: {
    boxSizing: "border-box",
    height: "60px",
    padding: "0 20px",
    minWidth: "184px",
    [theme?.breakpoints.down(1200)]: {
      minWidth: "152px",
    },
    [theme?.breakpoints.down(920)]: {
      minWidth: "130px",
    },
  },

  comparisonTableBox: {
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  lastRowCell: {
    height: "10px",
  },
  accordionBtn: {
    fontSize: "16px",
    fontWeight: 500,
  },

  featureHeadingsRow: {
    paddingTop: "48px",
  },

  accordionRoot: {
    boxShadow: "none",
    "&.MuiAccordion-root:before": {
      content: "none",
    },
  },
  accordionSummary: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 0,
    paddingRight: 0,
    "& .MuiAccordionSummary-content": {
      width: "fit-content",
      flexGrow: "0",
    },
  },
  advancedBG: {
    height: "100%",
    backgroundColor: theme?.palette?.plans?.advanced,
  },
  proBG: {
    height: "100%",
    backgroundColor: theme?.palette?.plans?.pro,
  },
  roundedTop: {
    borderRadius: "12px 12px 0 0",
    paddingTop: "18px",
  },

  roundedBottom: {
    borderRadius: "0 0 12px 12px ",
    paddingBottom: "18px",
  },
}));

interface BillingHistoryStyledProps {
  theme?: typeof appThemeConfigs;
}

export const BillingHistoryTableContainer = styled(TableContainer)(
  (props: BillingHistoryStyledProps) => {
    const {theme} = props;
    return {
      maxHeight: "360px",
      borderRadius: "9px",
      marginTop: "20px",
      border: `1px solid ${theme?.palette.divider}`,
      overflowY: "auto",

      "& thead": {
        "& tr": {
          "& th": {
            backgroundColor: theme?.palette.background.default,
            fontSize: "16px",
            color: "#000",
            fontWeight: "500",
            padding: "20px",
            borderBottom: `1px solid ${theme?.palette.divider}`,
          },
        },
      },

      "& tbody": {
        "& tr": {
          "& td": {
            borderBottom: `1px solid ${theme?.palette.divider}`,
            padding: "8px 20px",
            color: theme?.palette.text.secondary,
          },
        },
        "& tr:last-child td": {
          borderBottom: "none",
        },
      },
    };
  },
);
