// lib
import {makeStyles} from "@mui/styles";

export const useSnackBarsStyles = makeStyles((theme: any) => ({
  root: {
    "& .css-4oixxw-MuiSnackbar-root": {
      top: "110px",
      right: "0px",
      margin: "0 57px",
      maxWidth: "1500px",
    },
    "& .MuiAlert-root": {
      display: "grid",
      gridTemplateColumns: "auto 1fr auto",
      justifyItems: "start",
      alignItems: "center",
      gap: 12,
      padding: "15px",

      "& >*": {
        margin: "0px",
        padding: "0px",
      },

      "& .css-ki1hdl-MuiAlert-action": {
        color: theme.palette.text.secondary,
      },
    },
    "& .MuiAlert-filledSuccess": {
      backgroundColor: theme.palette.success.light,
      color: "#1b2126",
      border: `1px solid ${theme.palette.success.dark}`,
    },
    "& .MuiAlert-filledError": {
      backgroundColor: theme.palette.error.light,
      border: `1px solid ${theme.palette.error.main}`,
      color: theme.palette.text.primary,
    },
    "& .MuiAlert-filledInfo": {
      backgroundColor: theme.palette.active.secondary,
      border: `1px solid ${theme.palette.info.main}`,
      color: theme.palette.text.primary,
    },
  },
}));
