import {Box} from "@mui/material";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import {styled} from "@mui/material/styles";
import {DescriptionStyled} from "../../../../../../../styles/common";
import {CustomButton} from "../../../../../../../submodules/capital-bee-web-ui-kit/src/components/CustomButton";
import {useTranslation} from "react-i18next";

const FieldContainer = styled(Box)(() => {
  return {
    width: "450px",
    marginTop: "25px",
  };
});

const GridContainer = styled(Box)(() => {
  return {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    columnGap: "32px",
    marginTop: "15px",
  };
});

const StyledCardNumberElement = styled(CardNumberElement)((props: any) => {
  const {theme} = props;

  return {
    color: theme?.palette.text.primary,
    fontSize: 16,
    fontWeight: "normal",
    // padding: `5px 10px`,
    border: `2px solid ${theme?.palette.divider}`,
    borderRadius: "4px",
    // margin: "10px 0",
    marginTop: "0",
    padding: "12px",
  };
});
const StyledCardExpiryElement = styled(CardExpiryElement)((props: any) => {
  const {theme} = props;

  return {
    color: theme?.palette.text.primary,
    fontSize: 16,
    fontWeight: "normal",
    // padding: `5px 10px`,
    border: `2px solid ${theme?.palette.divider}`,
    borderRadius: "4px",
    // margin: "10px 0",
    marginTop: "0",
    padding: "12px",
  };
});
const StyledCardCvcElement = styled(CardCvcElement)((props: any) => {
  const {theme} = props;

  return {
    color: theme?.palette.text.primary,
    fontSize: 16,
    fontWeight: "normal",
    // padding: `5px 10px`,
    border: `2px solid ${theme?.palette.divider}`,
    borderRadius: "4px",
    // margin: "10px 0",
    marginTop: "0",
    padding: "12px",
  };
});

const RenderSubscription = ({handleCardSubmit, handleClose, classes}: any) => {
  const {t} = useTranslation();
  return (
    <Box>
      <form onSubmit={handleCardSubmit} id="payment-form">
        {/* <CardNumberElement
          options={{
            style: {
              base: inputStyle,
            },
          }}
        /> */}
        <FieldContainer>
          <Box>
            <DescriptionStyled noPadding size="lg" color="secondary">
              {t("card_number")}
            </DescriptionStyled>
            <StyledCardNumberElement />
          </Box>
          <GridContainer>
            <Box>
              <DescriptionStyled noPadding size="lg" color="secondary">
                {t("expiration_date")}
              </DescriptionStyled>
              <StyledCardExpiryElement />
            </Box>
            <Box>
              <DescriptionStyled noPadding size="lg" color="secondary">
                CVC
              </DescriptionStyled>
              <StyledCardCvcElement />
            </Box>
          </GridContainer>
        </FieldContainer>
        <Box className={classes.buttonWrap}>
          <CustomButton type="submit" text={t("subscribe")} />
          <CustomButton
            text={t("cancel")}
            variant="outlined"
            onClick={handleClose}
          />
        </Box>
      </form>
    </Box>
  );
};
export default RenderSubscription;
