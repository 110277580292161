import {makeStyles} from "@mui/styles";

export const useStyles = makeStyles((theme: any) => ({
  root: {},
  menuClass: {
    "& .MuiPaper-root": {
      [theme.breakpoints.up("sm")]: {
        padding: "3px 10px",
      },
      [theme.breakpoints.up("md")]: {
        padding: "4px 12px",
      },
      "&::before": {
        width: "0px",
        height: "0px",
      },
    },
    "& li:hover": {backgroundColor: "#F2F5FA !important"},
  },
  userContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  ellipses: {
    [theme?.breakpoints.down("lg")]: {
      display: "none",
    },
  },
  icon: {
    marginRight: "-10px",
    color: "white",
  },
  avatar: {
    marginRight: "12px",
    backgroundColor: "#2988df",
    textTransform: "capitalize",
    fontSize: "16px",
  },
  imageAvatar: {
    display: "grid",
    placeItems: "center",
    textTransform: "capitalize",
    fontSize: "14px",
    backgroundColor: theme.palette.active.secondary,
    color: theme.palette.info.main,
    paddingRight: "0px !important",
  },
  roundedImage: {
    borderRadius: "50%",
  },
  remainingDaysWrapper: {
    display: "flex",
    alignItems: "center",
    gap: 5,
  },
  flexRow: {
    display: "flex",
    gap: "12px",
    alignItems: "baseline",
    "& svg": {
      transform: "translateY(3px) scale(1.1)",
      height: "19px",
      width: "19px",
    },
  },
  itemContainer: {
    width: "100%",
    flex: 1,
  },
  borderStyle: {borderBottom: `1px solid ${theme.palette.divider}`},
  flexedContainer: {
    width: "250px",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",

    [theme.breakpoints.up("sm")]: {
      width: "280px",
    },
    [theme.breakpoints.up("md")]: {
      width: "358px",
    },
  },
  referFriendText: {maxWidth: 200, whiteSpace: "pre-wrap"},
  logout: {
    color: theme.palette.error.main,
  },
  menuIcon: {
    height: "50px",
    width: "50px",
    borderRadius: "8px",
  },
  flexRowSimple: {
    display: "flex",
    alignItems: "center",
  },
  intervalMargin: {
    marginBottom: "9px",
    whiteSpace: "pre-wrap",
  },
  menuNameBox: {
    margin: "0 0 0 12px",
    maxWidth: 160,
  },
  menuItemPlan: {
    borderRadius: "10px",
  },
  flexRowSpaceBetween: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
  menuUpgradeButton: {
    fontSize: "15px",
    height: "40px",
    maxWidth: "150px",
  },
  menuItemRoot: {
    minHeight: "55px",
    margin: "3px 0",
  },
  newNotification: {
    backgroundColor: theme?.palette.info.main,
    borderRadius: "50%",
    height: "7px",
    width: "7px",
    marginTop: "-47px",
    marginLeft: "-10px",
  },
  comingSoonChip: {height: 28, fontSize: 14},
}));
