// lib
import * as R from "ramda";
import {Box} from "@mui/system";
import classNames from "classnames";
import {useTranslation} from "react-i18next";
import {useNavigate, useLocation} from "react-router-dom";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import {MenuItem, Modal, Select, SelectChangeEvent} from "@mui/material";

// src
import {DescriptionStyled, TitleStyled} from "styles/common";
import {useFetchPaymentTaxYears} from "services/api/settings";
import {useSettingsStyles} from "screens/settings/settings.styles";
import {getFormattedYearValueWithMMM, getIsMobileView} from "utils";
import {useGetTaxYearsWithSubscriptionInfo} from "utils/meta-hooks";
import {useFetchTotalTransactionsPerYear} from "services/api/dashboard";
import DateCard from "screens/taxes/tax-report/components/tax-date/date-card";
import {TaxYearsProps} from "screens/taxes/tax-report/components/tax-date/tax-date";
import {CustomButton} from "submodules/capital-bee-web-ui-kit/src/components/CustomButton";

interface PaymentPlanYearSelectionModalModalType {
  open?: boolean;
  handleClose: (a: boolean) => void;
  handleOrderReview: any;
  planType: string;
  setTaxYearID: (a: string) => void;
  id?: string;
  setSelectedYearTitle: (a: string) => void;
  planKey: string;
  transactionsLimit: string;
  selectedYearID: number;
  selectedYearFormattedValue: string;
  setSelectedYearID: (val: number) => void;
  setSelectedYearFormattedValue: (val: string) => void;
  showBackButton?: boolean;
}
type YearData = {
  total_transactions: number;
  trade_transactions: number;
  attention_needed: number;
  auto_fixed: number;
};

type YearlyData = {
  [year: string]: YearData;
};

const PaymentPlanYearSelectionModal = (
  props: PaymentPlanYearSelectionModalModalType,
) => {
  const {
    open = false,
    handleClose,
    handleOrderReview,
    planType,
    planKey,
    transactionsLimit,
    setTaxYearID,
    setSelectedYearTitle,
    selectedYearID,
    selectedYearFormattedValue,
    setSelectedYearID,
    setSelectedYearFormattedValue,
    showBackButton = false,
  } = props;
  const classes = useSettingsStyles();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {state} = useLocation();

  const backNavLink: string = R.pathOr("", ["backNavLink"], state);
  const widgetName: string = R.pathOr("", ["widgetName"], state);

  const {data: taxYearsSubscribed} = useFetchPaymentTaxYears();

  const {data} = useFetchTotalTransactionsPerYear();
  const transactionsPerYear = R.pathOr(
    [],
    ["data"],
    data,
  ) as unknown as YearlyData;

  const result = Object.entries(transactionsPerYear).map(
    ([yearTitle, yearData]) => ({
      yearTitle,
      totalTransactions: yearData.total_transactions,
    }),
  );

  const subscribedYearsIDs = R.pluck(
    // @ts-ignore
    "id",
    R.pathOr([], ["data", planKey, "tax_years"], taxYearsSubscribed),
  );

  const {yearsList}: TaxYearsProps = useGetTaxYearsWithSubscriptionInfo();

  const handleChange = (event: SelectChangeEvent) => {
    // event.target.value is the selected year's ID
    const yearID = event.target.value;
    const yearTitle = R.pipe(
      R.filter(R.propEq("id", yearID)),
      R.head,
      R.propOr("", "year"),
    )(yearsList);

    setSelectedYearTitle(yearTitle as string);
    setTaxYearID(`${yearID}`);

    setSelectedYearFormattedValue(
      getFormattedYearValueWithMMM(yearID, yearsList),
    );
    setSelectedYearID(Number(yearID));
  };

  const isNotAdvancedTax = !R.equals(planType, "Advanced Tax");

  const menuList = yearsList.map((item, index) => {
    const yearID = R.propOr(-1, "id", item);

    const isTransacationLimitExceeding =
      result[index]?.totalTransactions > Number(transactionsLimit);

    const isSubscribedYear = R.includes(yearID, subscribedYearsIDs);
    return (
      <MenuItem
        value={Number(yearID)}
        key={index}
        disabled={isSubscribedYear || isTransacationLimitExceeding}
      >
        <DateCard
          isRestricted={isTransacationLimitExceeding}
          year={R.propOr("", "year", item)}
          startDate={R.propOr("", "startDate", item)}
          endDate={R.propOr("", "endDate", item)}
          selectedDate={selectedYearID}
          index={index}
          alreadySubscribed={isNotAdvancedTax && isSubscribedYear}
        />
      </MenuItem>
    );
  });

  const isMobileView = getIsMobileView();

  const goBack = () => {
    navigate(backNavLink);
  };

  return (
    <Modal open={open} onClose={() => handleClose(false)}>
      <Box
        id={`review-order-modal`}
        className={classNames({
          [classes.planYearSelectionModalWrapper]: true,
          [classes.fullWidthModal]: isMobileView,
        })}
      >
        {showBackButton && (
          <Box className={classes.backBtnContainer}>
            <CustomButton
              text={t(widgetName)}
              variant="info"
              startIcon={<ArrowBackSharpIcon />}
              onClick={goBack}
            />
          </Box>
        )}
        <TitleStyled size="md" bold id="title">
          {`${planType} ${t("subscription")}`}
        </TitleStyled>
        <DescriptionStyled size="lg" color="secondary" id="description">
          {`${planType} ${t("payment_plan_year_selection_desp")}`}
        </DescriptionStyled>
        <Box className={classes.fieldWrapper}>
          {/* <SelectField name={"userWallet"} control={control} list={[]} /> */}
          <Select
            value={""}
            onChange={handleChange}
            displayEmpty
            inputProps={{"aria-label": "Without label"}}
            className={`${classes.planYearSelectFieldWrapper} ${
              !!!selectedYearFormattedValue && classes.placeholderStyle
            }`}
            placeholder={t("select_tax_year")}
            renderValue={() =>
              !!!selectedYearFormattedValue
                ? t("select_tax_year")
                : selectedYearFormattedValue
            }
            id="select-tax-year"
          >
            {menuList}
          </Select>
        </Box>
        <DescriptionStyled size="lg" color="secondary" id="info">
          {t("plan_cant_apllied_to_another_year")}
        </DescriptionStyled>
        <Box className={classes.planYearSelectionButtonWrapper}>
          <CustomButton
            text={t("review_order")}
            onClick={() => {
              handleOrderReview();
              handleClose(false);
            }}
            variant="contained"
            size="medium"
            disabled={!!!selectedYearFormattedValue}
            sx={{fontWeight: "bold"}}
            id="review-order-btn"
          />
          <CustomButton
            text={t("cancel")}
            variant="outlined"
            onClick={() => {
              setSelectedYearID(-1);
              setSelectedYearFormattedValue("");
              handleClose(false);
            }}
            id="cancel-btn"
          />
        </Box>
      </Box>
    </Modal>
  );
};

export default PaymentPlanYearSelectionModal;
