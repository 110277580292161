// lib
import axios from "axios";
import * as R from "ramda";
import {useMutation} from "react-query";
import {useDispatch} from "react-redux";

// src
import store from "store";
import {resetAuth} from "store/auth";
import {setLoadingState, setToaster} from "store/ui";
import {
  clearLocalStorageToken,
  getParsedErrorMessage,
  getLocalStorageToken,
  isSomething,
  getLocalStorageI18Lang,
} from "utils";

export const customAxioCall = async (payload: any) => {
  const {url, data, method = "post", dispatch} = payload;
  const language = getLocalStorageI18Lang();

  try {
    const response = await axiosCall({
      url,
      method,
      data,
      headers: {
        "Accept-Language": language,
      },
    });
    return {response, error: undefined};
  } catch (error: any) {
    console.error(error?.message || "", error?.response);
    const errorMessage = getParsedErrorMessage(error?.response);
    if (dispatch) {
      dispatch(
        setToaster({
          message: isSomething(errorMessage) ? errorMessage : error?.message,
          type: "error",
        }),
      );
    }
    return {response: undefined, error: error.response || error};
  }
};

export interface AxiosCallParamsType {
  headers?: any;
  method?: any;
  url: string;
  data?: any;
  responseType?: any;
  accessToken?: string;
  authTokenRequired?: boolean;
}

// const {logout} = useLogout();
axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // console.log("response::interceptor", response);
    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const {dispatch} = store;
    console.log("error::interceptor");
    const noPermissionCodeList = [403, 401];

    const status =
      R.pathOr(null, ["status"], error) ||
      R.pathOr(null, ["response", "status"], error);

    if (R.includes(status, noPermissionCodeList)) {
      dispatch(resetAuth());
      clearLocalStorageToken();
    }

    return Promise.reject(error);
  },
);

export const axiosCall = ({
  headers,
  method = "get",
  url,
  data,
  responseType,
  accessToken,
  authTokenRequired = true,
}: AxiosCallParamsType) => {
  // header can be here
  // get token here
  const token = getLocalStorageToken();
  const language = getLocalStorageI18Lang();
  const updatedHeader = {...headers, "Accept-Language": language};

  if (token && authTokenRequired) {
    updatedHeader.Authorization = `Bearer ${token}`;
  }

  if (accessToken) {
    updatedHeader.Authorization = `Bearer ${accessToken}`;
  }

  return axios({
    ...(isSomething(updatedHeader) && {headers: updatedHeader}),
    ...(isSomething(responseType) && {responseType: responseType}),
    method,
    url: `${process.env.REACT_APP_MACHINE_IP}${url}`,
    data,
  });
};

export const useClientMutation = ({
  payload,
  uiLoading,
  accessToken,
  authTokenRequired,
  onSuccessActions,
}: {
  payload: any;
  uiLoading: boolean;
  accessToken?: string;
  authTokenRequired?: boolean;
  onSuccessActions?: Function;
}) => {
  const dispatch = useDispatch();

  return useMutation(
    // data is now an object instead of formData only.
    // new keys that can be added: url and isDynamicUrl.
    // if isDynamicUrl is true, url has to be provided.
    // use this in case of variable value in url.
    // modification made to remove useQuery depedency in case of sending data and dynamic url.
    (data: any) => {
      const isDynamicUrl = R.pathOr(false, ["isDynamicUrl"], data);

      return axiosCall({
        ...payload,
        url: isDynamicUrl ? data.url : payload.url,
        data: isDynamicUrl ? data.data : data,
        accessToken,
        authTokenRequired,
      });
    },

    {
      onMutate: () => {
        if (uiLoading) {
          // dispatch here ofr loading
          dispatch(setLoadingState(true));
        }
      },
      onSuccess: () => {
        if (onSuccessActions) {
          onSuccessActions();
        }
      },
      onSettled: () => {
        if (uiLoading) {
          // dispatch here ofr loading
          dispatch(setLoadingState(false));
        }
      },
      onError: error => {
        const errorMessage = getParsedErrorMessage(error);
        if (isSomething(errorMessage)) {
          dispatch(
            setToaster({
              message: errorMessage,
              type: "error",
            }),
          );
        }
      },
    },
  );
};
