export const InfoFilledIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
  >
    <path
      d="M16 2.667C8.64 2.667 2.667 8.64 2.667 16S8.64 29.333 16 29.333 29.333 23.36 29.333 16 23.36 2.666 16 2.666zm1.333 20h-2.666v-8h2.666v8zm0-10.667h-2.666V9.333h2.666V12z"
      fill="#2988DF"
    />
  </svg>
);
