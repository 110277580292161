// lib
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {storageKey} from "constants/";
import * as R from "ramda";

// src
import {AuthState, User} from "store/auth/types";

const initialState: AuthState = {
  user: {
    email: "",
    name: "",
    otpDevices: [],
  },
  token: null,
  appType: (() => {
    const appTypeFromLocalStorage = localStorage.getItem(
      storageKey.LOCAL_STORAGE_APP_TYPE_KEY,
    );
    const appStr = R.includes(appTypeFromLocalStorage, ["admin", "client"])
      ? appTypeFromLocalStorage
      : "admin";
    return appStr;
  })(),
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser(state, {payload}: PayloadAction<User>) {
      state.user = payload;
    },
    setToken(state, {payload}: PayloadAction<string | null>) {
      state.token = payload;
    },
    setAppView(state, {payload}: PayloadAction<"admin" | "client">) {
      state.appType = payload;
    },
    resetAuth: () => {
      return initialState;
    },
  },
});

export const {setUser, setToken, setAppView, resetAuth} = authSlice.actions;

export default authSlice.reducer;
