interface IconType {
  height?: string;
  width?: string;
  background?: string;
  borderRadius?: string;
}

export const AdvancedPortfolioIcon = (props: IconType) => {
  const {
    height = "48",
    width = "48",
    background = "white",
    borderRadius = "0px",
  } = props;

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{borderRadius}}
    >
      <rect width={width} height={height} rx="8" fill={background} />
      <g clipPath="url(#clip0_50947_13363)">
        <path
          d="M19.3127 20.6875L13.7939 17.3813V11.3125L19.3127 8L24.8252 11.3125V17.3813L19.3127 20.6875Z"
          fill="#FCD773"
        />
        <path
          d="M30.3439 20.6875L24.8252 17.3813V11.3125L30.3439 8L35.8627 11.3125V17.3813L30.3439 20.6875Z"
          fill="#FCCC50"
        />
        <path
          d="M19.3127 40L13.7939 36.6875V30.6187L19.3127 27.3125L24.8252 30.6187V36.6875L19.3127 40Z"
          fill="#FCD773"
        />
        <path
          d="M13.7939 30.6188L8.8252 27.3126V20.6876L13.7939 17.3813L19.3127 20.6876V27.3126L13.7939 30.6188Z"
          fill="#FBBF21"
        />
        <path
          d="M24.825 30.6188L19.3125 27.3126V20.6876L24.825 17.3813L30.3438 20.6876V27.3126L24.825 30.6188Z"
          fill="#FDE197"
        />
        <path
          d="M37.8812 35.9501C36.1562 37.6751 33.3625 37.6751 31.6375 35.9501C29.9125 34.2251 29.9125 31.4313 31.6375 29.7063L34.7562 26.7563L37.875 29.7063C39.6062 31.4313 39.6062 34.2251 37.8812 35.9501Z"
          fill="#FDE197"
        />
      </g>
      <defs>
        <clipPath id="clip0_50947_13363">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(8 8)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BasicTaxInactiveIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_50947_13378)">
      <rect width="48" height="48" rx="8" fill="#FFF7E1" />
      <g clipPath="url(#clip1_50947_13378)">
        <path
          d="M24 15.2V21.8625H11.3312C10.4125 21.8625 9.58125 21.4875 8.975 20.8875C8.375 20.2812 8 19.45 8 18.5312C8 16.9375 9.11875 15.6062 10.6125 15.2812C10.8437 15.2312 11.0875 15.2 11.3312 15.2H24Z"
          fill="#BF6A30"
        />
        <path
          d="M22.1746 15.2001L23.4809 19.9001H13.6809C12.7621 19.9001 11.9309 19.5251 11.3246 18.9251C10.7246 18.3251 10.3496 17.4876 10.3496 16.5688C10.3496 16.1126 10.4434 15.6751 10.6121 15.2751C10.8434 15.2251 11.0871 15.1938 11.3309 15.1938H22.1746V15.2001Z"
          fill="#C87F4D"
        />
        <path
          d="M22.1748 29.4625V13.4875C22.1748 12.6688 22.5061 11.925 23.0436 11.3875C23.5811 10.85 24.3248 10.5188 25.1436 10.5188C26.7873 10.5188 28.1123 11.85 28.1123 13.4875V29.4625"
          fill="#FCCE54"
        />
        <path
          d="M26.1254 10.6812C25.7004 10.8312 25.3191 11.075 25.0066 11.3875C24.4691 11.925 24.1379 12.6687 24.1379 13.4875V27.8812H22.1816V13.4875C22.1816 12.6687 22.5129 11.925 23.0504 11.3875C23.5879 10.85 24.3316 10.5187 25.1504 10.5187C25.4879 10.5125 25.8129 10.575 26.1254 10.6812Z"
          fill="#FBBF21"
        />
        <path
          d="M28.1123 27.8813V11.9062C28.1123 11.0875 28.4436 10.3438 28.9811 9.80625C29.5186 9.26875 30.2623 8.9375 31.0811 8.9375C32.7248 8.9375 34.0498 10.2687 34.0498 11.9062V27.8813"
          fill="#FCCE54"
        />
        <path
          d="M32.0629 9.10625C31.6379 9.25625 31.2566 9.5 30.9441 9.8125C30.4066 10.35 30.0754 11.0937 30.0754 11.9125V26.3062H28.1191V11.9062C28.1191 11.0875 28.4504 10.3438 28.9879 9.80625C29.5254 9.26875 30.2691 8.9375 31.0879 8.9375C31.4316 8.9375 31.7566 8.99375 32.0629 9.10625Z"
          fill="#FBBF21"
        />
        <path
          d="M34.0566 26.9875V13.4875C34.0566 12.6688 34.3879 11.925 34.9254 11.3875C35.4629 10.85 36.2066 10.5188 37.0254 10.5188C38.6691 10.5188 39.9941 11.85 39.9941 13.4875V26.9875"
          fill="#FCCE54"
        />
        <path
          d="M38.0063 10.6812C37.5813 10.8312 37.2 11.075 36.8875 11.3875C36.35 11.925 36.0187 12.6687 36.0187 13.4875V27.8812H34.0625V13.4875C34.0625 12.6687 34.3937 11.925 34.9312 11.3875C35.4687 10.85 36.2125 10.5187 37.0312 10.5187C37.3688 10.5125 37.7 10.575 38.0063 10.6812Z"
          fill="#FBBF21"
        />
        <path
          d="M40 19.5187C40 20.5062 39.2 21.3 38.2188 21.3C37.2375 21.3 36.4375 20.5 36.4375 19.5187V17.7312C36.4375 16.7437 35.6375 15.95 34.6562 15.95C33.675 15.95 32.875 16.75 32.875 17.7312V21.45C32.875 22.4375 32.075 23.2312 31.0938 23.2312C30.1125 23.2312 29.3125 22.4312 29.3125 21.45V19.5312C29.3125 18.5437 28.5125 17.75 27.5312 17.75C26.55 17.75 25.75 18.55 25.75 19.5312C25.75 20.5125 24.95 21.3125 23.9688 21.3125C22.9875 21.3125 22.1875 20.5125 22.1875 19.5312V34.7875C22.1875 35.775 22.9875 36.5687 23.9688 36.5687C24.95 36.5687 25.75 35.7687 25.75 34.7875V32.7062C25.75 31.7187 26.55 30.925 27.5312 30.925C28.5125 30.925 29.3125 31.7249 29.3125 32.7062V37.3312C29.3125 38.3187 30.1125 39.1124 31.0938 39.1124C32.075 39.1124 32.875 38.3124 32.875 37.3312V30.9125C32.875 29.925 33.675 29.1312 34.6562 29.1312C35.6375 29.1312 36.4375 29.9312 36.4375 30.9125V32.7C36.4375 33.6875 37.2375 34.4812 38.2188 34.4812C39.2 34.4812 40 33.6812 40 32.7V22.9875"
          fill="#FDE094"
        />
        <path
          d="M37.7379 32.7001V30.9126C37.7379 29.9251 36.9379 29.1313 35.9566 29.1313C35.7254 29.1313 35.5066 29.1751 35.3066 29.2563C35.9691 29.5188 36.4379 30.1626 36.4379 30.9126V32.7001C36.4379 33.6876 37.2379 34.4813 38.2191 34.4813C38.4504 34.4813 38.6691 34.4376 38.8691 34.3563C38.2066 34.0938 37.7379 33.4501 37.7379 32.7001Z"
          fill="#FCD46A"
        />
        <path
          d="M30.6061 37.3376V32.7126C30.6061 31.7251 29.8061 30.9314 28.8248 30.9314C28.5936 30.9314 28.3748 30.9751 28.1748 31.0564C28.8373 31.3189 29.3061 31.9626 29.3061 32.7126V37.3376C29.3061 38.3251 30.1061 39.1189 31.0873 39.1189C31.3186 39.1189 31.5373 39.0751 31.7373 38.9939C31.0748 38.7376 30.6061 38.0939 30.6061 37.3376Z"
          fill="#FCD46A"
        />
        <path
          d="M23.4752 34.7938V21.2563C22.7189 21.0501 22.1689 20.3563 22.1689 19.5376V34.7938C22.1689 35.7813 22.9689 36.5751 23.9502 36.5751C24.1814 36.5751 24.4002 36.5313 24.6002 36.4501C23.9439 36.1876 23.4752 35.5438 23.4752 34.7938Z"
          fill="#FCD46A"
        />
      </g>
      <rect
        width="48"
        height="48"
        fill="black"
        style={{mixBlendMode: "saturation"}}
      />
    </g>
    <defs>
      <clipPath id="clip0_50947_13378">
        <rect width="48" height="48" rx="8" fill="white" />
      </clipPath>
      <clipPath id="clip1_50947_13378">
        <rect width="32" height="32" fill="white" transform="translate(8 8)" />
      </clipPath>
    </defs>
  </svg>
);

export const AdvancedTaxIcon = (props: IconType) => {
  const {
    height = "48",
    width = "48",
    background = "white",
    borderRadius = "0px",
  } = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 89 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{borderRadius}}
    >
      <rect x="0.333252" width="88" height="88" rx="8" fill={background} />
      <path
        d="M73.3333 21C73.3333 22.1 72.4333 23 71.3333 23H52.9958C52.8333 23.4625 52.6083 23.9 52.3333 24.3125C51.2208 25.9875 49.3458 27 47.3333 27H41.3333C38.0208 27 35.3333 24.3125 35.3333 21C35.3333 17.6875 38.0208 15 41.3333 15H47.3333C49.8708 15 52.1458 16.6 52.9958 19H71.3333C72.4333 19 73.3333 19.8875 73.3333 21Z"
        fill="#9F6A52"
      />
      <path
        d="M41.3333 15V21C41.3333 21.55 40.8833 22 40.3333 22C39.7833 22 39.3333 21.55 39.3333 21V15.3375C39.9708 15.1125 40.6583 15 41.3333 15Z"
        fill="#92563A"
      />
      <path
        d="M44.3333 22C43.7833 22 43.3333 21.55 43.3333 21V15H45.3333V21C45.3333 21.55 44.8833 22 44.3333 22Z"
        fill="#92563A"
      />
      <path
        d="M49.3333 15.3375V21C49.3333 21.55 48.8833 22 48.3333 22C47.7833 22 47.3333 21.55 47.3333 21V15C48.0208 15 48.6958 15.1125 49.3333 15.3375Z"
        fill="#92563A"
      />
      <path
        d="M63.3329 52.3875C63.3329 54.1125 63.0079 55.825 62.3829 57.425L56.5829 72.3625C56.4329 72.75 56.0579 73 55.6454 73H33.0204C32.6079 73 32.2329 72.75 32.0954 72.3625L27.2204 59.8375L26.2829 57.425C24.1454 51.925 25.7079 45.675 30.1954 41.8375L32.3329 40V35H56.3329V40L58.0829 41.5L58.4829 41.85C61.5579 44.475 63.3329 48.3375 63.3329 52.3875Z"
        fill="#FEE8AF"
      />
      <path
        d="M63.3325 52.3875C63.3325 54.1125 63.0075 55.825 62.3825 57.425L56.5825 72.3625C56.4325 72.75 56.0575 73 55.645 73H33.02C32.6075 73 32.2325 72.75 32.095 72.3625L27.22 59.8375C38.595 57.425 49.645 51.875 58.0825 41.5L58.4825 41.85C61.5575 44.475 63.3325 48.3375 63.3325 52.3875Z"
        fill="#FDE092"
      />
      <path
        d="M63.3322 52.3875C63.3322 54.1125 63.0072 55.825 62.3822 57.425L56.5822 72.3625C56.4322 72.75 56.0572 73 55.6447 73H33.0197C32.6072 73 32.2322 72.75 32.0947 72.3625L27.2197 59.8375L26.2822 57.425C25.2322 54.7375 25.0572 51.7875 25.7572 49H39.3322C40.9947 49 42.3322 47.6625 42.3322 46V27H41.3322C38.7947 27 36.5197 25.4 35.6697 23H52.9822C52.8197 23.4625 52.5947 23.9 52.3197 24.3125C51.2072 25.9875 49.3322 27 47.3197 27H46.3197V46C46.3197 47.6625 47.6572 49 49.3197 49H62.8947C63.1947 50.1125 63.3322 51.25 63.3322 52.3875Z"
        fill="#FCC840"
      />
      <path
        d="M63.3325 52.3875C63.3325 54.1125 63.0075 55.825 62.3825 57.425L56.5825 72.3625C56.4325 72.75 56.0575 73 55.645 73H33.02C32.6075 73 32.2325 72.75 32.095 72.3625L27.22 59.8375C35.47 58.0875 43.545 54.6875 50.57 49H62.92C63.195 50.1125 63.3325 51.25 63.3325 52.3875Z"
        fill="#FBBF21"
      />
      <path
        d="M54.8333 39H36.8333C36.0083 39 35.3333 39.675 35.3333 40.5C35.3333 41.325 36.0083 42 36.8333 42H54.8333C55.6583 42 56.3333 41.325 56.3333 40.5C56.3333 39.675 55.6583 39 54.8333 39Z"
        fill="#FDE092"
      />
      <path d="M42.3333 39H46.3333V42H42.3333V39Z" fill="#FDD775" />
      <path
        d="M30.3336 52.3875C30.3336 51.225 30.5586 50.075 31.0086 49H28.8836C28.8086 49.2375 28.7336 49.4875 28.6711 49.7375C28.1211 51.925 28.2586 54.2375 29.0836 56.35L31.0336 61.375L32.8961 60.65L30.9461 55.625C30.5336 54.5875 30.3336 53.5 30.3336 52.3875Z"
        fill="#FDD97A"
      />
      <path
        d="M33.4458 45.6375L34.4708 44.7625L33.1708 43.25L32.1458 44.125C31.9708 44.275 31.8083 44.425 31.6458 44.5875L33.0333 46.025C33.1583 45.8875 33.2958 45.7625 33.4458 45.6375Z"
        fill="#FEEEC5"
      />
      <path
        d="M31.1454 48.6749C31.4079 48.0874 31.7454 47.5375 32.1329 47.025L30.5329 45.825C30.0579 46.45 29.6579 47.1249 29.3204 47.8499C29.1454 48.2249 28.9954 48.6125 28.8704 49.0125H30.9954C31.0454 48.8875 31.0954 48.7874 31.1454 48.6749Z"
        fill="#FEEEC5"
      />
    </svg>
  );
};

export const BasicTaxIcon = (props: IconType) => {
  const {height = "48", width = "48"} = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 89 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.666626" width="88" height="88" rx="8" fill="#FFF7E1" />
      <g clipPath="url(#clip0_175_32502)">
        <path
          d="M44.6666 26.4V39.725H19.3291C17.4916 39.725 15.8291 38.975 14.6166 37.775C13.4166 36.5625 12.6666 34.9 12.6666 33.0625C12.6666 29.875 14.9041 27.2125 17.8916 26.5625C18.3541 26.4625 18.8416 26.4 19.3291 26.4H44.6666Z"
          fill="#BF6A30"
        />
        <path
          d="M41.0158 26.4002L43.6283 35.8002H24.0283C22.1908 35.8002 20.5283 35.0502 19.3158 33.8502C18.1158 32.6502 17.3658 30.9752 17.3658 29.1377C17.3658 28.2252 17.5533 27.3502 17.8908 26.5502C18.3533 26.4502 18.8408 26.3877 19.3283 26.3877H41.0158V26.4002Z"
          fill="#C87F4D"
        />
        <path
          d="M41.0162 54.9251V22.9751C41.0162 21.3376 41.6787 19.8501 42.7537 18.7751C43.8287 17.7001 45.3162 17.0376 46.9537 17.0376C50.2412 17.0376 52.8912 19.7001 52.8912 22.9751V54.9251"
          fill="#FCCE54"
        />
        <path
          d="M48.9174 17.3625C48.0674 17.6625 47.3049 18.15 46.6799 18.775C45.6049 19.85 44.9424 21.3375 44.9424 22.975V51.7625H41.0299V22.975C41.0299 21.3375 41.6924 19.85 42.7674 18.775C43.8424 17.7 45.3299 17.0375 46.9674 17.0375C47.6424 17.025 48.2924 17.15 48.9174 17.3625Z"
          fill="#FBBF21"
        />
        <path
          d="M52.8912 51.7625V19.8125C52.8912 18.175 53.5537 16.6875 54.6287 15.6125C55.7037 14.5375 57.1912 13.875 58.8287 13.875C62.1162 13.875 64.7662 16.5375 64.7662 19.8125V51.7625"
          fill="#FCCE54"
        />
        <path
          d="M60.7924 14.2125C59.9424 14.5125 59.1799 15 58.5549 15.625C57.4799 16.7 56.8174 18.1875 56.8174 19.825V48.6125H52.9049V19.8125C52.9049 18.175 53.5674 16.6875 54.6424 15.6125C55.7174 14.5375 57.2049 13.875 58.8424 13.875C59.5299 13.875 60.1799 13.9875 60.7924 14.2125Z"
          fill="#FBBF21"
        />
        <path
          d="M64.7799 49.9751V22.9751C64.7799 21.3376 65.4424 19.8501 66.5174 18.7751C67.5924 17.7001 69.0799 17.0376 70.7174 17.0376C74.0049 17.0376 76.6549 19.7001 76.6549 22.9751V49.9751"
          fill="#FCCE54"
        />
        <path
          d="M72.6791 17.3625C71.8291 17.6625 71.0666 18.15 70.4416 18.775C69.3666 19.85 68.7041 21.3375 68.7041 22.975V51.7625H64.7916V22.975C64.7916 21.3375 65.4541 19.85 66.5291 18.775C67.6041 17.7 69.0916 17.0375 70.7291 17.0375C71.4041 17.025 72.0666 17.15 72.6791 17.3625Z"
          fill="#FBBF21"
        />
        <path
          d="M76.6666 35.0375C76.6666 37.0125 75.0666 38.6 73.1041 38.6C71.1416 38.6 69.5416 37 69.5416 35.0375V31.4625C69.5416 29.4875 67.9416 27.9 65.9791 27.9C64.0166 27.9 62.4166 29.5 62.4166 31.4625V38.9C62.4166 40.875 60.8166 42.4625 58.8541 42.4625C56.8916 42.4625 55.2916 40.8625 55.2916 38.9V35.0625C55.2916 33.0875 53.6916 31.5 51.7291 31.5C49.7666 31.5 48.1666 33.1 48.1666 35.0625C48.1666 37.025 46.5666 38.625 44.6041 38.625C42.6416 38.625 41.0416 37.025 41.0416 35.0625V65.575C41.0416 67.55 42.6416 69.1375 44.6041 69.1375C46.5666 69.1375 48.1666 67.5375 48.1666 65.575V61.4125C48.1666 59.4375 49.7666 57.85 51.7291 57.85C53.6916 57.85 55.2916 59.45 55.2916 61.4125V70.6625C55.2916 72.6375 56.8916 74.225 58.8541 74.225C60.8166 74.225 62.4166 72.625 62.4166 70.6625V57.825C62.4166 55.85 64.0166 54.2625 65.9791 54.2625C67.9416 54.2625 69.5416 55.8625 69.5416 57.825V61.4C69.5416 63.375 71.1416 64.9625 73.1041 64.9625C75.0666 64.9625 76.6666 63.3625 76.6666 61.4V41.975"
          fill="#FDE094"
        />
        <path
          d="M72.1424 61.4002V57.8252C72.1424 55.8502 70.5424 54.2627 68.5799 54.2627C68.1174 54.2627 67.6799 54.3502 67.2799 54.5127C68.6049 55.0377 69.5424 56.3252 69.5424 57.8252V61.4002C69.5424 63.3752 71.1424 64.9627 73.1049 64.9627C73.5674 64.9627 74.0049 64.8752 74.4049 64.7127C73.0799 64.1877 72.1424 62.9002 72.1424 61.4002Z"
          fill="#FCD46A"
        />
        <path
          d="M57.8787 70.6753V61.4253C57.8787 59.4503 56.2787 57.8628 54.3162 57.8628C53.8537 57.8628 53.4162 57.9503 53.0162 58.1128C54.3412 58.6378 55.2787 59.9253 55.2787 61.4253V70.6753C55.2787 72.6503 56.8787 74.2378 58.8412 74.2378C59.3037 74.2378 59.7412 74.1503 60.1412 73.9878C58.8162 73.4753 57.8787 72.1878 57.8787 70.6753Z"
          fill="#FCD46A"
        />
        <path
          d="M43.617 65.5877V38.5127C42.1045 38.1002 41.0045 36.7127 41.0045 35.0752V65.5877C41.0045 67.5627 42.6045 69.1502 44.567 69.1502C45.0295 69.1502 45.467 69.0627 45.867 68.9002C44.5545 68.3752 43.617 67.0877 43.617 65.5877Z"
          fill="#FCD46A"
        />
      </g>
      <defs>
        <clipPath id="clip0_175_32502">
          <rect
            width="64"
            height="64"
            fill="white"
            transform="translate(12.6666 12)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const BasicPortfolioIcon = (props: IconType) => {
  const {height = "48", width = "48"} = props;
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 80 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" rx="8" fill="#FFF7E1" />
      <path
        d="M29.0908 35.4542L39.9999 41.8178L50.909 35.4542V22.7269L39.9999 16.3633L29.0908 22.7269V35.4542Z"
        fill="#FBBF21"
      />
      <path
        d="M38.1815 57.2726V44.5453L27.2724 38.1816L16.3633 44.5453V57.2726L27.2724 63.6362L38.1815 57.2726Z"
        fill="#FCD56F"
      />
      <path
        d="M63.6356 57.2726V44.5453L52.7265 38.1816L41.8174 44.5453V57.2726L52.7265 63.6362L63.6356 57.2726Z"
        fill="#FCD56F"
      />
    </svg>
  );
};

export const BasicPortfolioInactiveIcon = () => (
  <svg
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_51496_390)">
      <rect y="6.10352e-05" width="48" height="48" rx="8" fill="#FFF7E1" />
      <path
        d="M18 21.5001L24 25.0001L30 21.5001V14.5001L24 11.0001L18 14.5001V21.5001Z"
        fill="#FBBF21"
      />
      <path
        d="M23 33.5001V26.5001L17 23.0001L11 26.5001V33.5001L17 37.0001L23 33.5001Z"
        fill="#FCD56F"
      />
      <path
        d="M37 33.5001V26.5001L31 23.0001L25 26.5001V33.5001L31 37.0001L37 33.5001Z"
        fill="#FCD56F"
      />
      <rect
        y="6.10352e-05"
        width="48"
        height="48"
        fill="#F7F7F7"
        style={{mixBlendMode: "saturation"}}
      />
    </g>
    <defs>
      <clipPath id="clip0_51496_390">
        <rect y="6.10352e-05" width="48" height="48" rx="8" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
