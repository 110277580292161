export const NoDataStateIcon = () => (
  <svg
    width="64"
    height="41"
    viewBox="0 0 64 41"
    xmlns="http://www.w3.org/2000/svg"
    style={{height: "100%", margin: "auto"}}
  >
    <g transform="translate(0 1)" fill="none" fillRule="evenodd">
      <ellipse fill="#6c778030" cx="32" cy="33" rx="32" ry="7"></ellipse>
      <g fillRule="nonzero" stroke="#6c7780">
        <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
        <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z"></path>
      </g>
    </g>
  </svg>
);

export const CrossedEyeSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="15"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      d="M47.6417 22.3619C45.7095 18.1714 42.8793 14.457 39.3517 11.4819L45.4157 5.41794L42.5857 2.58594L35.9997 9.16594C32.3545 7.05738 28.2108 5.96413 23.9997 5.99994C8.99974 5.99994 2.11374 18.5219 0.357743 22.3619C0.122019 22.8765 0 23.4359 0 24.0019C0 24.568 0.122019 25.1273 0.357743 25.6419C2.29001 29.8325 5.12018 33.5469 8.64774 36.5219L2.58574 42.5859L5.41374 45.4139L11.9997 38.8339C15.645 40.9425 19.7887 42.0357 23.9997 41.9999C38.9997 41.9999 45.8857 29.4779 47.6417 25.6379C47.877 25.1239 47.9988 24.5652 47.9988 23.9999C47.9988 23.4346 47.877 22.876 47.6417 22.3619ZM3.99974 24.0219C5.49974 20.7319 11.3857 9.99994 23.9997 9.99994C27.1389 9.98159 30.237 10.7139 33.0357 12.1359L29.5057 15.6659C27.5856 14.3911 25.2836 13.8199 22.9902 14.0492C20.6969 14.2785 18.5535 15.2942 16.9237 16.9239C15.294 18.5537 14.2783 20.6971 14.049 22.9904C13.8197 25.2838 14.3909 27.5858 15.6657 29.5059L11.5077 33.6639C8.30849 31.0587 5.74149 27.7621 3.99974 24.0219ZM29.9997 23.9999C29.9997 25.5912 29.3676 27.1174 28.2424 28.2426C27.1172 29.3678 25.591 29.9999 23.9997 29.9999C23.1088 29.9965 22.2301 29.7914 21.4297 29.3999L29.3997 21.4299C29.7912 22.2303 29.9963 23.109 29.9997 23.9999ZM17.9997 23.9999C17.9997 22.4086 18.6319 20.8825 19.7571 19.7573C20.8823 18.6321 22.4084 17.9999 23.9997 17.9999C24.8907 18.0034 25.7694 18.2085 26.5697 18.5999L18.5997 26.5699C18.2083 25.7695 18.0032 24.8909 17.9997 23.9999ZM23.9997 37.9999C20.8606 38.0183 17.7625 37.2859 14.9637 35.8639L18.4937 32.3339C20.4139 33.6087 22.7159 34.1799 25.0093 33.9506C27.3026 33.7213 29.446 32.7057 31.0757 31.0759C32.7055 29.4462 33.7212 27.3028 33.9505 25.0095C34.1797 22.7161 33.6085 20.4141 32.3337 18.4939L36.4897 14.3379C39.6953 16.9456 42.2638 20.25 43.9997 23.9999C42.4717 27.3139 36.5837 37.9999 23.9997 37.9999Z"
      fill="currentColor"
    />
  </svg>
);

export const EyeSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="15"
    viewBox="0 0 48 48"
    fill="none"
  >
    <path
      d="M47.6417 22.362C45.8857 18.522 38.9997 6 23.9997 6C8.99974 6 2.11374 18.522 0.357743 22.362C0.122019 22.8766 0 23.436 0 24.002C0 24.568 0.122019 25.1274 0.357743 25.642C2.11374 29.478 8.99974 42 23.9997 42C38.9997 42 45.8857 29.478 47.6417 25.638C47.877 25.124 47.9988 24.5653 47.9988 24C47.9988 23.4347 47.877 22.876 47.6417 22.362ZM23.9997 38C11.3857 38 5.49974 27.268 3.99974 24.022C5.49974 20.732 11.3857 10 23.9997 10C36.5837 10 42.4717 20.686 43.9997 24C42.4717 27.314 36.5837 38 23.9997 38Z"
      fill="currentColor"
    />
    <path
      d="M24 14C22.0222 14 20.0888 14.5865 18.4443 15.6853C16.7998 16.7841 15.5181 18.3459 14.7612 20.1732C14.0043 22.0004 13.8063 24.0111 14.1922 25.9509C14.578 27.8907 15.5304 29.6725 16.9289 31.0711C18.3275 32.4696 20.1093 33.422 22.0491 33.8079C23.9889 34.1937 25.9996 33.9957 27.8268 33.2388C29.6541 32.4819 31.2159 31.2002 32.3147 29.5557C33.4135 27.9112 34 25.9778 34 24C33.9968 21.3488 32.9422 18.8071 31.0676 16.9324C29.1929 15.0578 26.6512 14.0032 24 14ZM24 30C22.8133 30 21.6533 29.6481 20.6666 28.9888C19.6799 28.3295 18.9109 27.3925 18.4567 26.2961C18.0026 25.1997 17.8838 23.9933 18.1153 22.8295C18.3468 21.6656 18.9182 20.5965 19.7574 19.7574C20.5965 18.9182 21.6656 18.3468 22.8295 18.1153C23.9933 17.8838 25.1997 18.0026 26.2961 18.4567C27.3925 18.9108 28.3295 19.6799 28.9888 20.6666C29.6481 21.6533 30 22.8133 30 24C30 25.5913 29.3679 27.1174 28.2426 28.2426C27.1174 29.3679 25.5913 30 24 30Z"
      fill="currentColor"
    />
  </svg>
);

export const InfoOutlinedSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
  >
    <g clipPath="url(#ep7j5sy0ja)">
      <path
        d="M6.166 8.5h1v-1h-1v1zm0-2h1v-3h-1v3zm.5 4.5c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5zm0-9c-2.205 0-4 1.795-4 4s1.795 4 4 4 4-1.795 4-4-1.795-4-4-4z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="ep7j5sy0ja">
        <path
          fill="#fff"
          transform="matrix(1 0 0 -1 .666 12)"
          d="M0 0h12v12H0z"
        />
      </clipPath>
    </defs>
  </svg>
);

export const ArrowRightSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clipPath="url(#eg6tykck1a)" className="redirect-btn">
      <path
        d="M9 3 7.942 4.058l4.185 4.192H3v1.5h9.127l-4.185 4.193L9 15l6-6-6-6z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="eg6tykck1a">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const NoTableDataIcon = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 130 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.2" filter="url(#filter0_d_3644_63643)">
      <circle cx="65" cy="64" r="47" fill="white" />
    </g>
    <path
      d="M78.2329 64.6294C84.0203 64.6294 88.7812 69.3458 88.7812 75.159C88.7812 80.9722 84.0203 85.6886 78.2329 85.6886C72.4455 85.6886 67.7501 80.9722 67.7501 75.159C67.7501 69.3458 72.4455 64.6294 78.2329 64.6294Z"
      fill="#2988DF"
    />
    <path
      d="M73.741 70.9656C73.2824 71.419 73.2824 72.1648 73.741 72.6182L76.4127 75.3018L73.741 77.9854C73.2824 78.4387 73.2824 79.1846 73.741 79.6379C74.1924 80.0986 74.9349 80.0986 75.3862 79.6379L78.0579 76.9543L80.7296 79.6379C81.1809 80.0986 81.9234 80.0986 82.3748 79.6379C82.8334 79.1846 82.8334 78.4387 82.3748 77.9854L79.7031 75.3018L82.3748 72.6182C82.8334 72.1648 82.8334 71.419 82.3748 70.9656C81.9234 70.5049 81.1809 70.5049 80.7296 70.9656L78.0579 73.6492L75.3862 70.9656C74.9349 70.5049 74.1924 70.5049 73.741 70.9656Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M60.4267 41.7232C61.0346 41.4423 61.6962 41.2969 62.3658 41.2969C63.0354 41.2969 63.697 41.4423 64.3049 41.7232L82.3424 50.0595C83.0438 50.3813 83.4894 51.0827 83.4894 51.7841C83.4894 52.6257 83.0438 53.3271 82.3424 53.6571L64.3049 61.991C63.0754 62.5604 61.6562 62.5604 60.4267 61.991L42.3916 53.6571C41.6909 53.3271 41.2422 52.6257 41.2422 51.7841C41.2422 51.0827 41.6908 50.3813 42.3916 50.0595L60.4267 41.7232ZM82.3424 60.6213C83.0438 60.9431 83.4894 61.6445 83.4894 62.3459C83.4894 62.3611 83.4892 62.3763 83.4889 62.3915C83.4887 62.4062 83.4882 62.4208 83.4877 62.4354C81.8467 61.7448 80.0472 61.363 78.1644 61.363C71.5454 61.363 66.0202 66.0815 64.751 72.3467L64.3049 72.5528C63.0754 73.1222 61.6562 73.1222 60.4267 72.5528L42.3916 64.2189C41.6909 63.8889 41.2422 63.1875 41.2422 62.3459C41.2422 61.6445 41.6908 60.9431 42.3916 60.6213L46.7814 58.5915L59.321 64.384C61.2518 65.2834 63.4797 65.2834 65.4106 64.384L77.9527 58.5915L82.3424 60.6213ZM66.4155 82.1395C65.2182 80.1355 64.5158 77.8001 64.4784 75.3038C62.7853 75.8228 60.9477 75.7035 59.321 74.9458L46.7814 69.1533L42.3916 71.1831C41.6908 71.5049 41.2422 72.2063 41.2422 72.9077C41.2422 73.7493 41.6909 74.4507 42.3916 74.7807L60.4267 83.1146C61.6562 83.684 63.0754 83.684 64.3049 83.1146L66.4155 82.1395Z"
      fill="#2988DF"
    />
    <defs>
      <filter
        id="filter0_d_3644_63643"
        x="0"
        y="0"
        width="130"
        height="130"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="9" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.160784 0 0 0 0 0.533333 0 0 0 0 0.87451 0 0 0 0.6 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_3644_63643"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_3644_63643"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const NoChartAvailableSvg = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 131 130"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity=".2" filter="url(#0q3w7ofjna)">
      <circle cx="65.5" cy="64" r="47" fill="#fff" />
    </g>
    <g clip-path="url(#wtdo9avqvb)">
      <path
        d="M78.5 46h-28c-2.2 0-4 1.8-4 4v28c0 2.2 1.8 4 4 4h28c2.2 0 4-1.8 4-4V50c0-2.2-1.8-4-4-4zm-20 28h-4V60h4v14zm8 0h-4V54h4v20zm8 0h-4v-8h4v8z"
        fill="#2988DF"
      />
    </g>
    <defs>
      <clipPath id="wtdo9avqvb">
        <path fill="#fff" transform="translate(40.5 40)" d="M0 0h48v48H0z" />
      </clipPath>
      <filter
        id="0q3w7ofjna"
        x=".5"
        y="0"
        width="130"
        height="130"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="9" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.160784 0 0 0 0 0.533333 0 0 0 0 0.87451 0 0 0 0.6 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_50792_105131"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_50792_105131"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const TaxReportNotAvailableSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="137"
    height="137"
    viewBox="0 0 130 131"
    fill="none"
  >
    <g opacity=".2" filter="url(#eqoshnkd0a)">
      <circle cx="65" cy="64.5" r="47" fill="#fff" />
    </g>
    <g clip-path="url(#trkea876cb)">
      <path
        d="M50 63.5v8c0 1.66 1.34 3 3 3s3-1.34 3-3v-8c0-1.66-1.34-3-3-3s-3 1.34-3 3zm12 0v8c0 1.66 1.34 3 3 3s3-1.34 3-3v-8c0-1.66-1.34-3-3-3s-3 1.34-3 3zm-13 21h32c1.66 0 3-1.34 3-3s-1.34-3-3-3H49c-1.66 0-3 1.34-3 3s1.34 3 3 3zm25-21v8c0 1.66 1.34 3 3 3s3-1.34 3-3v-8c0-1.66-1.34-3-3-3s-3 1.34-3 3zM63.14 43.48l-15.8 8.32c-.82.42-1.34 1.28-1.34 2.2a2.5 2.5 0 0 0 2.5 2.5h33.02c1.36 0 2.48-1.12 2.48-2.5 0-.92-.52-1.78-1.34-2.2l-15.8-8.32a3.953 3.953 0 0 0-3.72 0z"
        fill="#2988DF"
      />
    </g>
    <defs>
      <clipPath id="trkea876cb">
        <path fill="#fff" transform="translate(41 40.5)" d="M0 0h48v48H0z" />
      </clipPath>
      <filter
        id="eqoshnkd0a"
        x="0"
        y=".5"
        width="130"
        height="130"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="9" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix values="0 0 0 0 0.160784 0 0 0 0 0.533333 0 0 0 0 0.87451 0 0 0 0.6 0" />
        <feBlend
          in2="BackgroundImageFix"
          result="effect1_dropShadow_49_18095"
        />
        <feBlend
          in="SourceGraphic"
          in2="effect1_dropShadow_49_18095"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);

export const PayWallDefaultSvg = () => (
  <svg
    width="97"
    height="96"
    viewBox="0 0 97 96"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="96" height="96" rx="8" fill="#FFF7E1" />
    <path
      d="M36.4895 39.5117L22.3477 31.0395V15.4883L36.4895 7L50.6152 15.4883V31.0395L36.4895 39.5117Z"
      fill="#FCD773"
    />
    <path
      d="M64.757 39.5117L50.6152 31.0395V15.4883L64.757 7L78.8988 15.4883V31.0395L64.757 39.5117Z"
      fill="#FCCC50"
    />
    <path
      d="M36.4895 89L22.3477 80.5117V64.9605L36.4895 56.4883L50.6152 64.9605V80.5117L36.4895 89Z"
      fill="#FCD773"
    />
    <path
      d="M22.3477 64.9606L9.61523 56.4884V39.5118L22.3477 31.0396L36.4895 39.5118V56.4884L22.3477 64.9606Z"
      fill="#FBBF21"
    />
    <path
      d="M50.6141 64.9606L36.4883 56.4884V39.5118L50.6141 31.0396L64.7559 39.5118V56.4884L50.6141 64.9606Z"
      fill="#FDE197"
    />
    <path
      d="M84.0707 78.622C79.6504 83.0423 72.4914 83.0423 68.0711 78.622C63.6508 74.2017 63.6508 67.0427 68.0711 62.6224L76.0629 55.063L84.0547 62.6224C88.491 67.0427 88.491 74.2017 84.0707 78.622Z"
      fill="#FDE197"
    />
  </svg>
);

export const MissingPurchaseHistoryModalBeeSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="124"
    height="124"
    viewBox="0 0 124 124"
    fill="none"
  >
    <path
      d="M56.271 73.98c2.518-4.542-3.587-12.74-13.636-18.31-10.05-5.57-20.236-6.402-22.754-1.86-2.518 4.542 3.587 12.74 13.636 18.31 10.05 5.57 20.236 6.403 22.754 1.86z"
      fill="#B9D3F9"
    />
    <path
      d="M49.275 77.235c-.508 0-1.049-.034-1.623-.067-4.328-.339-9.434-2.03-14.336-4.768-4.903-2.705-9.028-6.12-11.631-9.636-2.671-3.55-3.415-6.796-2.13-9.129 1.285-2.333 4.43-3.381 8.858-3.043 4.328.338 9.434 2.029 14.336 4.767 4.903 2.705 9.028 6.12 11.631 9.636 2.671 3.55 3.415 6.796 2.13 9.13-1.116 2.028-3.652 3.11-7.235 3.11zM26.824 51.167c-3.212 0-5.646.913-6.695 2.773-1.15 2.096-.405 5.071 2.097 8.418 2.536 3.415 6.593 6.763 11.428 9.434 4.835 2.67 9.805 4.328 14.065 4.666 4.159.338 7.1-.609 8.25-2.705 1.15-2.097.406-5.072-2.096-8.419-2.536-3.415-6.593-6.762-11.428-9.433-5.647-3.111-11.327-4.734-15.621-4.734z"
      fill="#B9D3F9"
    />
    <path
      d="M56.24 74.293c-.068 0-.136-.034-.203-.067-.17-.135-16.196-12.78-30.16-17.311-.169-.068-.27-.237-.203-.406.068-.169.237-.27.406-.203 14.1 4.598 30.227 17.311 30.362 17.447a.32.32 0 0 1 .068.44c-.101.067-.17.1-.27.1z"
      fill="#B9D3F9"
    />
    <path
      d="M42.048 81.671c11.186-2.618 19.294-8.84 18.11-13.896-1.183-5.056-11.21-7.032-22.396-4.414-11.186 2.619-19.294 8.84-18.11 13.896 1.183 5.057 11.21 7.033 22.396 4.414z"
      fill="#B9D3F9"
    />
    <path
      d="M32.538 83.118c-1.96 0-3.787-.169-5.477-.507-4.362-.845-7.1-2.739-7.709-5.342-.609-2.57 1.014-5.478 4.53-8.216 3.45-2.671 8.352-4.835 13.83-6.086 5.443-1.285 10.819-1.522 15.079-.676 4.361.845 7.1 2.738 7.709 5.342.608 2.57-1.015 5.477-4.53 8.216-3.45 2.67-8.352 4.835-13.83 6.086-3.313.777-6.593 1.183-9.602 1.183zm5.308-19.509c-10.988 2.57-19.001 8.656-17.852 13.525 1.15 4.868 11.023 6.762 22.011 4.158 5.376-1.25 10.177-3.38 13.558-5.984 3.314-2.536 4.835-5.207 4.294-7.54-.54-2.333-3.11-4.023-7.201-4.835-4.193-.811-9.434-.575-14.81.676z"
      fill="#B9D3F9"
    />
    <path
      d="M25.032 76.66c-.135 0-.237-.067-.304-.203-.068-.169 0-.372.169-.405 18.731-7.54 35.062-8.622 35.23-8.656.17 0 .339.135.339.304 0 .17-.135.338-.304.338-.17 0-16.398 1.116-35.028 8.588 0 .034-.068.034-.102.034z"
      fill="#B9D3F9"
    />
    <path
      d="M38.763 97.142c-1.944.172-3.154-4.257-2.912-6.204 1.445-6.316 5.938 5.394 2.912 6.204z"
      fill="#332E25"
    />
    <path
      d="M47.935 65.803c2.313-.528 3.113 2.957.815 3.513-10.063 2.56-14.108 11.338-10.14 21.15.59 2.015-2.42 2.513-2.759.472-4.567-11.367.05-21.634 12.084-25.135z"
      fill="#332E25"
    />
    <path
      d="M47.67 69.614c-9.51 3.083-12.72 11.323-9.095 20.72l.16.288c.321.578.574 1.307.785 2.096.538 2.195.238 4.174-.728 4.408a.818.818 0 0 1-.324.028c.598-1.918.176-3.837-.294-5.162a10.883 10.883 0 0 0-.798-1.71c-.135-.38-.27-.758-.375-1.153-2.68-9.052.6-14.989 3.769-16.972 3.254-2.032 6.738-2.492 6.9-2.543z"
      fill="#3F3A31"
    />
    <path
      d="M38.322 90.626c-1.993-5.09-2.047-9.82-.129-13.716 1.796-3.64 5.202-6.285 9.903-7.684a.304.304 0 1 1 .173.584c-4.54 1.348-7.818 3.883-9.518 7.357-1.822 3.73-1.79 8.283.152 13.212.05.16-.03.356-.19.407a.322.322 0 0 1-.391-.16c-.016-.03-.016-.03 0 0z"
      fill="#2D1D1D"
    />
    <path
      d="M35.527 91.307c-2.37-5.976-2.418-11.577-.147-16.2 2.217-4.515 6.496-7.833 12.44-9.58a.304.304 0 1 1 .172.584C42.24 67.79 38.088 71 35.94 75.365c-2.188 4.5-2.134 9.91.169 15.696.051.16-.03.356-.19.407a.356.356 0 0 1-.391-.161z"
      fill="#2D1D1D"
    />
    <path
      d="M35.746 93.678a9.21 9.21 0 0 1-.255-2.503c.023-.163.164-.317.328-.295.163.023.317.164.295.328-.058.712.05 1.52.233 2.326.464 1.858 1.345 3.107 1.99 3.316.09.025.208.035.31.016.659-.176 1.029-1.892.507-4.058-.262-.95-.688-1.922-.832-2.182-.011-.227-.09-.367.054-.447.174-.096.405-.05.485.093.16.29.657 1.352.922 2.375.608 2.458.192 4.502-.963 4.803a1.178 1.178 0 0 1-.667-.046c-.555-.183-1.113-.78-1.577-1.618a9.147 9.147 0 0 1-.83-2.108z"
      fill="#2D1D1D"
    />
    <path
      d="M104.67 72.819c.566-5.16-8.229-10.358-19.644-11.61-11.414-1.251-21.126 1.917-21.692 7.077-.566 5.16 8.229 10.358 19.643 11.61 11.415 1.251 21.127-1.917 21.693-7.077z"
      fill="#B9D3F9"
    />
    <path
      d="M87.954 80.515c-1.623 0-3.28-.102-4.97-.27-5.579-.61-10.684-2.165-14.437-4.396-3.82-2.266-5.782-4.97-5.478-7.608.304-2.637 2.773-4.835 6.999-6.22 4.159-1.353 9.5-1.76 15.046-1.15 5.579.608 10.684 2.163 14.437 4.395 3.821 2.265 5.782 4.97 5.478 7.607-.305 2.637-2.773 4.835-7 6.222-2.907.946-6.356 1.42-10.075 1.42zM80.11 61.276c-3.651 0-7.033.474-9.873 1.387-3.956 1.284-6.289 3.313-6.56 5.68-.27 2.367 1.59 4.835 5.174 6.965 3.685 2.164 8.723 3.72 14.2 4.294 5.478.609 10.719.203 14.776-1.116 3.956-1.285 6.289-3.313 6.559-5.68.271-2.367-1.589-4.835-5.173-6.965-3.685-2.164-8.723-3.72-14.2-4.294-1.657-.203-3.314-.27-4.903-.27z"
      fill="#B9D3F9"
    />
    <path
      d="M94.412 72.265h-.102c-.169-.034-16.33-4.937-30.801-4.362-.17.034-.338-.135-.338-.304 0-.169.135-.338.304-.338 14.572-.575 30.87 4.328 31.038 4.395.17.068.27.237.203.406-.033.135-.169.203-.304.203z"
      fill="#B9D3F9"
    />
    <path
      d="M90.34 69.458c9.301-6.729 14.376-15.593 11.334-19.797-3.042-4.204-13.048-2.158-22.35 4.572-9.301 6.729-14.376 15.592-11.334 19.797 3.042 4.204 13.048 2.158 22.35-4.572z"
      fill="#B9D3F9"
    />
    <path
      d="M73.483 76.559c-2.637 0-4.598-.778-5.714-2.333-1.555-2.13-1.183-5.444 1.014-9.332 2.164-3.787 5.85-7.675 10.38-10.955 4.531-3.28 9.4-5.578 13.66-6.457 4.362-.88 7.607-.203 9.163 1.96 1.555 2.13 1.183 5.444-1.015 9.332-2.164 3.787-5.849 7.675-10.38 10.955-4.53 3.28-9.399 5.579-13.66 6.458-1.25.237-2.4.372-3.448.372zm22.755-28.807c-4.463 0-10.718 2.4-16.703 6.729-9.129 6.627-14.2 15.316-11.259 19.373 2.942 4.058 12.78 1.961 21.91-4.632 9.129-6.627 14.2-15.316 11.259-19.373-1.015-1.42-2.874-2.097-5.207-2.097z"
      fill="#B9D3F9"
    />
    <path
      d="M68.006 74.361c-.068 0-.135-.034-.203-.067-.135-.102-.135-.338-.034-.474 10.447-12.171 25.02-19.508 25.19-19.576.168-.068.371 0 .439.135.067.17 0 .372-.135.44-.136.067-14.607 7.337-24.987 19.407a.308.308 0 0 1-.27.135z"
      fill="#B9D3F9"
    />
    <path
      d="M92.69 54.106C89.667 62.37 81.112 68.19 72.262 66.66c-2.325-.516-3.243 3.137-.692 3.654 10.377 1.572 20.438-5.402 23.698-15.177.647-1.674-1.886-2.69-2.578-1.032z"
      fill="#332E25"
    />
    <path
      d="M91.799 56.05c-1.09 2.155-2.464 4.11-4.17 5.777a21.1 21.1 0 0 1-1.08.944c-3.925 3.284-9.287 4.636-14.287 3.89-.62-.11-1.12.022-1.5.322l2.167 1.572s5.287.643 10.92-2.386c5.586-3.044 8.1-10.042 8.1-10.042l-.15-.077z"
      fill="#3F3A31"
    />
    <path
      d="M75.574 70.797c-1.498.096-2.798.056-4.021-.134-.83-.157-1.432-.615-1.684-1.358-.267-.773-.11-1.68.424-2.28.504-.586 1.216-.823 2.047-.666 5 .747 10.257-.667 14.03-3.8.349-.285.745-.63 1.05-.93 1.75-1.727 3.233-3.848 4.43-6.32a.32.32 0 0 1 .424-.132.32.32 0 0 1 .133.424c-1.228 2.562-2.756 4.743-4.568 6.5-.335.314-.73.66-1.08.944-3.94 3.253-9.349 4.741-14.56 3.948-.589-.125-1.104.052-1.44.443-.35.436-.491 1.07-.299 1.616.163.485.6.836 1.173.93 1.133.16 2.403.214 3.87.134 4.478-.24 8.799-1.872 12.145-4.57 2.327-1.858 4.295-4.293 5.874-7.291a.32.32 0 0 1 .424-.133.32.32 0 0 1 .133.424c-1.61 3.088-3.624 5.584-6.027 7.517a20.532 20.532 0 0 1-3.873 2.468c-2.64 1.296-5.61 2.076-8.605 2.266z"
      fill="#2D1D1D"
    />
    <path
      d="M96.242 59.39s-6.17-40.656-7.025-40.532c-.771.113 4.853 40.86 4.853 40.86.102.63.668 1.06 1.274.97.629-.102 1.06-.668.898-1.297z"
      fill="#4150D8"
    />
    <path
      d="M95.33 60.961a1.28 1.28 0 0 1-1.018-.268 1.345 1.345 0 0 1-.515-.929c-.012-.083-1.42-10.29-2.712-20.379-.751-5.922-1.334-10.657-1.7-14.051-.724-6.646-.534-6.68-.202-6.725.333-.045.534-.056 1.744 6.498.616 3.36 1.436 8.038 2.39 13.95 1.647 10.032 3.187 20.205 3.21 20.312.056.356-.03.723-.233 1.03-.144.189-.311.33-.501.423-.119.059-.25.093-.463.139zm-1-1.252a.959.959 0 0 0 .34.572c.19.143.414.18.64.158a.83.83 0 0 0 .571-.34.936.936 0 0 0 .147-.662c-2.129-13.96-5.362-34.26-6.583-39.273.295 5.172 2.97 25.539 4.886 39.545z"
      fill="#31409B"
    />
    <path
      d="M92.96 58.568s.534-2.672.75-3.306c.216-.633.778-3.094-.284-2.572-1.032.507-1.243 2.531-1.243 2.531l.777 3.347zM97.011 54.545c.052 1.256-.572 1.75-.783 1.703-.212-.047-.873-.55-1.108-1.565-.235-1.015-.418-2.846.491-3.066.818-.176 1.349 1.673 1.4 2.928z"
      fill="#332E25"
    />
    <path
      d="M93.056 59.915c-.166-.032-.3-.153-.284-.35.033-.392.329-3.702.56-4.38.278-.74.423-1.979.273-2.207l-.09.045c-.73.358-1.12 2.163-1.2 2.768 0 0-.228.156-.394.124a.32.32 0 0 1-.255-.364c.048-.287.427-2.583 1.58-3.15.425-.208.696-.04.817.052.69.64-.037 2.918-.067 2.933-.124.438-.413 2.764-.529 4.215-.016.121-.168.271-.168.271-.06.03-.106.014-.243.043zM96.18 56.535c-.345-.118-1.114-.658-1.377-1.81-.118-.469-.454-2.15.082-2.977a.915.915 0 0 1 .638-.465.908.908 0 0 1 .77.187c.736.58 1.073 2.186 1.082 3.048.024.816-.208 1.495-.634 1.855-.076.075-.136.105-.197.135a.468.468 0 0 1-.363.027zm-.709-4.435c-.305.451-.238 1.586-.017 2.494.175.818.671 1.215.837 1.322.137-.105.427-.511.418-1.373-.038-.998-.407-2.21-.828-2.531-.12-.092-.196-.092-.227-.077l-.06.03c-.03.014-.077.075-.122.135z"
      fill="#2D1D1D"
    />
    <path
      d="M61.389 118.924c20.852 0 37.755-1.272 37.755-2.84 0-1.569-16.903-2.841-37.755-2.841-20.852 0-37.756 1.272-37.756 2.841 0 1.568 16.904 2.84 37.756 2.84z"
      fill="#C7D1EA"
    />
    <path
      d="M46.174 114.393c.744 2.57 8.453 1.386 8.42-.778-.71-2.637-8.657-1.386-8.42.778z"
      fill="#332E25"
    />
    <path
      d="M50.13 109.085c.338 1.826 2.367 8.486 4.43 4.53-.71-2.738-2.097-5.477-1.489-8.418.339-2.435 1.556-4.835 3.348-6.458 1.826-1.59-.575-4.396-2.4-2.807-3.45 3.314-4.937 8.487-3.889 13.153z"
      fill="#332E25"
    />
    <path
      d="M46.174 114.393c.034.304.237.575.575.778.203-1.42 3.11-1.961 4.7-2.164.642-.068 1.082-.102 1.082-.102s-.136-.27-.305-.71c-.54-1.352-1.724-4.361-1.521-6.424.507-4.868 2.502-6.052 2.502-6.052l-.778-1.961c-2.299 3.212-3.144 7.439-2.299 11.327.203 1.048.541 2.062.947 3.043-.27 0-.575 0-.88.034-2.332.236-4.124 1.217-4.023 2.231z"
      fill="#3F3A31"
    />
    <path
      d="M56.825 95.932c-.71-.81-1.995-1.149-3.077-.236-3.483 3.38-5.038 8.655-3.99 13.456.175.876.454 1.776.787 2.681-.125.004-.253.01-.38.024-2.604.271-4.43 1.353-4.329 2.57.102 1.048 1.658 1.796 3.753 1.724 1.982-.067 3.202-.424 4.024-.845 1.869-.957 1.278-2.021 1.081-2.637-.417-1.312-.98-2.502-1.217-3.719a11.46 11.46 0 0 1-.101-3.72c.338-2.366 1.555-4.699 3.245-6.255 1.049-.912.914-2.231.203-3.043zm-.643 2.536a11.19 11.19 0 0 0-3.449 6.661c-.169 1.285-.148 2.64.136 3.956.338 1.569 1.392 3.81 1.386 4.564-.003.483-.338.744-.947 1.082-.743.406-1.724.676-2.806.778-1.082.101-2.13 0-2.908-.237-.676-.203-1.081-.541-1.115-.879-.034-.338.338-.744.946-1.082.744-.405 1.725-.676 2.807-.777.27-.034.54-.034.811-.034a.339.339 0 0 0 .338-.338c0-.093-.782-2.217-.98-3.179-1.015-4.598.473-9.636 3.786-12.848.778-.642 1.623-.371 2.13.203.508.575.643 1.454-.135 2.13z"
      fill="#2D1D1D"
    />
    <path
      d="M44.236 82.645c1.522 13.93 16.568 27.725 30.768 22.281.508-.203 1.015-.406 1.522-.676a14.52 14.52 0 0 0 5.004-3.922 13.085 13.085 0 0 0 2.231-3.889c2.536-6.897.204-15.214-4.395-20.76-1.488-1.758-4.903-5.984-7.574-9.365-1.86-2.333-3.38-4.26-3.617-4.632l-21.842-.609c-.237.237-.71 2.13-1.15 4.768-.98 5.274-1.893 13.457-.947 16.804z"
      fill="#F9C710"
    />
    <path
      d="M44.27 83.186c1.826 13.76 16.703 27.15 30.7 21.774-9.297 1.352-19.813-4.429-24.445-14.606-4.666-10.245-1.893-22.18-1.893-22.18l-3.516-2.333c-.88 5.071-1.759 12.78-1.015 16.364.068.305.136.643.17.98z"
      fill="#F8A01F"
    />
    <path
      d="M81.53 100.328a13.085 13.085 0 0 0 2.231-3.889c2.536-6.897.204-15.214-4.395-20.76-1.488-1.758-4.903-5.984-7.574-9.365L68.073 67.6S75.782 74.53 80.38 83.59c4.463 8.825 1.32 16.365 1.15 16.737z"
      fill="#FFD95F"
    />
    <path
      d="M44 73.719c19.272 2.84 29.753-4.903 29.753-4.903-3.415-4.26-5.612-7.168-5.612-7.168l-17.48-.507-4.396-.135s-.744 2.3-1.386 5.782c-.338 2.028-.676 4.395-.88 6.93z"
      fill="#332E25"
    />
    <path
      d="M68.513 106.448c-3.415 0-6.931-.947-10.312-2.807-7.71-4.294-13.457-12.712-14.302-20.962-.812-8.352.71-15.993 2.096-21.707a.325.325 0 0 1 .304-.236l21.842.608c.102 0 .203.068.27.135.61.88 8.623 10.955 11.192 13.998 5.038 6.153 6.83 14.606 4.43 21.098-1.252 3.415-3.957 6.322-7.405 7.979-2.57 1.251-5.309 1.894-8.115 1.894zM44.54 82.611c.845 8.047 6.458 16.263 13.998 20.456 5.95 3.313 12.273 3.617 17.852.912 3.313-1.589 5.883-4.361 7.066-7.64 2.671-7.236-.236-15.486-4.328-20.457-2.468-2.907-10.008-12.408-11.123-13.896l-21.436-.608c-1.387 5.646-2.807 13.085-2.029 21.233z"
      fill="#2D1D1D"
    />
    <path
      d="M64.659 101.579c-.068.372.371.777 1.014.913.642.135 1.217-.068 1.319-.44.067-.372-.373-.778-1.015-.913-.676-.101-1.25.068-1.319.44zM70.393 103.329c.131-.35-.258-.82-.87-1.05-.612-.23-1.214-.133-1.346.217-.131.349.258.819.87 1.049.612.23 1.214.133 1.346-.216zM66.315 103.337c-.101.271.17.609.642.778.44.169.88.101.981-.169.101-.271-.17-.609-.642-.778-.474-.135-.913-.068-.98.169z"
      fill="#F4B503"
    />
    <path
      d="M65.977 106.008c.812.102 1.657.169 2.502.169 2.164 0 4.362-.405 6.56-1.251.135.068.304.102.439.135 2.942 1.015 5.545 1.454 7.506 1.623 2.536.237 4.024 0 4.024 0-2.942-.946-5.376-3.922-6.12-4.97-.17-.236-.27-.372-.27-.372 1.96-1.96 3.177-4.361 3.752-6.931-.067.135-3.55 8.622-18.393 11.597z"
      fill="#332E25"
    />
    <path
      d="M80.854 101.714c0 .068.135 3.28 2.096 4.97 2.536.237 4.024 0 4.024 0-2.908-.946-5.343-3.922-6.12-4.97z"
      fill="#3F3A31"
    />
    <path
      d="M75.038 105.264c-2.907 1.116-5.95 1.318-9.061.878-.17-.034-.338.001-.304-.168 0-.169.135-.27.27-.304 14.607-2.908 18.09-11.293 18.123-11.394.067-.17.237-.237.406-.203.169.034.05.216.015.385-.608 2.705-1.605 4.991-3.43 6.884.608.846 3.076 4.058 6.086 5.004.135.034.236.17.236.339 0 .135-.135.27-.27.304-.135.033-5.8.393-12.07-1.725zm10.583 1.15c-2.908-1.522-5.004-4.531-5.24-4.869a.318.318 0 0 1 .033-.439 13.89 13.89 0 0 0 2.806-4.16c-1.994 2.638-6.322 6.695-15.045 8.893 2.299.034 4.598-.372 6.762-1.217.25-.115 3.733 1.792 10.684 1.792z"
      fill="#2D1D1D"
    />
    <path
      d="M44.236 82.645c.204 1.555.542 3.11.981 4.632.372 1.217.845 2.4 1.42 3.584 0 0 19.002-3.246 30.329-12.916a30.599 30.599 0 0 0 2.4-2.265c-.642-.778-1.25-1.522-1.86-2.266 0 0-.879.676-2.603 1.69-4.598 2.638-15.113 7.473-30.667 7.54z"
      fill="#332E25"
    />
    <path
      d="M74.87 75.105s1.25 1.589 2.096 2.84a30.599 30.599 0 0 0 2.4-2.265c-.642-.778-1.25-1.522-1.86-2.266 0 0-.912.677-2.637 1.691z"
      fill="#3F3A31"
    />
    <path
      d="M46.705 91.267c-.135 0-.237-.068-.304-.203-.474-1.048-1.15-2.57-1.522-3.72a28.48 28.48 0 0 1-.98-4.665c0-.102 0-.204.067-.271a.307.307 0 0 1 .237-.102c21.098-.067 32.965-9.06 33.067-9.162.135-.102.206-.062.308.074.608.744 1.284 1.53 1.927 2.308.101.136.165.221.064.357-10.752 11.394-32.628 15.35-32.83 15.384h-.034zm-2.096-8.318c.203 1.488.54 2.908.912 4.226.304 1.049.913 2.401 1.387 3.415 2.434-.473 21.977-4.497 32.052-14.91-.507-.609-1.014-1.218-1.487-1.792-1.86 1.285-13.49 8.926-32.864 9.061zM52.284 74.665c-2.57 0-5.343-.169-8.318-.608-.169-.034 0-.237.034-.406.034-.17-.101-.27.068-.237 17.21 2.536 27.454-3.482 29.246-4.666a384.328 384.328 0 0 1-5.308-6.762l-21.707-.608c-.169 0-.304-.17-.304-.339s.17-.338.338-.304l21.842.609c.102 0 .203.034.237.135.034.034 2.231 2.942 5.612 7.134.068.068.068.17.068.237a.228.228 0 0 1-.135.203c-.068.101-7.743 5.612-21.673 5.612z"
      fill="#2D1D1D"
    />
    <path
      d="M46.846 22.439a53.475 53.475 0 0 0-.44-3.922 49.365 49.365 0 0 0-.744-3.889 49.185 49.185 0 0 0-1.082-3.82 28.462 28.462 0 0 0-1.487-3.652.459.459 0 0 1 .236-.608c.237-.102.474 0 .609.203a36.872 36.872 0 0 1 1.758 3.685c.507 1.251.947 2.536 1.353 3.854a84.66 84.66 0 0 1 1.014 3.956c.27 1.353.507 2.672.71 4.058a.945.945 0 0 1-.812 1.048.945.945 0 0 1-1.048-.812c-.067-.034-.067-.034-.067-.101zM65.705 22.29c.185-1.39.412-2.718.705-4.056a52.478 52.478 0 0 1 1.025-3.963c.366-1.313.84-2.573 1.35-3.838.508-1.264 1.127-2.475 1.779-3.691a.479.479 0 0 1 .622-.188c.22.106.29.37.184.589-.552 1.202-1.07 2.4-1.511 3.655a48.852 48.852 0 0 0-1.082 3.8 44.494 44.494 0 0 0-.761 3.893 52.797 52.797 0 0 0-.452 3.919l.005.033a.916.916 0 0 1-.975.85.916.916 0 0 1-.85-.974c-.068.01-.072-.024-.039-.029z"
      fill="#332E25"
    />
    <path
      d="M47.84 23.687c-.276 0-.543-.092-.768-.267a1.268 1.268 0 0 1-.456-.695.386.386 0 0 1-.097-.272 52.94 52.94 0 0 0-.436-3.887 49.133 49.133 0 0 0-.739-3.863 49.01 49.01 0 0 0-1.075-3.794 27.911 27.911 0 0 0-1.47-3.61.802.802 0 0 1-.02-.615.774.774 0 0 1 .421-.437c.37-.158.775-.03 1.01.322l.015.026a37.101 37.101 0 0 1 1.774 3.718 47.43 47.43 0 0 1 1.362 3.881 86.025 86.025 0 0 1 1.02 3.978c.299 1.493.525 2.788.715 4.084.045.337-.046.668-.255.937a1.266 1.266 0 0 1-1.001.494zm-.674-1.346a.323.323 0 0 1 .071.156.617.617 0 0 0 .681.53.616.616 0 0 0 .407-.235.593.593 0 0 0 .124-.445 77.336 77.336 0 0 0-.707-4.037c-.3-1.297-.64-2.623-1.008-3.931-.44-1.43-.88-2.683-1.341-3.82a36.44 36.44 0 0 0-1.734-3.637c-.032-.043-.103-.117-.201-.074a.124.124 0 0 0-.07.07.143.143 0 0 0 .005.11 28.504 28.504 0 0 1 1.498 3.679 49.617 49.617 0 0 1 1.089 3.846c.282 1.189.527 2.469.749 3.914.178 1.153.32 2.421.437 3.874zM66.657 23.623c-.029 0-.058 0-.087-.003a1.219 1.219 0 0 1-.847-.42 1.256 1.256 0 0 1-.303-.701.345.345 0 0 1-.026-.345c.188-1.409.417-2.715.697-3.99a52.538 52.538 0 0 1 1.032-3.988c.386-1.391.904-2.735 1.358-3.865.536-1.331 1.18-2.577 1.795-3.724.236-.38.675-.511 1.053-.328.378.183.525.634.335 1.026-.443.966-1.017 2.257-1.497 3.62a48.545 48.545 0 0 0-1.072 3.769 43.933 43.933 0 0 0-.756 3.864 52.532 52.532 0 0 0-.446 3.86.33.33 0 0 1 .003.064c-.02.33-.17.632-.42.852-.229.199-.522.31-.819.31zm-.61-1.425a.338.338 0 0 1 .023.145.587.587 0 0 0 .975.478.572.572 0 0 0 .195-.369.314.314 0 0 1-.001-.068c.098-1.29.255-2.653.454-3.943.179-1.225.43-2.508.766-3.92.29-1.213.656-2.5 1.09-3.825.49-1.396 1.072-2.705 1.524-3.69.029-.06.027-.131-.029-.158-.075-.037-.155-.01-.202.066-.596 1.113-1.23 2.34-1.754 3.641-.448 1.114-.957 2.436-1.337 3.803a52.064 52.064 0 0 0-1.021 3.946 47.618 47.618 0 0 0-.683 3.894z"
      fill="#2D1D1D"
    />
    <path
      d="M56.51 67.227h1.183c14.539.102 25.46-2.772 25.561-17.345.102-14.539-13.592-31.714-25.257-31.816-.71 0-1.453.068-2.197.17-.338.067-.676.1-1.014.202-11.09 2.536-22.383 17.852-22.45 31.106-.068 14.2 10.244 17.446 24.174 17.683z"
      fill="#F9C710"
    />
    <path
      d="M56.51 67.227s-6.39-.372-14.234-4.361c-7.845-3.956-10.211-17.244-3.821-28.807 6.357-11.496 16.297-15.587 16.364-15.62-11.09 2.535-22.382 17.851-22.45 31.105-.101 14.2 10.211 17.446 24.14 17.683z"
      fill="#F8A01F"
    />
    <path
      d="M56.51 67.227h1.183c14.539.102 25.46-2.772 25.561-17.345.102-14.539-13.592-31.714-25.257-31.816-.71 0-1.453.068-2.197.17-.744.1 6.289-1.184 15.08 8.384 8.79 9.569 11.529 21.132 10.109 28.537-1.42 7.404-11.022 11.191-24.48 12.07z"
      fill="#FFD95F"
    />
    <path
      d="M58.336 67.565h-.643c-8.452-.067-14.268-1.15-18.325-3.448-4.97-2.84-7.405-7.608-7.337-14.54.101-14.91 13.862-31.781 25.899-31.781h.067c5.58.033 12.037 3.99 17.278 10.582 5.24 6.593 8.317 14.64 8.283 21.538-.034 6.931-2.502 11.665-7.54 14.437-3.921 2.198-9.567 3.212-17.682 3.212zm-.406-49.127c-5.511 0-11.834 3.787-16.973 10.143-5.14 6.357-8.25 14.2-8.284 21.03-.033 6.661 2.266 11.26 7 13.964 3.921 2.232 9.635 3.314 17.986 3.382 8.352.067 14.066-.947 18.056-3.145 4.8-2.67 7.168-7.201 7.201-13.862.034-6.762-3.009-14.64-8.148-21.132-5.14-6.458-11.394-10.312-16.77-10.346 0-.034-.034-.034-.068-.034z"
      fill="#A55A0F"
    />
    <path
      d="M71.857 50.802c2.08-.73 2.799-4.078 1.606-7.48-1.193-3.403-3.846-5.57-5.926-4.84-2.08.73-2.8 4.078-1.606 7.48 1.193 3.403 3.846 5.57 5.926 4.84z"
      fill="#fff"
    />
    <path
      d="M70.981 51.268c-2.062 0-4.328-2.096-5.41-5.206-.608-1.69-.743-3.45-.44-4.937.339-1.521 1.117-2.603 2.266-2.975 1.116-.406 2.4-.067 3.618.913 1.183.947 2.13 2.4 2.739 4.125.608 1.69.743 3.449.44 4.936-.339 1.521-1.117 2.604-2.266 2.975a2.94 2.94 0 0 1-.947.17zm-2.637-12.645c-.27 0-.507.034-.744.135-.913.304-1.555 1.217-1.826 2.502-.304 1.353-.135 2.976.406 4.565 1.116 3.246 3.618 5.308 5.545 4.632.913-.304 1.555-1.217 1.826-2.502.304-1.353.135-2.975-.406-4.565-.575-1.589-1.454-2.941-2.536-3.82-.778-.609-1.555-.947-2.265-.947z"
      fill="#A55A0F"
    />
    <path
      d="M70.966 49.417c1.287-.451 1.729-2.532.987-4.647-.742-2.115-2.386-3.464-3.673-3.013-1.287.451-1.728 2.532-.987 4.647.742 2.115 2.386 3.464 3.673 3.013z"
      fill="#22213F"
    />
    <path
      d="M68.851 43.695c.135.405.609.575 1.048.44.44-.17.71-.61.575-.981-.135-.406-.609-.575-1.048-.44-.474.17-.744.609-.575.98zM44.62 44.23c1.258-3.376.604-6.736-1.46-7.505-2.065-.77-4.758 1.344-6.016 4.72-1.258 3.377-.603 6.738 1.461 7.507 2.065.769 4.758-1.345 6.015-4.721z"
      fill="#fff"
    />
    <path
      d="M39.47 49.443a3.14 3.14 0 0 1-.981-.17c-1.116-.405-1.894-1.487-2.198-3.009-.304-1.487-.101-3.212.541-4.902 1.319-3.55 4.227-5.748 6.458-4.903 1.116.406 1.894 1.488 2.198 3.01.304 1.487.101 3.212-.541 4.902-1.15 3.01-3.415 5.072-5.478 5.072zm2.772-12.578c-1.758 0-3.787 1.893-4.835 4.666-.575 1.589-.778 3.212-.507 4.564.27 1.319.879 2.198 1.792 2.536 1.893.71 4.429-1.319 5.612-4.53.575-1.59.778-3.213.508-4.565-.271-1.319-.88-2.198-1.792-2.536-.237-.067-.508-.135-.778-.135z"
      fill="#A55A0F"
    />
    <path
      d="M43.661 44.546c.782-2.1.38-4.188-.896-4.663-1.278-.476-2.947.84-3.729 2.94s-.38 4.187.897 4.663c1.277.476 2.946-.84 3.728-2.94z"
      fill="#22213F"
    />
    <path
      d="M41.938 42.24c.1.407.54.643 1.014.542.473-.101.777-.507.676-.913-.102-.405-.541-.642-1.014-.54-.474.1-.778.506-.676.912z"
      fill="#fff"
    />
    <path
      d="M63.982 32.537c1.623.676 3.178.609 3.483-.135.304-.744-.778-1.893-2.401-2.536-1.623-.676-3.178-.608-3.482.136-.305.71.777 1.859 2.4 2.535z"
      fill="#F8A01F"
    />
    <path
      d="M66.079 33.315c-.677 0-1.454-.17-2.198-.473-1.86-.744-2.942-2.029-2.57-2.976.372-.946 2.063-1.081 3.922-.338.846.338 1.556.812 2.029 1.319.54.575.743 1.15.54 1.657-.202.473-.743.777-1.52.811h-.203zm-3.111-3.618c-.575 0-.98.135-1.082.406-.203.507.71 1.521 2.232 2.13.743.304 1.487.44 2.096.44.507-.035.879-.17.98-.406.102-.237-.067-.575-.405-.947-.406-.44-1.048-.88-1.792-1.15-.778-.338-1.454-.473-2.03-.473z"
      fill="#A55A0F"
    />
    <path
      d="M47.854 32.605c1.725-.406 2.942-1.353 2.773-2.13-.17-.778-1.724-1.116-3.415-.71-1.724.405-2.942 1.352-2.773 2.13.17.777 1.725 1.082 3.415.71z"
      fill="#F8A01F"
    />
    <path
      d="M46.434 33.078c-1.25 0-2.164-.406-2.333-1.15-.236-.98 1.082-2.062 3.01-2.502 1.96-.44 3.584-.034 3.82.947.102.507-.169 1.048-.811 1.555-.575.44-1.353.778-2.232.98-.473.136-.98.17-1.454.17zm2.198-3.178c-.406 0-.845.034-1.319.169-1.589.372-2.637 1.217-2.535 1.724.135.508 1.453.846 3.043.474.777-.17 1.487-.474 1.96-.846.406-.304.61-.642.575-.879-.101-.372-.777-.642-1.724-.642z"
      fill="#A55A0F"
    />
    <path
      d="M64.488 21.527c.154-.34-.204-.834-.799-1.103-.595-.27-1.203-.212-1.357.128-.154.34.204.835.8 1.104.595.27 1.203.212 1.356-.129zM67.575 23.508c.205-.312-.072-.856-.618-1.214-.547-.36-1.156-.397-1.36-.085-.206.312.07.856.617 1.215.546.359 1.156.397 1.36.084zM64.993 23.55c.154-.234-.038-.631-.428-.887-.39-.257-.831-.275-.985-.04-.154.234.038.631.428.887.39.257.832.275.985.04z"
      fill="#F4B503"
    />
    <path
      d="M78.757 77.979c-.203.169-.101.575.237.913.304.338.71.44.913.27.203-.169.101-.575-.237-.913-.304-.304-.71-.44-.913-.27zM80.414 79.872c-.203.135-.169.575.068.947.237.372.642.541.845.406.203-.136.17-.575-.067-.947-.271-.372-.643-.54-.846-.406zM79.698 80.553c.153-.107.13-.404-.051-.665-.181-.26-.453-.385-.606-.279-.153.107-.13.404.05.665.182.26.453.385.607.279z"
      fill="#F9C710"
    />
    <path
      d="M50.965 97.319a28.345 28.345 0 0 0 6.322 5.409s15.925-4.361 24.649-14.876a25.637 25.637 0 0 0 2.13-2.908l-1.184-3.449s-.54 1.15-2.164 2.84c-3.414 3.618-11.664 9.806-29.753 12.984z"
      fill="#332E25"
    />
    <path
      d="M80.718 84.335s.71 1.454 1.218 3.517a25.637 25.637 0 0 0 2.13-2.908l-1.184-3.449s-.54 1.15-2.164 2.84z"
      fill="#3F3A31"
    />
    <path
      d="M57.288 103.067c-.068 0-.103-.155-.137-.189-2.333-1.454-4.403-3.226-6.296-5.39-.068-.101-.228-.17-.194-.27.033-.102.135-.17.236-.204 13.626-2.4 21.538-6.56 25.73-9.602 4.565-3.28 5.917-6.052 5.917-6.086 0-.034.034-.034.034-.067.067-.102.203-.136.304-.136.135.034.11.135.178.236l1.154 3.432v.165c0 .068.122.056.122.09-7.912 12.577-26.778 17.886-26.947 17.919-.034.102-.068.102-.102.102zm-5.68-5.512a28.701 28.701 0 0 0 5.747 4.835c1.69-.507 18.9-5.748 26.373-17.48l-.913-2.637c-.676 1.014-2.299 3.178-5.714 5.646-4.227 3.043-12.037 7.168-25.493 9.636z"
      fill="#2D1D1D"
    />
    <path
      d="M74.463 115.982c1.995 1.116 5.816-6.322 3.922-7.472-2.772-.947-5.781 6.458-3.922 7.472z"
      fill="#332E25"
    />
    <path
      d="M72.198 108.578c1.59.777 5.41 3.787 6.56 1.352.169-.507 0-1.048-.338-1.42-4.7-1.826-9.028-5.68-8.96-11.124.71-2.67-3.246-3.786-3.686-.946-.135 4.801 2.434 9.534 6.424 12.138z"
      fill="#332E25"
    />
    <path
      d="M77.676 110.877c-.474 1.826-1.961 4.429-3.213 5.105.88.44 2.469-.879 3.517-2.941 1.014-1.995 1.183-3.922.405-4.497a1.267 1.267 0 0 0-.507-.304 16.024 16.024 0 0 1-3.55-1.826c-1.048-.71-2.029-1.623-2.773-2.671-1.352-1.792-2.13-4.091-2.13-6.323-.033.135-.743 4.328 1.184 6.898 1.724 2.333 4.564 3.854 6.221 4.463.237.101.406.169.609.203.473.168.507.912.237 1.893z"
      fill="#3F3A31"
    />
    <path
      d="M79.13 109.093v-.008l-.005-.012-.002-.014a1.715 1.715 0 0 0-1.075-1.191c-1.285-.473-2.469-1.081-3.483-1.792-1.048-.71-1.995-1.589-2.705-2.569-1.42-1.894-2.197-4.43-2.062-6.729 0-.169-.135-.338-.304-.338-.17 0-.338.135-.338.305-.136 2.501.642 5.105 2.197 7.167a10.965 10.965 0 0 0 2.84 2.739c1.048.744 2.266 1.352 3.618 1.859.108.044.208.106.298.179.13.106.245.336.31.599.203.845-.101 2.266-.777 3.618-.474.981-1.082 1.792-1.725 2.333-.54.439-1.048.608-1.352.473-.609-.338-.609-2.096.44-4.159.202-.371.428-.712.665-1.051l.002-.005c.004-.005.008-.012.01-.018a.26.26 0 0 0 .026-.06l.007-.015c.067-.169 0-.372-.17-.44a15.636 15.636 0 0 1-3.178-1.657c-3.922-2.569-6.39-7.201-6.288-11.833 0-.17-.136-.338-.305-.338-.203 0-.338.135-.338.304-.101 4.835 2.468 9.704 6.593 12.408a18.997 18.997 0 0 0 2.906 1.562 10.04 10.04 0 0 0-.472.839c-1.149 2.299-1.216 4.463-.135 5.004.17.101.338.135.541.135.44 0 .98-.236 1.454-.676.676-.608 1.353-1.487 1.894-2.536.769-1.505 1.133-3.1.912-4.083z"
      fill="#2D1D1D"
    />
    <path
      d="M69.595 35.276c-4.59 0-8.406 3.38-9.088 7.782-1.601-1.2-4.988-2.793-10.5-1.103-.45-4.653-4.382-8.302-9.151-8.302-5.072 0-9.197 4.125-9.197 9.197 0 5.071 4.125 9.196 9.197 9.196a9.183 9.183 0 0 0 9.18-8.663c6.55-2.172 9.713.677 10.37 1.383.156 4.936 4.216 8.903 9.189 8.903 5.071 0 9.196-4.125 9.196-9.197 0-5.071-4.125-9.196-9.196-9.196zM40.855 50.66c-4.293 0-7.81-3.516-7.81-7.81s3.517-7.81 7.81-7.81c4.329 0 7.81 3.516 7.81 7.81s-3.515 7.81-7.81 7.81zm28.74 1.623c-4.294 0-7.81-3.517-7.81-7.81 0-4.295 3.516-7.81 7.81-7.81s7.81 3.515 7.81 7.81c0 4.293-3.516 7.81-7.81 7.81z"
      fill="#4150D8"
    />
    <path
      d="M69.595 53.972a9.454 9.454 0 0 1-9.488-9.08c-.712-.72-3.734-3.218-9.783-1.284-.388 4.918-4.507 8.74-9.469 8.74-5.237 0-9.499-4.26-9.499-9.498 0-5.239 4.261-9.5 9.5-9.5a9.459 9.459 0 0 1 9.411 8.212c5.068-1.452 8.339-.143 10.027.976.906-4.345 4.821-7.565 9.3-7.565 5.238 0 9.5 4.262 9.5 9.5 0 5.237-4.261 9.499-9.5 9.499zM54.553 42.285c3.713 0 5.635 1.802 6.075 2.275.05.053.078.123.08.197a8.852 8.852 0 0 0 8.887 8.61c4.904 0 8.894-3.99 8.894-8.895 0-4.904-3.99-8.893-8.894-8.893-4.345 0-8.123 3.235-8.788 7.525a.302.302 0 0 1-.481.196c-1.483-1.111-4.783-2.726-10.23-1.056a.304.304 0 0 1-.39-.26 8.857 8.857 0 0 0-8.85-8.028c-4.904 0-8.894 3.99-8.894 8.894s3.99 8.894 8.894 8.894c4.71 0 8.61-3.68 8.878-8.377a.303.303 0 0 1 .206-.27c1.77-.588 3.303-.812 4.613-.812zm15.042 10.3c-4.474 0-8.114-3.64-8.114-8.113 0-4.473 3.64-8.113 8.114-8.113s8.113 3.64 8.113 8.113c0 4.474-3.64 8.113-8.113 8.113zm0-15.62c-4.14 0-7.508 3.368-7.508 7.507 0 4.14 3.368 7.508 7.508 7.508 4.14 0 7.507-3.368 7.507-7.508 0-4.14-3.368-7.507-7.507-7.507zm-28.74 13.997c-4.473 0-8.112-3.639-8.112-8.113 0-4.473 3.64-8.112 8.113-8.112 4.473 0 8.113 3.64 8.113 8.112 0 4.474-3.64 8.114-8.113 8.114zm0-15.62c-4.14 0-7.507 3.368-7.507 7.508 0 4.14 3.368 7.507 7.508 7.507 4.14 0 7.507-3.368 7.507-7.508 0-4.14-3.368-7.507-7.507-7.507z"
      fill="#31409B"
    />
    <path
      d="M54.448 54.785c-3.99-.203-5.545-3.483-5.343-5.308.034-.406.203-.744.406-.947 1.319-1.184 2.468.54 5.207.676 2.772.135 4.091-1.454 5.274-.135.204.203.305.575.305 1.014-.034 1.758-1.792 4.902-5.85 4.7z"
      fill="#22213F"
    />
    <path
      d="M60.297 49.747c-1.184.845-3.28 1.454-5.68 1.352-2.401-.101-4.43-.947-5.512-1.927.034-.406.17-.473.406-.676 1.319-1.184 2.468.54 5.207.676 2.772.135 4.09-1.454 5.274-.135.204.236.305.27.305.71z"
      fill="#fff"
    />
    <path
      d="M54.82 55.123h-.406c-2.266-.102-3.618-1.183-4.362-2.029-.913-1.048-1.386-2.468-1.285-3.617.068-.508.237-.913.508-1.184.912-.811 1.758-.473 2.772-.067.744.27 1.555.608 2.671.676 1.116.067 1.995-.17 2.739-.406 1.048-.304 1.927-.54 2.739.372.236.27.372.71.372 1.217 0 .913-.44 2.367-1.657 3.517-.744.675-2.063 1.52-4.092 1.52zm-4.295-6.695c-.27 0-.507.102-.777.338-.17.136-.27.406-.304.744-.068.744.169 1.995 1.115 3.145.643.777 1.86 1.724 3.922 1.825 2.029.102 3.314-.676 4.058-1.352 1.081-1.014 1.453-2.266 1.453-3.043 0-.338-.067-.643-.202-.778-.508-.575-1.015-.473-2.097-.169-.777.203-1.724.473-2.941.406-1.218-.068-2.164-.44-2.874-.71-.609-.27-1.014-.406-1.353-.406z"
      fill="#A55A0F"
    />
    <path
      d="M39.96 9.19c2.324-.232 4.127-1.217 4.03-2.202-.098-.985-2.061-1.596-4.384-1.365-2.323.232-4.127 1.217-4.029 2.202.098.985 2.06 1.596 4.384 1.364z"
      fill="#332E25"
    />
    <path
      d="M38.963 9.556c-2.076 0-3.607-.673-3.71-1.71-.061-.615.391-1.22 1.275-1.702.798-.437 1.88-.742 3.046-.858 1.166-.116 2.287-.031 3.156.239.96.298 1.524.802 1.586 1.417.06.616-.392 1.22-1.275 1.704-.798.436-1.88.74-3.046.857-.355.036-.7.053-1.032.053zm.676-3.62c-1.081.109-2.075.386-2.798.781-.62.34-.97.737-.937 1.065.068.688 1.805 1.292 4.026 1.07 1.08-.107 2.074-.385 2.797-.78.62-.339.97-.737.938-1.065-.033-.327-.455-.648-1.13-.858-.787-.245-1.816-.32-2.896-.212z"
      fill="#2D1D1D"
    />
    <path
      d="M78.89 7.805c.101-.984-1.7-1.976-4.022-2.214-2.323-.238-4.288.367-4.389 1.352-.1.985 1.7 1.976 4.023 2.214 2.323.238 4.287-.367 4.388-1.352z"
      fill="#332E25"
    />
    <path
      d="M75.508 9.55c-.338 0-.691-.018-1.055-.055-2.583-.265-4.437-1.37-4.314-2.572.063-.615.627-1.117 1.59-1.413.869-.268 1.99-.35 3.155-.23 1.166.118 2.247.426 3.044.864.883.486 1.333 1.092 1.27 1.707-.105 1.032-1.626 1.699-3.69 1.699zM73.85 5.88c-.717 0-1.38.086-1.928.255-.675.208-1.098.528-1.132.855-.07.688 1.51 1.627 3.73 1.854 2.222.227 3.959-.372 4.029-1.06.033-.328-.316-.726-.935-1.067-.722-.397-1.715-.677-2.795-.787a9.48 9.48 0 0 0-.969-.05z"
      fill="#2D1D1D"
    />
  </svg>
);

export const MailSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
  >
    <g clip-path="url(#3jgsiw8k3a)">
      <path
        d="M22.5 6c0-1.1-.9-2-2-2h-16c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6zm-2 0-8 5-8-5h16zm0 12h-16V8l8 5 8-5v10z"
        fill="#6C7780"
      />
    </g>
    <defs>
      <clipPath id="3jgsiw8k3a">
        <path fill="#fff" transform="translate(.5)" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const LockSvg = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
  >
    <g clip-path="url(#tn5bbpohra)">
      <path
        d="M12 5.333h-.667V4a3.335 3.335 0 0 0-6.667 0v1.333H4c-.733 0-1.333.6-1.333 1.334v6.666c0 .734.6 1.334 1.333 1.334h8c.733 0 1.333-.6 1.333-1.334V6.667c0-.734-.6-1.334-1.333-1.334zm-4 6c-.734 0-1.333-.6-1.333-1.333S7.266 8.667 8 8.667s1.333.6 1.333 1.333-.6 1.333-1.333 1.333zm2.066-6H5.933V4c0-1.14.927-2.067 2.067-2.067 1.14 0 2.066.927 2.066 2.067v1.333z"
        fill="#6C7780"
      />
    </g>
    <defs>
      <clipPath id="tn5bbpohra">
        <path fill="#fff" d="M0 0h16v16H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const WebSignInSVG = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#4afyv25rea)">
      <path
        d="M3 4.5h13.5V3H3c-.825 0-1.5.675-1.5 1.5v8.25H0V15h10.5v-2.25H3V4.5zM17.25 6h-4.5a.752.752 0 0 0-.75.75v7.5c0 .412.338.75.75.75h4.5c.413 0 .75-.338.75-.75v-7.5a.752.752 0 0 0-.75-.75zm-.75 6.75h-3V7.5h3v5.25z"
        fill="#6C7780"
      />
    </g>
    <defs>
      <clipPath id="4afyv25rea">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const OpenInNewSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#jyqxpxq9ka)">
      <path
        d="M14.25 14.25H3.75V3.75H9v-1.5H3.75a1.5 1.5 0 0 0-1.5 1.5v10.5a1.5 1.5 0 0 0 1.5 1.5h10.5c.825 0 1.5-.675 1.5-1.5V9h-1.5v5.25zm-3.75-12v1.5h2.693L5.82 11.123l1.058 1.057 7.372-7.372V7.5h1.5V2.25H10.5z"
        fill="#2988DF"
      />
    </g>
    <defs>
      <clipPath id="jyqxpxq9ka">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const EditSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
  >
    <g clip-path="url(#bm7m0cl1da)">
      <path
        d="m10.545 6.765.69.69L4.44 14.25h-.69v-.69l6.795-6.795zm2.7-4.515a.75.75 0 0 0-.525.217L11.347 3.84l2.813 2.813 1.373-1.373a.747.747 0 0 0 0-1.058l-1.755-1.755a.736.736 0 0 0-.533-.217zm-2.7 2.393L2.25 12.937v2.813h2.813l8.294-8.295-2.812-2.813z"
        fill="#2988DF"
      />
    </g>
    <defs>
      <clipPath id="bm7m0cl1da">
        <path fill="#fff" d="M0 0h18v18H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const AutoFetchSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <g clip-path="url(#15gmnbezga)">
      <path
        d="m11.084 5.25.73-1.604 1.603-.73-1.604-.729-.729-1.604-.73 1.604-1.603.73 1.604.729.729 1.604zm-4.375.291L5.251 2.333 3.792 5.541.584 7l3.208 1.458 1.459 3.208 1.458-3.208L9.917 7 6.71 5.54zm4.375 3.209-.73 1.604-1.603.729 1.604.73.729 1.603.73-1.604 1.603-.729-1.604-.73-.729-1.603z"
        fill="#2988DF"
      />
    </g>
    <defs>
      <clipPath id="15gmnbezga">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const RetrySVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <g clip-path="url(#j3ypbmfzpa)">
      <path
        d="M10.296 3.704A4.642 4.642 0 0 0 7 2.333 4.66 4.66 0 0 0 2.339 7a4.66 4.66 0 0 0 4.66 4.666 4.657 4.657 0 0 0 4.51-3.5h-1.213A3.495 3.495 0 0 1 7 10.5c-1.931 0-3.5-1.57-3.5-3.5 0-1.931 1.569-3.5 3.5-3.5A3.45 3.45 0 0 1 9.46 4.538L7.583 6.416h4.083V2.333l-1.37 1.37z"
        fill="#D94A41"
      />
    </g>
    <defs>
      <clipPath id="j3ypbmfzpa">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);

export const WorldIconSVG = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
  >
    <g clip-path="url(#nxk6t6z24a)">
      <path
        d="M6.994 1.167A5.83 5.83 0 0 0 1.167 7a5.83 5.83 0 0 0 5.827 5.834A5.837 5.837 0 0 0 12.834 7a5.837 5.837 0 0 0-5.84-5.833zm4.043 3.5h-1.72A9.129 9.129 0 0 0 8.51 2.59a4.684 4.684 0 0 1 2.526 2.077zM7 2.357c.484.7.864 1.476 1.114 2.31H5.886c.251-.834.63-1.61 1.114-2.31zm-4.515 5.81A4.807 4.807 0 0 1 2.334 7c0-.402.058-.793.151-1.166h1.972c-.047.385-.082.77-.082 1.166 0 .397.035.782.082 1.167H2.485zm.479 1.167h1.72c.187.729.455 1.429.805 2.076a4.659 4.659 0 0 1-2.525-2.076zm1.72-4.667h-1.72A4.659 4.659 0 0 1 5.489 2.59a9.129 9.129 0 0 0-.805 2.077zM7 11.644a8.218 8.218 0 0 1-1.114-2.31h2.228c-.25.834-.63 1.61-1.114 2.31zm1.365-3.477h-2.73A8.582 8.582 0 0 1 5.542 7c0-.396.04-.787.093-1.166h2.73c.053.379.094.77.094 1.166 0 .397-.041.782-.094 1.167zm.146 3.243a9.13 9.13 0 0 0 .805-2.076h1.721a4.684 4.684 0 0 1-2.526 2.076zm1.033-3.243c.046-.385.081-.77.081-1.167 0-.396-.035-.781-.081-1.166h1.971c.094.373.152.764.152 1.166 0 .403-.058.794-.152 1.167H9.544z"
        fill="#1B2126"
      />
    </g>
    <defs>
      <clipPath id="nxk6t6z24a">
        <path fill="#fff" d="M0 0h14v14H0z" />
      </clipPath>
    </defs>
  </svg>
);
export const SoldIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#F8EFEF" />
    <g clipPath="url(#clip0_1153_10527)">
      <path
        d="M6 12L7.0575 13.0575L11.25 8.8725V18H12.75V8.8725L16.935 13.065L18 12L12 6L6 12Z"
        fill="#D94A41"
      />
    </g>
    <defs>
      <clipPath id="clip0_1153_10527">
        <rect width="18" height="18" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);
export const BoughtIconSVG = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="24" height="24" rx="12" fill="#DFF4EA" />
    <g clipPath="url(#clip0_1153_10586)">
      <path
        d="M18 12L16.9425 10.9425L12.75 15.1275V6H11.25V15.1275L7.065 10.935L6 12L12 18L18 12Z"
        fill="#1DA868"
      />
    </g>
    <defs>
      <clipPath id="clip0_1153_10586">
        <rect width="18" height="18" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);

export const NoDataIcon = () => (
  <svg
    width="100"
    height="100"
    viewBox="0 0 136 137"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_51324_38037)">
      <rect
        x="18"
        y="18.5127"
        width="100"
        height="100"
        rx="50"
        fill="white"
        shape-rendering="crispEdges"
      />
      <path
        d="M51.5896 47.6666H84.4101C85.7158 47.6666 86.968 48.2061 87.8912 49.1662C88.8145 50.1264 89.3332 51.4287 89.3332 52.7866V85.2133C89.3332 86.5712 88.8145 87.8735 87.8912 88.8337C86.968 89.7939 85.7158 90.3333 84.4101 90.3333H51.5896C50.2839 90.3333 49.0317 89.7939 48.1084 88.8337C47.1852 87.8735 46.6665 86.5712 46.6665 85.2133V52.7866C46.6665 51.4287 47.1852 50.1264 48.1084 49.1662C49.0317 48.2061 50.2839 47.6666 51.5896 47.6666Z"
        fill="#2988DF"
      />
      <path
        d="M73.1382 71.4314H72.0571L71.6739 71.078C73.015 69.5857 73.8224 67.6484 73.8224 65.5409C73.8224 60.8417 69.8401 57.0325 64.9272 57.0325C60.0143 57.0325 56.032 60.8417 56.032 65.5409C56.032 70.2402 60.0143 74.0494 64.9272 74.0494C67.1305 74.0494 69.1559 73.2771 70.716 71.9943L71.0854 72.3608V73.3949L77.9279 79.9268L79.967 77.9764L73.1382 71.4314ZM64.9272 71.4314C61.5197 71.4314 58.769 68.8003 58.769 65.5409C58.769 62.2815 61.5197 59.6505 64.9272 59.6505C68.3348 59.6505 71.0854 62.2815 71.0854 65.5409C71.0854 68.8003 68.3348 71.4314 64.9272 71.4314Z"
        fill="white"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_51324_38037"
        x="0"
        y="0.512695"
        width="136"
        height="136"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="9" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.160784 0 0 0 0 0.533333 0 0 0 0 0.87451 0 0 0 0.15 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_51324_38037"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_51324_38037"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
export const FireIcon = () => (
  <svg
    width="10"
    height="14"
    viewBox="0 0 10 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.43327 8.76029C9.43317 8.75903 9.4331 8.75776 9.433 8.75653C9.39438 8.22111 9.29566 7.76645 9.12254 7.32735C9.12021 7.32071 9.1177 7.31412 9.11497 7.30755C9.10507 7.28372 8.13211 4.90999 8.58767 3.60547C8.62879 3.48778 8.60884 3.35738 8.53447 3.25733C8.4601 3.15727 8.34113 3.10075 8.21648 3.10606C8.18307 3.10751 7.50255 3.14527 6.7807 3.78037C6.14894 2.47687 6.04919 1.09207 6.04816 1.07696C6.03962 0.945987 5.96339 0.828995 5.84705 0.768257C5.73069 0.707493 5.59114 0.711903 5.47879 0.779708C4.48182 1.38193 3.71164 2.11688 3.18967 2.96418C2.76821 3.64829 2.50832 4.40394 2.41719 5.21015C2.36246 5.69443 2.37547 6.13731 2.41584 6.50936C2.43453 6.68173 2.26236 6.81132 2.1019 6.7457L1.13437 6.34987C1.06842 6.32289 0.996252 6.31166 0.925891 6.32299C0.777754 6.34684 0.662341 6.45313 0.622826 6.59064C0.535026 6.89607 0.469276 7.17836 0.421793 7.45364C0.256516 8.41234 0.324145 9.39423 0.617288 10.2932C0.91201 11.197 1.42059 11.9753 2.08806 12.544C2.87689 13.216 3.83214 13.5582 4.87381 13.5582C5.44859 13.5582 6.04974 13.454 6.66366 13.2436C7.63751 12.9098 8.40876 12.2486 8.89402 11.3316C9.29731 10.5694 9.48885 9.65623 9.43327 8.76029Z"
      fill="#FF641A"
    />
    <path
      d="M9.433 8.75653C9.39438 8.22111 9.29566 7.76645 9.12254 7.32735C9.12021 7.32071 9.1177 7.31412 9.11497 7.30755C9.10507 7.28372 8.13211 4.90999 8.58767 3.60547C8.62879 3.48778 8.60884 3.35738 8.53447 3.25733C8.4601 3.15727 8.34113 3.10075 8.21648 3.10606C8.18683 3.10734 7.64715 3.13725 7.02135 3.58831C6.89176 3.68172 6.70846 3.6306 6.64617 3.48349C6.13443 2.27501 6.04911 1.09092 6.04816 1.07696C6.03962 0.945987 5.96339 0.828995 5.84705 0.768257C5.73069 0.707493 5.59114 0.711903 5.47879 0.779708C5.28009 0.899731 5.09055 1.02512 4.91004 1.15551V13.5578C5.47385 13.5535 6.06262 13.4496 6.66366 13.2436C7.63751 12.9098 8.40876 12.2486 8.89402 11.3316C9.29731 10.5694 9.48885 9.65623 9.43327 8.76029C9.43317 8.75903 9.4331 8.75776 9.433 8.75653Z"
      fill="#FF001E"
    />
    <path
      d="M5.90551 7.24246C5.81049 7.10071 5.63194 7.0406 5.47062 7.0964C5.3093 7.1521 5.20577 7.30946 5.21847 7.47965C5.2633 8.08041 5.20323 9.84691 4.36768 10.076C3.65639 10.2711 3.27697 9.94165 3.24011 9.90748C3.14788 9.80692 3.01249 9.76884 2.87954 9.80071C2.74569 9.83291 2.64451 9.94035 2.6088 10.0733C2.60171 10.0997 2.53882 10.3354 2.51231 10.489C2.34232 11.4751 2.66468 12.4524 3.35362 13.0393C3.75772 13.3836 4.2451 13.5589 4.77498 13.5589C5.06404 13.5589 5.36583 13.5067 5.67341 13.4013C6.16901 13.2314 6.59278 12.872 6.8989 12.362C7.15496 11.9354 7.30384 11.4377 7.30745 10.9964C7.31662 9.86107 6.88426 8.70331 5.90551 7.24246Z"
      fill="#FFEB00"
    />
    <path
      d="M5.47062 7.0964C5.3093 7.1521 5.20577 7.30946 5.21847 7.47965C5.25047 7.9085 5.22892 8.9313 4.91007 9.56492V13.5548C5.15763 13.5411 5.41339 13.4904 5.67341 13.4013C6.16901 13.2314 6.59278 12.872 6.8989 12.362C7.15496 11.9354 7.30384 11.4377 7.30745 10.9964C7.31662 9.86107 6.88426 8.70331 5.90551 7.24246C5.81049 7.10071 5.63194 7.0406 5.47062 7.0964Z"
      fill="#FFBB00"
    />
  </svg>
);
