// lib
import {createTheme, Theme} from "@mui/material";

// src
import {AppThemeConfigs} from "./theme.types";

export const appThemeConfigs: AppThemeConfigs = {
  palette: {
    error: {
      main: "#d94a41",
      light: "#f8efef",
      dark: "#F5844B",
    },
    info: {
      main: "#2988df",
      light: "#dbedfb",
      border: "#CCE4F6",
    },
    icon: {
      default: "#BEC2C4",
    },
    active: {
      primary: "#dbedfb",
      secondary: "#edf6fd",
    },
    success: {
      main: "#2e7d32",
      light: "#DFF4EA",
      dark: "#1da868",
    },
    action: {
      hover: "#edf6fd",
    },
    text: {
      primary: "#1b2126",
      secondary: "#6c7780",
      tertiary: "#fff",
      error: "#d94a41",
      active: "#2988df",
      success: "#1da868",
      light: "#d4d0cf",
    },
    divider: "#e5e6ea",
    background: {
      default: "#f2f5fa",
      paper: "#fff",
      warning: "#f8efef",
      red: "rgba(245, 126, 66, 0.2)",
      green: "rgba(29, 168, 104, 0.2)",
      warningDark: "#FEF2EC",
      disabled: "#FBFAFA",
    },
    plans: {
      advanced: "#FFFBF0",
      pro: "#FFF7E1",
    },
    buttonPrimary: "#fbbf21",
    buttonPrimaryLight: "#fff7e1",
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          // scrollbarColor: "rgba(0, 0, 0, 0.2) transparent",
          overflowY: "overlay",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            width: "10px",
            height: "10px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 10,
            backgroundColor: "rgba(0, 0, 0, 0.2)",
            outline: " 1px solid transparent",
            minHeight: 24,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "rgba(0, 0, 0, 0.3)",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "rgba(0, 0, 0, 0.2)",
          },
        },
        ".MuiTableContainer-root": {
          // scrollbarColor: "rgba(0, 0, 0, 0.2) transparent",
          overflowY: "overlay",
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            width: "10px",
            height: "10px",
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: 10,
            backgroundColor: "rgba(0, 0, 0, 0.1)",
            outline: " 1px solid transparent",
            minHeight: 24,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: "rgba(0, 0, 0, 0.1)",
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: "rgba(0, 0, 0, 0.2)",
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: "rgba(0, 0, 0, 0.1)",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          color: "#1B2126",
          backgroundColor: "transparent",
          fontSize: "16px",
          textTransform: "none" as const,
          ":hover": {
            backgroundColor: "transparent",
          },
        },
        primary: {
          backgroundColor: "#2988DF",
          color: "#FFFFFF",
          border: "1px solid #2988DF",
          fontWeight: 500,
          padding: "10px 32px",
          ":hover": {
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #2988DF",
            boxShadow: "0px 4px 18px 2px rgba(37, 37, 37, 0.08)",
          },
          ":focus": {
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #2988DF",
          },
          ":disabled": {
            backgroundColor: "#F2F5FA",
            opacity: 0.5,
            fontWeight: 500,
            color: "#BEC2C4",
            border: "none",
            boxShadow: "none",
          },
        },
        contained: {
          backgroundColor: "#FBBF21",
          border: "1px solid #FBBF21",
          fontWeight: 500,
          padding: "10px 32px",
          ":hover": {
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #FBBF21",
            boxShadow: "0px 4px 18px 2px rgba(37, 37, 37, 0.08)",
          },
          ":focus": {
            background:
              " linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #FBBF21",
          },
          ":disabled": {
            backgroundColor: "#fbbf21",
            opacity: 0.5,
            fontWeight: 500,
            color: "#1b2126",
            boxShadow: "none",
          },
        },
        outlined: {
          border: "1px solid #1B2126",
          padding: "10px 32px",

          ":hover": {
            border: "1px solid #1B2126",
            backgroundColor: "#F2F5FA",
            boxShadow: "0px 4px 18px 2px rgba(37, 37, 37, 0.08)",
            transition: "all .2s linear",
          },
          ":focus": {
            background: "#E5E6EA",
          },
          ":disabled": {
            boxShadow: "none",
          },
        },
        link: {
          color: "#2988df",
          width: "fit-content",
          fontSize: "12px",
          padding: "10px 16px",
          textTransform: "initial",

          ":hover": {
            background: "#F2F5FA",
          },
          ":focus": {
            background: "#E0EDF8",
          },
        },
        danger: {
          color: "#FFFFFF",
          backgroundColor: "#d94a41",
          ":hover": {
            background:
              "linear-gradient(0deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0.15)), #D94A41",
            boxShadow: "0px 4px 18px 2px rgba(37, 37, 37, 0.08);",
          },
          ":focus": {
            background:
              "linear-gradient(0deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), #D94A41",
          },
        },
        info: {
          color: "#2988df",
          fontSize: "14px",
          padding: "0",
          minWidth: "0",

          ":hover": {
            color: "#2988DF",
          },
          ":focus": {
            color: "#006CCE",
          },
          ":disabled": {
            color: "#BEC2C4",
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1920,
    },
  },
};

export type ThemeType = typeof appThemeConfigs & Theme;
export const AppTheme = createTheme(appThemeConfigs);
