import {InfoSvg} from "../../assets/svgs/Dashboard";
import {CustomTooltip, ErrorSvgWrapper} from "../../styles/common";
import {InfoOutlinedSvg} from "../../assets/svgs/Common";
import classNames from "classnames";
import {isSomething} from "../../utils";

interface InfoTooltipProps {
  title: string | JSX.Element;
  placement?: "bottom" | "right";
  isFilledIcon?: boolean;
  id?: any;
  customClassName?: string;
}

export const InfoTooltip = (props: InfoTooltipProps) => {
  const {
    title,
    placement = "right",
    isFilledIcon = true,
    customClassName = "",
    ...rest
  } = props;
  return (
    <CustomTooltip {...rest} title={title} arrow placement={placement}>
      <ErrorSvgWrapper
        className={classNames({
          ["svgWrapper"]: true,
          [customClassName]: isSomething(customClassName),
        })}
        id="tooltip-info"
      >
        {isFilledIcon ? <InfoSvg /> : <InfoOutlinedSvg />}
      </ErrorSvgWrapper>
    </CustomTooltip>
  );
};
