import {Theme} from "@mui/material";
import {makeStyles} from "@mui/styles";
import {styled} from "@mui/system";
import {NavLink} from "react-router-dom";

export const AppBar = styled("div")(props => ({
  height: "89px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  backgroundColor: "#1b2126",
  padding: "0px 50px",
  border: "solid 1px #1b2126",
  position: "sticky",
  top: 0,
  zIndex: 10,
  [props.theme?.breakpoints.down("lg")]: {
    padding: "0px 25px",
    position: "fixed",
    top: 0,
    width: "100%",
    zIndex: 10,
  },
  [props.theme?.breakpoints.down("md")]: {
    paddingRight: "15px",
  },
}));

export const LinksWrapper = styled("div")(props => {
  return {
    display: "flex",
    height: "100%",
    alignItems: "center",
    transition: "position 300ms linear",

    [props.theme?.breakpoints.up("md")]: {
      columnGap: "20px",
    },
    [props.theme?.breakpoints.up("lg")]: {
      columnGap: "40px",
    },

    [props.theme?.breakpoints.down("md")]: {
      visibility: "hidden",
      position: "fixed",
    },

    "&.isShowDropdown": {
      visibility: "visible",
      transition: "all 300ms linear",
      position: "fixed",
      top: "89px",
      left: 0,
      backgroundColor: "#1b2126",
      right: 0,
      display: "grid",
      height: "auto",
      padding: "24px 0",
      borderTop: "1px solid #6c778033 ",
    },
  };
});

interface linkProps {
  active?: "active" | undefined;
  theme?: Theme;
  isShowDropdown?: boolean;
}

export const LinkHeightWrap = styled("span")((props: linkProps) => {
  const getBorderBottom = () => {
    if (!props.isShowDropdown) {
      if (props.active) return `4px solid ${props.theme?.palette.common.white}`;
      return "4px solid transparent";
    }

    return "1px solid #6c77801a";
  };

  return {
    height: "100%",
    borderBottom: getBorderBottom(),

    "&:last-child": {
      borderBottom: props.isShowDropdown ? "none" : ``,
    },
  };
});

export const ActiveLinkWrapper = styled("div")((props: linkProps) => ({
  height: "100%",
  alignItems: "center",
  display: "flex",

  padding: props.isShowDropdown ? "16px 24px" : "0px 10px",
  color: props.active && props.isShowDropdown ? "#fbbf21" : "#fff",
}));

export const ButtonWrapper = styled("div")(props => ({
  // minWidth: "153px",
  textAlign: "right",
  h4: {
    color: "white",
  },

  // [props.theme?.breakpoints.down("md")]: {
  //   width: "200px",
  // },
}));

export const NavLinkWrapper = styled(NavLink)(props => ({
  color: "inherit",
  fontSize: "16px",
  fontFamily: " Roboto",
  fontStyle: "normal",
  lineHeight: "1.5",
  textDecoration: "none",
  height: "100%",
  alignItems: "center",
  display: "flex",
  [props.theme?.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

export const OutsideLinkWrapper = styled("a")(props => ({
  color: "inherit",
  fontSize: "16px",
  fontFamily: " Roboto",
  fontStyle: "normal",
  lineHeight: "1.5",
  textDecoration: "none",
  height: "100%",
  alignItems: "center",
  display: "flex",
  [props.theme?.breakpoints.down("md")]: {
    fontSize: "14px",
  },
}));

export const LogoWrapper = styled("div")(() => ({
  maxWidth: "300px",
  display: "grid",
  placeItems: "center",
}));

export const useHeaderStyles = makeStyles((theme: any) => ({
  lockIconContainer: {
    marginRight: "12px",
    display: "grid",
    placeItems: "center",
  },
  flexWrapper: {display: "flex", gap: 16, placeItems: "center flex-start"},
  menuToggleButton: {
    display: "grid",
    gap: 3,
    cursor: "pointer",
    visibility: "hidden",

    [theme.breakpoints.down("md")]: {
      visibility: "visible",
    },
  },

  toggleMenuBar: {
    transition: "all 300ms linear",
    width: "18px",
    height: "2px",
    borderRadius: "1.6px",
    background: "#fff",

    "&:first-child": {
      marginTop: 0,
    },

    "&.isShowDropdown": {
      "&:first-child": {
        transform: "translate(0px, 7px) rotate(44.87deg)",
      },
      "&:nth-child(2)": {
        transform: "translateX(-100px)",
        opacity: 0,
      },

      "&:last-child": {
        transform: "translateY(-3px) rotate(-44.87deg)",
      },
    },
  },
  selectLanguageWrapper: {
    "& svg": {color: theme?.palette.text.secondary},
    "& .MuiInputBase-input": {color: theme?.palette.text.secondary},
    "& fieldset": {border: "none"},
  },
  noGap: {gap: "0px", minWidth: "219px"},
  minGap: {gap: "5px"},
}));
