import * as R from "ramda";
import {getStartAndEndUTCDate} from "utils";
import {isSomething} from "submodules/capital-bee-web-ui-kit/src/utils";
import {differenceInCalendarDays} from "date-fns";

// ACCESS TOKEN VERIFICATION
export const AUTHENTICATE_TOKEN = "/accounts/user/";

// ACCOUNT REGISTRATION ENDPOINTS
export const REGISTRATION = "/accounts/register/";
export const REGISTRATION_EMAIL_VARIFICATION =
  "/accounts/register/verify-email/";
export const REGISTRATION_EMAIL_RESEND = "/accounts/register/resend-email/";
export const REGISTRATION_GOOGLE = "/accounts/social/google/";

// ACCOUNT LOGIN ENDPOINTS
export const LOGIN = "/accounts/login/";
export const LOGIN_OTP = "/accounts/otp/devices/verify/";
export const LOGIN_OTP_RESEND = "/accounts/otp/devices/resend/";
export const GET_QR_TOKEN = "/accounts/login-tokens/";
export const LOGIN_VIA_QR = (token: string) =>
  `/accounts/login-tokens/${token}/access-tokens/`;

//  PASSWORD ENDPOINTS
export const FORGOT_PASSWORD = "/accounts/password/reset/";
export const CHANGE_PASSWORD = "/accounts/password/change/";
export const RESET_PASSWORD = "/accounts/password/reset/confirm/";
export const CONFIRM_PASSWORD = "/accounts/confirm-password";

//LOGOUT ENDPOINT
export const LOGOUT = "/accounts/logout/";

// ACCOUNT SETUP ENDPOINT
export const ACCOUNT_SETUP = "/accounts/user/";

//2FA ADD DEVICE
export const ADD_2FA_DEVICE = "/accounts/otp/devices/";
export const VERIFY_2FA_Device = (id: string) =>
  `/accounts/otp/devices/${id}/verify/`;
export const EDIT_2FA_DEVICE = (id: string) => `/accounts/otp/devices/${id}`;
export const RESEND_OTP_TO_OTP_DEVICE = (id: string) =>
  `/accounts/otp/devices/${id}/resend/`;

// USER'S ADDED WALLETS
export const USER_WALLETS = (filter = "") =>
  `/accounts/wallets/${filter}&source=user`;
export const USER_WALLETS_SUMMARY = `/accounts/wallets/stats/`;
// AVAILABLE WALLETS
export const AVAILABLE_WALLETS = "/wallets/";

// ADD NEW WALLET
export const ADD_NEW_WALLET = "/accounts/wallets/";

// SEARCH WALLET
export const SEARCH_WALLETS = (searchString: string) =>
  `/accounts/wallets/?search=${searchString}&source=user`;

// TRANSACTION TAGS
export const TRANSACTION_TAGS = "/meta/transactions/tags/";

// TRANSACTION CURRENCIES
export const TRANSACTION_CURRENCIES = (type: string) =>
  `/meta/transactions/currencies/?type=${type}&ordering=rank`;

export const DELETE_WALLET = (id: string) => `/accounts/wallets/${id}`;

export const DELETE_BULK_WALLET = (walletsIdList: string[]) => {
  const formattedWalletsIdList = R.join(",", walletsIdList);
  return `/accounts/wallets/?id__in=${formattedWalletsIdList}`;
};

export const GET_TRANSACTIONS = (
  pageNo = 1,
  filters = "",
  rowsPerpage = 10,
) => {
  return `/accounts/transactions/?page=${pageNo}${filters}&_page_size=${rowsPerpage}`;
};

export const SYNC_WALLET = (id: string) => `/accounts/wallets/${id}/sync/`;

export const SYNC_BULK_WALLET = (walletsIdList: string[]) => {
  const formattedWalletsIdList = R.join(",", walletsIdList);
  return `/accounts/wallets/sync/?id__in=${formattedWalletsIdList}`;
};

export const DELETE_TRANSACTION = (id: string) =>
  `/accounts/transactions/${id}`;

export const DELETE_BULK_TRANSACTION = (transactionsIdList: string[]) => {
  const formattedTransactionsIdList = R.join(",", transactionsIdList);
  return `/accounts/transactions/?id__in=${formattedTransactionsIdList}`;
};

export const UPDATE_BULK_TRANSACTION = (transactionsIdList: string[]) => {
  const formattedTransactionsIdList = R.join(",", transactionsIdList);
  return `/accounts/transactions/?id__in=${formattedTransactionsIdList}`;
};

export const SEARCH_CURRENCIES_LIST = (
  currencyType: string,
  searchString: string,
  pageParam: number = 1,
) =>
  `/meta/transactions/currencies/?type=${currencyType}&search=${searchString}&page=${pageParam}`;

export const FETCH_TRANSACTION_WRT_ID = (id: string | number) =>
  `/accounts/transactions/${id}`;

export const FETCH_EXCHANGE_RATE = (
  currencyCode: string,
  timeStamp: string,
) => {
  return `/meta/fiat_value/${currencyCode}/${timeStamp}`;
};

// ADD NEW TRANSACTION
export const ADD_NEW_TRANSACTION = "/accounts/transactions/";

export const META_DATA = "/meta/";

export const PAYMNENT_PLANS = "/payments/products/";
export const PAYMENT_PLANS_COMPARISON = "/payments/products/feature/comparison";
export const FEATURE_VOTE = "/payments/products/feature/vote";
export const LOGIN_DEVICES = "/history/login/";

export const ATTATCH_PAYMENT_METHOD = (payment_method_id: string) =>
  `/payments/payment_methods/${payment_method_id}/attach/`;

export const SUBSCRIBE_PRODUCT = (productID: string, priceID: string) =>
  `/payments/products/${productID}/${priceID}/subscribe/`;
export const INVOICES_DETAILS = "/payments/invoices/";

export const PAYMENT_SUBSCRIPTON = "/payments/subscriptions/";

// USER SETTINGS TAX RULES
export const COST_BASIS = "/meta/cost/basis/";
export const COST_TRACKING = "/meta/cost/tracking/";

// TAX REPORT
export const TAX_YEARS = "/meta/tax/years/";

export const TAX_CALCULATION_OVERVIEW = (year: string | number) =>
  `/tax/summaries/?year__id=${year}`;

export const TAX_CALCULATION_STATUS = `/accounts/user/status`;

export const TAX_CALCULATION_SYNC_WITH_YEAR = (yearId: string | number) =>
  `/tax/summaries/${yearId}/sync/`;

export const TAX_CALCULATION_SYNC = `/tax/summaries/sync/`;

export const TAX_REPORT_INFO_BY_TAX_YEAR = (yearId: string | number) =>
  `/tax/report/${yearId}`;

export const TAX_REPORT_DOWNLOAD_BY_FILE_ID = (fileId: string | number) =>
  `/tax/report/download/${fileId}`;

// TRANSACTIONS CSV IMPORT
export const TRANSACTION_CSV_IMPORT = (walletID: string | number) =>
  `/accounts/transactions/imports/${walletID}/`;

//DASHBOARD
export const PORTFOLIO_VALUE = "/dashboards/portfolio-value";
export const TOTAL_ASSETS = "/dashboards/total-assets";
export const UNREALIZED_GAINS_OVERALL_VALUE =
  "/dashboards/unrealized-gains-value";
export const DEACTIVATE_ACCOUNT = "/accounts/deactivate/";

export const PROFIT_LOSS_HISTORY = (
  activeFilter: {
    name: string;
    value: string;
  },
  dateRange: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  if (dateRange) {
    const {startDate, endDate} = dateRange;
    const numberOfDays = differenceInCalendarDays(
      new Date(endDate),
      new Date(startDate),
    );

    const getRangeFilter = () => {
      if (R.lte(numberOfDays, 31)) return "MONTH";
      if (R.lte(numberOfDays, 180)) return "SIX_MONTH";
      return "YEAR";
    };

    return `/dashboards/profit-loss-history?start_date=${startDate}&end_date=${endDate}&filter=${getRangeFilter()}`;
  }
  const {startDateUtc, endDateUtc} = getStartAndEndUTCDate(activeFilter);
  return `/dashboards/profit-loss-history?start_date=${startDateUtc}&end_date=${endDateUtc}&filter=${activeFilter.value}`;
};
// DELETE OTP DEVICE
export const DELETE_OTP_DEVICE = (deviceID: string) =>
  `/accounts/otp/devices/${deviceID}`;

export const TOTAL_TRANSACTIONS_COUNT = (
  activeFilter: {
    name: string;
    value: string;
  },
  dateRange?: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  if (dateRange) {
    const {startDate, endDate} = dateRange;
    return `/dashboards/total-transactions-count?start_date=${startDate}&end_date=${endDate}`;
  }
  const {startDateUtc, endDateUtc} = getStartAndEndUTCDate(activeFilter);
  return `/dashboards/total-transactions-count?start_date=${startDateUtc}&end_date=${endDateUtc}`;
};

export const BEST_WORST_TRADES = (
  activeFilter: {
    name: string;
    value: string;
  },
  dateRange: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  if (dateRange) {
    const {startDate, endDate} = dateRange;
    return `/dashboards/best-worst-trades?start_date=${startDate}&end_date=${endDate}`;
  }
  const {startDateUtc, endDateUtc} = getStartAndEndUTCDate(activeFilter);
  return `/dashboards/best-worst-trades?start_date=${startDateUtc}&end_date=${endDateUtc}`;
};

export const TOTAL_ASSETS_HISTORY = (
  currenciesArray: string[],
  activeFilter: {
    name: string;
    value: string;
  },
  dateRange: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  let currenciesString = "";
  R.map((currencySymbol: string) => {
    currenciesString = currenciesString + "&asset_id=" + currencySymbol;
  }, currenciesArray);

  if (dateRange) {
    const {startDate, endDate} = dateRange;
    return `/dashboards/total-assets-history?start_date=${startDate}&end_date=${endDate}${currenciesString}`;
  }
  const {startDateUtc, endDateUtc} = getStartAndEndUTCDate(activeFilter);
  // return "dashboards/total-assets-history?start_date=2022-05-10T14:34:46&end_date=2022-05-11T14:34:46&symbol=ETH&symbol=BNB&symbol=MATIC";
  return `/dashboards/total-assets-history?start_date=${startDateUtc}&end_date=${endDateUtc}${currenciesString}`;
};

export const FETCH_RANK_AND_PERCENTILE = (residence = "DEU") =>
  `/dashboards/percentile-rank?residence=${residence}`;

export const FETCH_PERFORMANCE_CHART_DATA = (residence = "DEU") =>
  `/dashboards/percentile-performance?residence=${residence}`;
// WATCHLIST
export const ASSET_PRICES = (
  currency: string,
  page: string,
  pageSize: string | number,
) =>
  `/watchlists/all-assets?currency=${currency}&page=${page}&page_size=${pageSize}`;

export const WATCHLIST_CURRENCY = "/watchlists/user-assets";

export const REMOVE_WATCHLIST_CURRENCY = (currencies: string) =>
  `/watchlists/user-assets?${currencies}`;

export const WATCHLIST_CURRENCY_LIST = (
  currency: string,
  page: string,
  pageSize: string | number,
) =>
  `/watchlists/user-assets?currency=${currency}&page=${page}&page_size=${pageSize}`;

export const TOTAL_GAIN_VALUE = (
  activeFilter: {
    name: string;
    value: string;
  },
  dateRange?: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  if (dateRange) {
    const {startDate, endDate} = dateRange;
    return `/dashboards/gains-value?start_date=${startDate}&end_date=${endDate}`;
  }
  const {startDateUtc, endDateUtc} = getStartAndEndUTCDate(activeFilter);
  return `/dashboards/gains-value?start_date=${startDateUtc}&end_date=${endDateUtc}`;
};

export const SUCCESS_RATE = (
  activeFilter: {
    name: string;
    value: string;
  },
  dateRange: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  if (dateRange) {
    const {startDate, endDate} = dateRange;
    return `/dashboards/success-rate?start_date=${startDate}&end_date=${endDate}`;
  }
  const {startDateUtc, endDateUtc} = getStartAndEndUTCDate(activeFilter);
  return `/dashboards/success-rate?start_date=${startDateUtc}&end_date=${endDateUtc}`;
};

export const TOTAL_TRADE_TRANSACTIONS_DATA = (
  activeFilter: {
    name: string;
    value: string;
  },
  dateRange: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  if (dateRange) {
    const {startDate, endDate} = dateRange;
    return `/dashboards/total-trades-transactions?start_date=${startDate}&end_date=${endDate}`;
  }
  const {startDateUtc, endDateUtc} = getStartAndEndUTCDate(activeFilter);
  return `/dashboards/total-trades-transactions?start_date=${startDateUtc}&end_date=${endDateUtc}`;
};

export const COIN_INFO = (currency: string, symbol: string) =>
  `/watchlists/coin-info?currency=${currency}&asset_id=${symbol}`;

export const COIN_PRICE_HISTORY = (
  coinSymbol: string,
  currencyCode: string,
  date: {startDate: string; endDate: string} | null,
  activeFilter: {
    name: string;
    value: string;
  },
) => {
  const capitalCurrencyCode = R.toUpper(currencyCode);
  if (date) {
    const startDate = R.pathOr("", ["startDate"], date);
    const endDate = R.pathOr("", ["endDate"], date);
    return `/watchlists/price-history?asset_id=${coinSymbol}&currency=${capitalCurrencyCode}&start_date=${startDate}&end_date=${endDate}`;
  }
  const {startDateUtc, endDateUtc} = getStartAndEndUTCDate(activeFilter);
  return `/watchlists/price-history?asset_id=${coinSymbol}&currency=${capitalCurrencyCode}&start_date=${startDateUtc}&end_date=${endDateUtc}`;
};

export const COIN_PEER_GROUP_HISTORY = (
  coinSymbol: string,
  currencyCode: string,
) => {
  const capitalCurrencyCode = R.toUpper(currencyCode);
  const {startDateUtc, endDateUtc} = getStartAndEndUTCDate({
    name: "7D",
    value: "7D",
  });
  return `/watchlists/peer-groups?asset_id=${coinSymbol}&currency=${capitalCurrencyCode}&start_date=${startDateUtc}&end_date=${endDateUtc}`;
};
export const COIN_NEWS = (coin: string, limit: number) =>
  `/watchlists/coin-news?asset_id=${coin}&limit=${limit}`;

export const TOP_TRADERS = (
  activeFilter: {name: string; value: string},
  isViewByLocallyActive: boolean,
  taxResidentCountry: string,
  dateRange: {
    startDate: string;
    endDate: string;
  } | null,
) => {
  if (dateRange) {
    const {startDate, endDate} = dateRange;
    return `/dashboards/top-traders?start_date=${startDate}&end_date=${endDate}${
      isViewByLocallyActive ? `&residence=${taxResidentCountry}` : ""
    }`;
  }
  const {startDateUtc, endDateUtc} = getStartAndEndUTCDate(activeFilter);
  return `/dashboards/top-traders?start_date=${startDateUtc}&end_date=${endDateUtc}${
    isViewByLocallyActive ? `&residence=${taxResidentCountry}` : ""
  }`;
};

export const COIN_MARKET_CAP_HISTORY = (
  coinSymbol: string,
  currencyCode: string,
  date: {startDate: string; endDate: string} | null,
  activeFilter: {
    name: string;
    value: string;
  },
) => {
  const capitalCurrencyCode = R.toUpper(currencyCode);
  if (date) {
    const startDate = R.pathOr("", ["startDate"], date);
    const endDate = R.pathOr("", ["endDate"], date);
    return `/watchlists/market-cap-history?asset_id=${coinSymbol}&currency=${capitalCurrencyCode}&start_date=${startDate}&end_date=${endDate}`;
  }
  const {startDateUtc, endDateUtc} = getStartAndEndUTCDate(activeFilter);
  return `/watchlists/market-cap-history?asset_id=${coinSymbol}&currency=${capitalCurrencyCode}&start_date=${startDateUtc}&end_date=${endDateUtc}`;
};
export const SEARCH_COINS_WATCHLIST = (searchString: string) =>
  `/meta/transactions/currencies/?type=crypto&search=${searchString}`;
// currencies is a string with format symbol=BTC&symbol=ADA...
export const SEARCH_ASSET_PRICES = (
  currencies: string,
  currencyCode: string,
  pageNumber: number,
) =>
  `/watchlists/all-assets?currency=${currencyCode.toUpperCase()}&${
    isSomething(currencies) ? currencies : `page=${pageNumber}`
  }`;
export const SEARCH_WATCHLIST = (
  currencies: string,
  currencyCode: string,
  pageNumber: number,
) =>
  `/watchlists/user-assets?currency=${currencyCode.toUpperCase()}&${
    isSomething(currencies) ? currencies : `page=${pageNumber}`
  }`;

export const FETCH_ADMIN_USERS = (pageNumber: number, rowsPerpage?: number) =>
  `/admin_panel/users/?page=${pageNumber}&_page_size=${rowsPerpage}`;

export const FETCH_ROLES = (pageNum: number, rowsPerpage?: number) =>
  `/admin_panel/roles/?page=${pageNum}&_page_size=${rowsPerpage}`;

export const ADD_NEW_ROLE = (id: string | number | null) => {
  return `/admin_panel/roles/${id ? id : ""}`;
};

export const FETCH_PERMISSIONS = `/admin_panel/permissions/`;

export const DELETE_ROLE = (id: string | number | null) => {
  return `/admin_panel/roles/${id ? id : ""}`;
};
export const ADMIN_SUBSCRIPTIONS = (pageNum: number, rowsPerpage?: number) =>
  `/admin_panel/subscribers/?page=${pageNum}&_page_size=${rowsPerpage}`;

export const ADD_OR_EDIT_USER = (id: string | number | null) => {
  return `/admin_panel/users/${id ? id : ""}`;
};

export const DELETE_USER = (id: string | number | null) => {
  return `/admin_panel/users/${id ? id : ""}`;
};
export const GET_USERS_COUNT = (id: string | number) =>
  `/admin_panel/users/?role_id=${id}`;
export const FETCH_BLOCKING_REASONS = `/meta/blocking_reasons/`;
export const BLOCK_USER = `/admin_panel/block_user/`;
export const UN_BLOCK_USER = `/admin_panel/unblock_user/`;

export const FETCH_SUPPORTED_COUNTRIES = `/admin_panel/countries/`;
export const FETCH_SUPPORTED_CURRENCIES = `/admin_panel/currencies/`;
export const FETCH_SUPPORTED_LANGUAGES = `/admin_panel/languages/`;
export const FETCH_VERIFICATION_METHODS = `/admin_panel/verification_methods/`;
export const SEARCH_ROLES = (searchString: string) =>
  `/admin_panel/roles/?search=${searchString}`;
export const SEARCH_SUBSCRIPTIONS = (searchString: string) =>
  `/admin_panel/subscribers/?search=${searchString}`;
export const SEARCH_USERS = (searchString: string) =>
  `/admin_panel/users/?search=${searchString}`;

export const FETCH_TAX_RULES = (pageNum = 1) =>
  `/admin_panel/tax_residence_rules/?page=${pageNum}`;

export const FETCH_ASSET_HOLDING_TABULAR_DATA = `/dashboards/total-holding-assets`;

export const FETCH_ASSET_HOLDING_HISTORY_DATA = () => {
  return `/dashboards/total-holding-assets-history`;
};
export const FETCH_TAX_SUMMARIES = (taxYearID: number | string) =>
  `/tax/summaries/${taxYearID}`;

export const EDIT_LOSS_CARRIED_FWD = (taxYearID: number) =>
  `/tax/summaries/${taxYearID}`;

export const FETCH_TOTAL_TRANSACTIONS_PER_YEAR =
  "/dashboards/total-transactions-count-per-year";

export const FETCH_TRANSACTION_COUNT_YEARLY = (taxYear: string) =>
  `/dashboards/total-transactions-count?start_date=${taxYear}-01-01T00:00:00Z&end_date=${taxYear}-12-31T23:59:00Z`;

export const TOTAL_PORTFOLIO_VALUE = (taxYearID: number | string) =>
  `/dashboards/portfolio-value?tax_year=${taxYearID}`;

export const PAYMENT_METHODS = "/payments/payment_methods/";

export const DELETE_PAYMENT_METHOD = (id: string) =>
  `/payments/payment_methods/${id}`;

export const MAKE_DEFAULT_PAYMENT_METHOD = (id: string) =>
  `/payments/payment_methods/${id}/default/`;

export const UPDATE_BILLING_DETAILS = (id: string) =>
  `/payments/payment_methods/${id}`;

export const PAYMENT_TAX_YEARS = "/payments/tax/years/";
export const PAYMENT_TAX_YEARS_PRODUCT = "/payments/tax/years/product/";
export const GENERATE_INVOICE = (
  productID: string,
  priceID: string,
  taxYearID: string,
) =>
  `/payments/products/${productID}/${priceID}${
    !!taxYearID ? "/" : ""
  }${taxYearID}/subscribe/`;

export const GET_INVOICE = (invoiceID: string) =>
  `/payments/invoices/${invoiceID}`;

export const CANCEL_PLAN_SUBSCRIPTION = (id: string) =>
  `/payments/subscriptions/${id}/cancel/`;

export const PAY_FOR_PLAN = (invoiceID: string) =>
  `/payments/invoices/${invoiceID}/pay/`;
