import {Trans} from "react-i18next";

export const USER_ADDED = <Trans i18nKey="user_added_toast" />;
export const USER_EDITED = <Trans i18nKey="user_updated_toast" />;
export const USER_DELETED = <Trans i18nKey="user_deleted_toast" />;
export const ROLE_ADDED = <Trans i18nKey="role_added_toast" />;
export const ROLE_EDITED = <Trans i18nKey="role_updated_toast" />;
export const ROLE_DELETED = <Trans i18nKey="role_deleted_toast" />;
export const MANUAL_WALLET_ADDED = (
  <Trans i18nKey="manual_wallet_added_toast" />
);
export const CVS_IMPORT_SUCCESS = <Trans i18nKey="csv_import_success_toast" />;
export const WALLET_REMOVED_SUCCESS = (
  <Trans i18nKey="wallet_remove_success_toast" />
);
export const BULK_WALLET_REMOVED_SUCCESS = (
  <Trans i18nKey="bulk_wallet_remove_success_toast" />
);
export const TRANSACTION_ADDEDD_SUCCESS = (
  <Trans i18nKey="transaction_added_success_toast" />
);
export const TRANSACTION_DELETED_SYNC = (
  <Trans i18nKey="transaction_deleted_resync_toast" />
);
export const WALLET_SYNC_STATUS = <Trans i18nKey="wallet_sync_status" />;

export const TAX_CAL_IN_PROGRESS_WALLETS_MSG = (
  <Trans i18nKey="tax_cal_in_sync_msg" />
);

export const WALLET_IMPORT_MSG = <Trans i18nKey={"wallet_import_toast_msg"} />;
