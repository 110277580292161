//lib
import * as R from "ramda";
import {useState} from "react";
import {Box, Divider, Grid, Modal} from "@mui/material";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import {
  useElements,
  useStripe,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import {useDispatch, useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

//src
import {isSomething} from "../../../../../../utils";
import {TitleStyled} from "../../../../../../styles/common";
import {CustomButton} from "../../../../../../submodules/capital-bee-web-ui-kit/src/components/CustomButton";

//styles
import {useSettingsStyles} from "../../../../settings.styles";
import RenderplanCard from "./components/render-plan-cards";
import RenderSubscription from "./components/render-subscription";
import {endpoints, queryConstants} from "../../../../../../constants";
import {customAxioCall} from "../../../../../../services/api";
import {setLoadingState, setToaster} from "../../../../../../store/ui";
import {RootState} from "../../../../../../store";
import {useQueryClient} from "react-query";

export type planFeaturesType = {
  cpa_advice: string;
  num_wallets: string;
  portfolio_plan: string;
  num_transactions: string;
};

type planType = {
  id: string;
  name: string;
  description: string;
  type: string;
  active: boolean;
  created: string;
  metadata: planFeaturesType;
}[];

type offeredPlansModalTypes = {
  handleClose: any;
  productPlans: planType;
};

const OfferedPlansModal = (props: offeredPlansModalTypes) => {
  const classes = useSettingsStyles();
  const {handleClose, productPlans} = props;
  const activePlan = R.find(R.propEq("active", true))(productPlans);
  const [selectedPlan, setSelectedPlan] = useState(activePlan);
  const [currentStep, setCurrentStep] = useState("plans");
  const elements = useElements();
  const stripe = useStripe();
  const dispatch = useDispatch();
  const {user} = useSelector((state: RootState) => state.auth);
  const {t} = useTranslation();

  const userFirstName = R.pathOr("", ["first_name"], user);
  const userLastName = R.pathOr("", ["last_name"], user);
  const email = R.pathOr("", ["email"], user);
  const queryClient = useQueryClient();
  const handleProceedToPay = () => {
    console.log(selectedPlan);
    setCurrentStep("payment");
  };

  const isCurrentSelectedPlanSubscribed = R.pathOr(
    false,
    ["is_subscribed"],
    selectedPlan,
  );

  const handleCardSubmit = async (e: any) => {
    console.log("submit called:::");
    e.preventDefault();

    if (!stripe || !elements) return;
    dispatch(setLoadingState(true));

    const cardNumberElement = elements?.getElement(CardNumberElement);
    if (cardNumberElement) {
      // create payment method
      const {error, paymentMethod} = await stripe.createPaymentMethod({
        type: "card",
        card: cardNumberElement,
        billing_details: {
          name: userFirstName + " " + userLastName,
          email: email,
        },
      });

      if (error) {
        // handle error state
        console.log("Error occured during payment method creation:", error);
        const errorMessage = R.pathOr("", ["message"], error);
        dispatch(setLoadingState(false));
        if (isSomething(error)) {
          dispatch(
            setToaster({
              message: errorMessage,
              type: "error",
            }),
          );
        }

        return;
      }

      // attach payment method to user via backend api
      const paymentMethodID = R.pathOr("", ["id"], paymentMethod);

      const {error: attatchError, response: attatchPaymentResponse} =
        await customAxioCall({
          url: `${endpoints.ATTATCH_PAYMENT_METHOD(paymentMethodID)}`,
          dispatch,
        });

      if (attatchError) {
        dispatch(setLoadingState(false));
        console.log("attatchError::", attatchError);
        return;
      }

      console.log("attatchPaymentResponse:", attatchPaymentResponse);
      // subscribe product

      const getProductData = (selectedProductData: any) => {
        const productId = R.pathOr("", ["id"], selectedProductData);
        const priceId = R.pipe(
          R.pathOr([], ["prices"]),
          R.find(R.propEq("active", true)),
          R.pathOr("", ["id"]),
        )(selectedProductData);

        return {productId, priceId};
      };

      const {priceId, productId} = getProductData(selectedPlan);

      const subscribeProductUrl = endpoints.SUBSCRIBE_PRODUCT(
        productId,
        priceId,
      );

      const {error: subscribeError, response: subscribeResponse} =
        await customAxioCall({
          url: `${subscribeProductUrl}`,
          dispatch,
        });

      if (subscribeError) {
        dispatch(
          setToaster({
            message: "Error occured during subscription process",
            type: "error",
          }),
        );
        dispatch(setLoadingState(false));
        console.log("subscribeError::", subscribeError);
        return;
      }
      queryClient.invalidateQueries(queryConstants.INVOICES_DETAILS);
      queryClient.invalidateQueries(queryConstants.PAYMENT_PLANS);
      dispatch(setLoadingState(false));
      handleClose();

      console.log("subscribeProductResponse:", subscribeResponse);
    }
  };

  return (
    <Modal open={isSomething(productPlans)} onClose={handleClose}>
      <Box className={classes.offeredPlansModalWrapper}>
        <TitleStyled size="md" bold>
          {!R.equals(currentStep, "plans") && (
            <CustomButton
              text={""}
              variant="info"
              startIcon={<ArrowBackSharpIcon />}
              onClick={() => setCurrentStep("plans")}
              sx={{minWidth: "auto"}}
            />
          )}
          {t("upgrade_tax_plan")}
        </TitleStyled>
        <Divider />
        {R.equals(currentStep, "plans") && (
          <>
            <Grid container spacing={3} className={classes.paymentGridWrapper}>
              {productPlans.map((plan, key) => (
                <RenderplanCard
                  plan={plan}
                  key={key}
                  planSelectedByUser={selectedPlan}
                  setSelectedPlan={setSelectedPlan}
                />
              ))}
            </Grid>
            <Box className={classes.buttonWrap}>
              <CustomButton
                text={t("proceed_to_pay")}
                onClick={handleProceedToPay}
                disabled={isCurrentSelectedPlanSubscribed}
              />
              <CustomButton
                text={t("cancel")}
                variant="outlined"
                onClick={handleClose}
              />
            </Box>
          </>
        )}
        {R.equals(currentStep, "payment") && (
          <RenderSubscription
            handleCardSubmit={handleCardSubmit}
            handleClose={handleClose}
            classes={classes}
          />
        )}
      </Box>
    </Modal>
  );
};

export default OfferedPlansModal;
