import {makeStyles} from "@mui/styles";

export const useCancelPaymentModalStyles = makeStyles((theme: any) => ({
  removeModalWrapper: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "480px",
    backgroundColor: theme.palette.common.white,
    padding: "32px",
    borderRadius: "10px",
  },
  mobileViewWrapper: {width: "90%", maxWidth: 400},
  buttonWrap: {
    display: "flex",
    columnGap: "15px",
    marginTop: "40px",
    marginBottom: "10px",
  },
}));
