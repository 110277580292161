// lib
import { combineReducers } from "@reduxjs/toolkit";

// src
import auth from "store/auth";
import ui from "store/ui/index";
import app from "store/app/index";

const rootReducer = combineReducers({
  auth,
  ui,
  app,
});

export default rootReducer;
