//lib
import * as R from "ramda";
import classNames from "classnames";
import {Box, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";

//src
import {
  DescriptionStyled,
  TitleStyled,
} from "../../../../../../../styles/common";
import TickIcon from "../../../../../../../assets/svgs/TickIcon";
import TickFilledSuccess from "../../../../../../../assets/svgs/TickFilledSuccess";

//styles
import {planFeaturesType} from "../offered-plans-modal";
import {useSettingsStyles} from "../../../../../settings.styles";
import {getFormattedCurrency, isSomething, mapIndexed} from "utils";
import CustomChip from "../../../../../../../components/custom-chip/custom-chip";

type RenderFeaturesType = {
  planFeatures: planFeaturesType | null;
  isBlueTicksPlan?: boolean;
};

export const RenderFeatures = (props: RenderFeaturesType) => {
  const {planFeatures, isBlueTicksPlan = true} = props;
  const classes = useSettingsStyles();
  const {t} = useTranslation();

  const wallets_limit = R.pathOr("", ["num_wallets"], planFeatures);
  const transactions_limit = R.pathOr("", ["num_transactions"], planFeatures);
  const feature1 = R.pathOr("", ["feature_1"], planFeatures);
  const feature2 = R.pathOr("", ["feature_2"], planFeatures);
  const feature3 = R.pathOr("", ["feature_3"], planFeatures);
  const feature4 = R.pathOr("", ["feature_4"], planFeatures);
  const feature5 = R.pathOr("", ["feature_5"], planFeatures);

  const featuresArray = [
    feature1,
    "num_transactions",
    "num_wallets",
    feature2,
    feature3,
    feature4,
    feature5,
  ];

  const setDynamicFeatureTranslations = (
    index: number,
    featureText: string,
  ) => {
    if (index === 1)
      return t(featureText, {num_transactions: transactions_limit});
    if (index === 2) return t(featureText, {num_wallets: wallets_limit});
    return t(featureText);
  };

  return (
    <Box className={classes.plansFeaturesContainer} id="plans-features">
      {mapIndexed((featureText: any, index: number) => {
        if (!isSomething(featureText)) return "";
        return (
          <Box
            className={classNames({
              [classes.paymentWrapper]: true,
              [classes.blueTick]: isBlueTicksPlan,
            })}
          >
            <TickIcon size="14px" />
            <DescriptionStyled
              size="md"
              color="secondary"
              id={`feature-${index + 1}`}
              align={"left"}
            >
              {/*{`${featureText}`}*/}
              {setDynamicFeatureTranslations(index, featureText)}
            </DescriptionStyled>
          </Box>
        );
      }, featuresArray)}
    </Box>
  );
};

const RenderplanCard = ({plan, setSelectedPlan, planSelectedByUser}: any) => {
  const classes = useSettingsStyles();
  const {t} = useTranslation();
  const isUserPlanActive = R.equals(
    R.pathOr(false, ["id"], planSelectedByUser),
    R.pathOr(false, ["id"], plan),
  );
  const planName = R.pathOr("", ["name"], plan);
  const plansFeatures: planFeaturesType | null = R.pathOr(
    null,
    ["metadata"],
    plan,
  );

  const pricesArray = R.pipe(
    R.propOr({}, "prices"),
    R.filter((x: any) => R.propEq("active", true, x)),
  )(plan);
  const paymentInterval = R.pathOr(
    "",
    ["0", "recurring", "interval"],
    pricesArray,
  );
  const price = R.pipe(
    R.pathOr(0, ["0", "unit_amount"]),
    R.divide(R.__, 100),
  )(pricesArray);

  const currency = R.pathOr(0, ["0", "currency"], pricesArray);

  const isSubscribed = R.pathOr(false, ["is_subscribed"], plan);

  return (
    <Grid item xs={6}>
      <Box
        className={classNames({
          [classes.planWrapper]: true,
          [classes.activePlan]: isUserPlanActive,
        })}
        onClick={() => setSelectedPlan(plan)}
      >
        <Box className={classes.paymentWrapper}>
          <DescriptionStyled size="lg" noPadding>
            {planName}
          </DescriptionStyled>
          {isSubscribed && (
            <Box className={classes.palnSubscribedChip}>
              <CustomChip
                key={planName}
                label={t("subscribed")}
                color="success"
                variant="filled"
              />
            </Box>
          )}
          {isUserPlanActive && <TickFilledSuccess />}
        </Box>

        <Box className={classes.paymentWrapper}>
          <TitleStyled size="md" bold noPadding>
            <span className={classes.currency}>{currency}</span>
            {` ${getFormattedCurrency(price)}`}
          </TitleStyled>
          <DescriptionStyled size="md" color="secondary">
            {`/${paymentInterval}`}
          </DescriptionStyled>
        </Box>

        <RenderFeatures planFeatures={plansFeatures} />
      </Box>
    </Grid>
  );
};

export default RenderplanCard;
