//lib

import * as R from "ramda";
import {styled} from "@mui/material/styles";
import {Chip, ChipProps} from "@mui/material";

//src
import {appThemeConfigs} from "theme/theme";

interface CustomChipProps {
  theme?: typeof appThemeConfigs;
  variant: "filled" | "outlined";
  color:
    | "primary"
    | "secondary"
    | "error"
    | "success"
    | "warning"
    | "default"
    | "info";
  bold?: boolean;
  capitalized?: boolean;
  isRoundedChip?: boolean;
  customDefaultTextColor?: string;
  textColor?: string;
  customDefaultBgColor?: string;
  id?: string;
  isThinChip?: boolean;
  maxWidth?: string;
}

const CustomChip = styled(Chip)((props: ChipProps & CustomChipProps) => {
  const {
    bold = false,
    theme,
    capitalized = false,
    isRoundedChip = false,
    customDefaultTextColor,
    customDefaultBgColor,
    id,
    isThinChip = false,
    maxWidth,
  } = props;
  const isOutlined = R.pathEq(["variant"], "outlined", props);

  return {
    id: id,
    borderRadius: isRoundedChip ? "12px" : "5px",
    padding: isRoundedChip ? "6px 4px" : "unset",
    fontWeight: R.equals(bold, true) ? "bold" : "normal",
    ...(capitalized ? {textTransform: "capitalize"} : {}),
    border: "none",
    height: isThinChip ? "auto" : "32px",

    "& .MuiChip-label": {
      padding: isThinChip ? "0px 3px" : "0px 12px",
      "& p": {
        fontSize: isThinChip ? "10px" : "",
        padding: isThinChip ? "0" : "5px 0px",
        maxWidth: !!maxWidth ? maxWidth : "unset",
        textOverflow: !!maxWidth ? "ellipsis" : "unset",
        whiteSpace: !!maxWidth ? "nowrap" : "unset",
        overflow: !!maxWidth ? "hidden" : "unset",
      },
    },

    // primary color
    ...(R.pathEq(["color"], "primary", props) && {
      color: isOutlined
        ? theme?.palette.info.main
        : theme?.palette.text.tertiary,

      backgroundColor: isOutlined
        ? theme?.palette.info.light
        : theme?.palette.info.main,
      "&:hover": {
        backgroundColor: isOutlined
          ? `${theme?.palette.info.light} !important`
          : `${theme?.palette.info.main} !important`,
      },
    }),

    // color success
    ...(R.pathEq(["color"], "success", props) && {
      color: isOutlined
        ? theme?.palette.success.dark
        : theme?.palette.background.paper,

      backgroundColor: isOutlined
        ? theme?.palette.success.light
        : theme?.palette.success.dark,
      "&:hover": {
        backgroundColor: isOutlined
          ? `${theme?.palette.success.light} !important`
          : `${theme?.palette.success.dark} !important`,
      },
    }),

    //  color error
    ...(R.pathEq(["color"], "error", props) && {
      color: isOutlined
        ? theme?.palette.error.main
        : theme?.palette.background.paper,

      backgroundColor: isOutlined
        ? theme?.palette.error.light
        : theme?.palette.error.main,
      "&:hover": {
        backgroundColor: isOutlined
          ? `${theme?.palette.error.light} !important`
          : `${theme?.palette.error.main} !important`,
      },
    }),

    //color secondary
    ...(R.pathEq(["color"], "secondary", props) && {
      color: isOutlined
        ? theme?.palette.text.primary
        : theme?.palette.text.secondary,
      backgroundColor: isOutlined ? theme?.palette.background.paper : "#F0F1F2",
      border: `1px solid ${theme?.palette.info.light}`,
      "&:hover": {
        backgroundColor: isOutlined
          ? `${theme?.palette.background.paper} !important`
          : "",
      },
    }),

    //color warning
    ...(R.pathEq(["color"], "warning", props) && {
      color: theme?.palette.error.dark,
      backgroundColor: theme?.palette.background.warningDark,
    }),

    //color info contains yellow color
    ...(R.pathEq(["color"], "info", props) && {
      color: theme?.palette.text.primary,
      backgroundColor: theme?.palette.buttonPrimary,
    }),

    //color default contains light gray color
    ...(R.pathEq(["color"], "default", props) && {
      color: customDefaultTextColor
        ? customDefaultTextColor
        : theme?.palette.text.secondary,
      backgroundColor: customDefaultBgColor
        ? customDefaultBgColor
        : theme?.palette.background.default,
    }),
  };
});

export default CustomChip;
