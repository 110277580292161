//lib
import * as R from "ramda";
import {useTranslation} from "react-i18next";
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useQueryClient} from "react-query";

//src

import {HeaderLogo} from "assets/svgs/HeaderLogo";
import {
  UNAUTHENTICATED_ROUTES,
  AUTHENTICATED_ROUTES,
  queryConstants,
  FREE,
} from "constants/";
import {CustomButton} from "submodules/capital-bee-web-ui-kit/src/components/CustomButton";

//styles
import {
  AppBar,
  LinksWrapper,
  NavLinkWrapper,
  ButtonWrapper,
  LogoWrapper,
  ActiveLinkWrapper,
  LinkHeightWrap,
  useHeaderStyles,
  OutsideLinkWrapper,
} from "components/header/header.styles";
// import {LockSvg} from "assets/svgs/Common";
import {
  useGetActiveProductInfo,
  useGetUserLanguage,
  useIsThisRolePermitted,
} from "utils/meta-hooks";
import CustomChip from "components/custom-chip/custom-chip";
import UserMenu from "components/header/components/user-menu";
import {useState} from "react";
import classNames from "classnames";
import {Box, MenuItem, Select} from "@mui/material";
import {AmericanFlag, GermanFlag} from "assets/svgs/flags";
import {
  getLocalStorageLanguage,
  languageTranslationFunction,
  mapIndexed,
  metaTransformerFunction,
  setLocalStorageLanguage,
} from "utils";
import {RootState} from "store";
import {useAccountSetup} from "services/api/settings";
import {setToaster, setHasLanguageChanged} from "store/ui";

const countryFlags = {
  de: <GermanFlag />,
  en: <AmericanFlag />,
};

interface NavItemType {
  label: string;
  url: string;
  activePlanKey: string;
  noOfWalletsAllowedInCurrentPlan: string;
  noOfTransactionsAllowedInCurrentPlan: string;
}

interface HeaderType {
  isLogIn: boolean;
  user?: string;
  isAdminAppView: boolean;
  isUserTypeAdmin: boolean;
  i18n: any;
}

interface LanguageCardProps {
  countryCode: string;
  countryName: string;
}

const LanguageCard = (props: LanguageCardProps) => {
  const classes = useHeaderStyles();

  const {countryCode, countryName} = props;
  console.log("countryCode: ", countryCode);
  return (
    <Box className={classes.flexWrapper}>
      {R.propOr("", countryCode, countryFlags)}
      {`${countryName} (${R.toUpper(countryCode)})`}
    </Box>
  );
};

const NavItem = (props: NavItemType) => {
  const {
    label,
    url,
    noOfTransactionsAllowedInCurrentPlan,
    noOfWalletsAllowedInCurrentPlan,
    activePlanKey,
  } = props;
  const {t} = useTranslation();
  // const classes = useHeaderStyles();

  const limitedChipComponent = (
    <CustomChip
      variant="filled"
      color="default"
      label={t("limited")}
      size="small"
      customDefaultBgColor="#6c7780"
      customDefaultTextColor="#1b2126"
      isRoundedChip
      sx={{marginLeft: "8px"}}
      id="limited"
    />
  );
  const RenderChip = () => {
    if (R.equals(activePlanKey, FREE) && R.equals(label, "wallets")) {
      return limitedChipComponent;
    }
    if (R.equals(activePlanKey, FREE) && R.equals(label, "taxes")) {
      return limitedChipComponent;
    }
    return <></>;
  };

  // const RenderLockIcon = () => {
  //   if (R.equals(activePlanKey, FREE) && R.equals(label, "watchlist")) {
  //     return (
  //       <span className={classes.lockIconContainer}>
  //         <LockSvg />
  //       </span>
  //     );
  //   }
  //   return <></>;
  // };
  return (
    <NavLinkWrapper to={url}>
      {/* <RenderLockIcon /> */}
      {t(label)}
      <RenderChip />
    </NavLinkWrapper>
  );
};

const Header = (props: HeaderType) => {
  const {pathname} = useLocation();
  const classes = useHeaderStyles();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const {languageCode} = useGetUserLanguage();
  const contentLanguage = getLocalStorageLanguage();
  const {meta} = useSelector((state: RootState) => state.app);
  const {user} = useSelector((state: RootState) => state.auth);
  const dispatch = useDispatch();

  const {accountSetup} = useAccountSetup();

  const {languagesList} = metaTransformerFunction(meta);
  const transformedLanguagesList = languageTranslationFunction(
    languagesList,
    t,
  );

  const queryClient = useQueryClient();

  const [isShowDropdown, setIsShowDropdown] = useState(false);

  const {isLogIn, isAdminAppView, isUserTypeAdmin, i18n} = props;

  const isUserManagementPermitted = useIsThisRolePermitted("manage_users");
  const isRoleManagementPermitted = useIsThisRolePermitted("manage_roles");
  const isManagementPermitted =
    isUserManagementPermitted || isRoleManagementPermitted;
  const isSubscriptionPermitted = useIsThisRolePermitted("manage_subscribers");
  const isViewSettingPermitted = useIsThisRolePermitted("view_settings");

  const activeProduct = useGetActiveProductInfo();

  const activePlanKey: string = R.propOr("", "productKey", activeProduct);
  const noOfWalletsAllowedInCurrentPlan: string = R.propOr(
    "",
    "maxWalletsSupport",
    activeProduct,
  );
  const noOfTransactionsAllowedInCurrentPlan: string = R.propOr(
    "",
    "maxTransactionsSupport",
    activeProduct,
  );

  const ADMIN_APP_ROUTES = [
    ...(isManagementPermitted
      ? [{label: t("management"), url: "/admin/management"}]
      : []),
    ...(isSubscriptionPermitted
      ? [{label: t("subscribers"), url: "/admin/subscriptions"}]
      : []),
    ...(isViewSettingPermitted
      ? [{label: t("settings"), url: "/admin/settings"}]
      : []),
  ];

  const navigationLink = isAdminAppView
    ? ADMIN_APP_ROUTES
    : AUTHENTICATED_ROUTES;

  const handleClick = () => {
    if (!isLogIn) {
      if (pathname === "/login") {
        navigate("/");
      } else {
        navigate("/login");
      }
    }
  };

  const toggle = () => {
    return setIsShowDropdown((prevState: boolean) => !prevState);
  };

  const getLanguageName = (code: string): string =>
    //@ts-ignore
    R.pipe(
      R.filter(R.propEq("value", code)),
      R.head,
      R.propOr("", "name"),
    )(languagesList);

  const languageChangeToaster = (code: string) => {
    setTimeout(() => {
      dispatch(
        setToaster({
          type: "success",
          message: t("language_changed_toast", {
            language: getLanguageName(code),
          }),
        }),
      );
    }, 2000);
  };

  const languagesListSelect = mapIndexed((country, index) => {
    const code: string = R.propOr("", "value", country);
    return (
      <MenuItem value={code} key={index}>
        <LanguageCard
          countryCode={code}
          countryName={R.propOr("", "name", country)}
        />
      </MenuItem>
    );
  }, transformedLanguagesList);

  const handleLanguageChange = (e: any) => {
    const languageCode = e.target.value;
    if (!!user?.profile)
      accountSetup(
        {
          ...user,
          profile: {...user?.profile, language: languageCode},
        },
        {
          onSuccess: () => {
            languageChangeToaster(languageCode);
            dispatch(setHasLanguageChanged(true));
            setLocalStorageLanguage(languageCode);
            queryClient.invalidateQueries(queryConstants.GET_USER_INFO);
          },
        },
      );
    else {
      setLocalStorageLanguage(languageCode);
      i18n.changeLanguage(languageCode);
      languageChangeToaster(languageCode);
    }
  };

  const currentLangCode = !!languageCode ? languageCode : contentLanguage;

  return (
    <AppBar>
      <Box className={classes.flexWrapper}>
        <Box className={classes.menuToggleButton} onClick={toggle}>
          <span
            className={classNames({
              [classes.toggleMenuBar]: true,
              isShowDropdown: isShowDropdown,
            })}
          />
          <span
            className={classNames({
              [classes.toggleMenuBar]: true,
              isShowDropdown: isShowDropdown,
            })}
          />
          <span
            className={classNames({
              [classes.toggleMenuBar]: true,
              isShowDropdown: isShowDropdown,
            })}
          />
        </Box>

        <LogoWrapper onClick={() => navigate("/dashboard")}>
          <HeaderLogo />
        </LogoWrapper>
      </Box>
      <LinksWrapper className={isShowDropdown ? "isShowDropdown" : ""}>
        {isLogIn &&
          navigationLink.map((meta, idx) => (
            <LinkHeightWrap
              key={idx}
              isShowDropdown={isShowDropdown}
              active={R.startsWith(meta?.url, pathname) ? "active" : undefined}
              onClick={() => setIsShowDropdown(false)}
            >
              <ActiveLinkWrapper
                active={
                  R.startsWith(meta?.url, pathname) ? "active" : undefined
                }
                isShowDropdown={isShowDropdown}
              >
                <NavItem
                  {...meta}
                  activePlanKey={activePlanKey}
                  noOfWalletsAllowedInCurrentPlan={
                    noOfWalletsAllowedInCurrentPlan
                  }
                  noOfTransactionsAllowedInCurrentPlan={
                    noOfTransactionsAllowedInCurrentPlan
                  }
                />
              </ActiveLinkWrapper>
            </LinkHeightWrap>
          ))}
        {!isLogIn &&
          UNAUTHENTICATED_ROUTES.map(meta => (
            <LinkHeightWrap
              isShowDropdown={isShowDropdown}
              active={R.startsWith(meta?.url, pathname) ? "active" : undefined}
              onClick={() => setIsShowDropdown(false)}
            >
              <ActiveLinkWrapper
                active={
                  R.startsWith(meta?.url, pathname) ? "active" : undefined
                }
                isShowDropdown={isShowDropdown}
              >
                <OutsideLinkWrapper href={meta?.url} target="_blank">
                  {t(meta?.label)}
                </OutsideLinkWrapper>
              </ActiveLinkWrapper>
            </LinkHeightWrap>
          ))}
      </LinksWrapper>
      <Box
        className={classNames({
          [classes.flexWrapper]: true,
          [classes.noGap]: true,
        })}
      >
        <Select
          defaultValue={currentLangCode}
          value={currentLangCode}
          inputProps={{"aria-label": "Without label"}}
          className={classes.selectLanguageWrapper}
          renderValue={(value: any) => {
            const code = !!languageCode ? languageCode : value;
            return (
              <Box
                className={classNames({
                  [classes.flexWrapper]: true,
                  [classes.minGap]: true,
                })}
              >
                {R.propOr("", code, countryFlags)}
                {R.toUpper(code)}
              </Box>
            );
          }}
          id="lanhuage-select-header"
          onChange={handleLanguageChange}
        >
          {languagesListSelect}
        </Select>
        {!isShowDropdown && (
          <ButtonWrapper>
            {isLogIn ? (
              <UserMenu isUserTypeAdmin={isUserTypeAdmin} />
            ) : (
              <CustomButton
                text={pathname === "/login" ? t("get_started") : t("login")}
                size={"large"}
                onClick={handleClick}
                id="header-btn"
              />
            )}
          </ButtonWrapper>
        )}
      </Box>
    </AppBar>
  );
};

export default Header;
