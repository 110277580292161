import {
  clearLocalStorageToken,
  getLocalStorageLanguage,
  isSomething,
} from "utils";
import {resetAuth} from "store/auth";
import {endpoints, queryConstants} from "constants/";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {axiosCall, useClientMutation} from "services/api/";
import {useQuery} from "react-query";

//***********************************LOGIN API INTEGRATION*********************************************//

export const useLogin = () => {
  const payload = {
    url: endpoints.LOGIN,
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
    authTokenRequired: false,
  });

  return {
    accountLogin: mutate,
    ...rest,
  };
};

export const useConfirmPassword = () => {
  const payload = {
    url: endpoints.CONFIRM_PASSWORD,
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
    authTokenRequired: true,
  });

  return {
    confirmPassword: mutate,
    ...rest,
  };
};

export const useLogout = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const payload = {
    url: endpoints.LOGOUT,
    method: "post",
  };

  const handleOnSuccess = () => {
    dispatch(resetAuth());
    clearLocalStorageToken();
    navigate("/login");
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
    onSuccessActions: handleOnSuccess,
  });

  return {
    logout: mutate,
    ...rest,
  };
};

export const useGetQRToken = (polling: number) => {
  return useQuery(
    [queryConstants.GET_QR_TOKEN],
    () => {
      return axiosCall({
        url: endpoints.GET_QR_TOKEN,
        method: "post",
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
      refetchInterval: polling,
      cacheTime: 0,
    },
  );
};

export const useLoginViaQR = (polling: number, token: string) => {
  return useQuery(
    [queryConstants.LOGIN_VIA_QR, token],
    () => {
      return axiosCall({
        url: endpoints.LOGIN_VIA_QR(token),
        method: "put",
      });
    },
    {
      onSuccess: () => {},
      onError: () => {},
      refetchInterval: polling,
      enabled: isSomething(token),
      cacheTime: 0,
    },
  );
};

//*******************************REGISTRATION API INTEGRATION******************************************//

export const useGoogleRegistration = () => {
  const contentLanguage = getLocalStorageLanguage();
  const payload = {
    url: endpoints.REGISTRATION_GOOGLE,
    method: "post",
    headers: {
      "Accept-Language": contentLanguage,
    },
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    googleRegistration: mutate,
    ...rest,
  };
};

export const useRegistration = () => {
  const contentLanguage = getLocalStorageLanguage();
  const payload = {
    url: endpoints.REGISTRATION,
    method: "post",
    headers: {
      "Accept-Language": contentLanguage,
    },
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    accountRegistration: mutate,
    ...rest,
  };
};

export const useRegistrationResendEmail = () => {
  const payload = {
    url: endpoints.REGISTRATION_EMAIL_RESEND,
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    resendEmail: mutate,
    ...rest,
  };
};

export const useEmailVerification = () => {
  const payload = {
    url: endpoints.REGISTRATION_EMAIL_VARIFICATION,
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
  });

  return {
    emailVerification: mutate,
    ...rest,
  };
};

//************************************OTP INTEGRATION***************************************************//

export const useOTPVerification = (accessToken: string) => {
  const payload = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: endpoints.LOGIN_OTP,
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
    accessToken,
    authTokenRequired: false,
  });

  return {
    otpVerification: mutate,
    ...rest,
  };
};

export const useLoginResendOTP = (accessToken: string) => {
  const payload = {
    headers: {
      Authorization: `Bearer ${accessToken}`,
    },
    url: endpoints.LOGIN_OTP_RESEND,
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
    accessToken,
    authTokenRequired: false,
  });

  return {
    resendOTP: mutate,
    ...rest,
  };
};

//************************************PASSWORD INTEGRATION***************************************************//

export const useForgotPassword = () => {
  const payload = {
    url: endpoints.FORGOT_PASSWORD,
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
    authTokenRequired: false,
  });

  return {
    forgotPassword: mutate,
    ...rest,
  };
};

export const useResetPassword = () => {
  const payload = {
    url: endpoints.RESET_PASSWORD,
    method: "post",
  };

  const {mutate, ...rest} = useClientMutation({
    payload,
    uiLoading: true,
    authTokenRequired: false,
  });

  return {
    resetPassword: mutate,
    ...rest,
  };
};
