//lib
import * as R from "ramda";
import Box from "@mui/material/Box";
import classNames from "classnames";
import Menu from "@mui/material/Menu";
import {useDispatch} from "react-redux";
import mixpanel from "mixpanel-browser";
import Avatar from "@mui/material/Avatar";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";

//src
import {
  ContactTaxAdvisorIcon,
  HelpAndSupportIcon,
  InviteFriendsIcon,
  LogOutIcon,
  SettingsIcon,
  SwitchIcon,
} from "../../../assets/svgs/icons";
import {useStyles} from "./user-menu.styles";
import {useLogout} from "../../../services/api/auth";
import CustomChip from "../../custom-chip/custom-chip";
import {DescriptionStyled} from "../../../styles/common";
import {useGetActiveProductInfo} from "utils/meta-hooks";
import {setAppView} from "../../../store/auth/auth-slice";
import {storageKey, FREE, PRO, ADVANCED, BEGINNER} from "constants/";
import {planIconsMap} from "screens/settings/components/plan-and-payment/components/payment-plans";
import {CustomButton} from "../../../submodules/capital-bee-web-ui-kit/src/components/CustomButton";

const taxPackages = [BEGINNER, ADVANCED, PRO];
interface UserMenuUserMenuDropdownProps {
  open: boolean;
  appType: string | null;
  isUserTypeAdmin: boolean;
  anchorEl: any;
  setAnchorEl: (value: ((prevState: null) => null) | null) => void;
  setPlansData: (value: any) => void;
  productPlans: any[];
  avatarText: string;
  user: any;
}

export const UserMenuDropdown = (props: UserMenuUserMenuDropdownProps) => {
  const {
    open,
    isUserTypeAdmin,
    anchorEl,
    setAnchorEl,
    appType,
    avatarText,
    user,
  } = props;
  const classes = useStyles();
  const {logout} = useLogout();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {t} = useTranslation();

  const activeProduct = useGetActiveProductInfo();
  const activePlanKey = R.propOr("", "productKey", activeProduct);

  // mixpanel tracking
  mixpanel.identify(user.pk);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleDiscount = () => {
    console.log("Give discount");
  };

  const handleSettings = () => {
    navigate("/settings");
  };

  const handleHelp = () => {
    console.log("help clicked");
  };

  const handleSwitchApp = () => {
    const changedAppType = R.equals(appType, "client") ? "admin" : "client";
    dispatch(setAppView(changedAppType));
    localStorage.setItem(storageKey.LOCAL_STORAGE_APP_TYPE_KEY, changedAppType);
  };

  const handleLogout = () => {
    logout({});
  };

  const isTaxPackage = R.includes(activePlanKey, taxPackages);
  const isAdvancedTax = R.equals(activePlanKey, PRO);

  return (
    <Menu
      id="account-menu"
      className={classes.menuClass}
      anchorEl={anchorEl}
      open={open}
      onClose={handleCloseMenu}
      // onClick={handleCloseMenu}
      transformOrigin={{horizontal: "right", vertical: "top"}}
      anchorOrigin={{horizontal: "right", vertical: "bottom"}}
    >
      <MenuItem className={classes.menuItemRoot} id="user-details">
        <Box className={classes.userContainer}>
          <Avatar className={classes.avatar} id="avatar-text">
            {avatarText}
          </Avatar>
          <Box>
            <DescriptionStyled
              color="primary"
              size="lg"
              bold={true}
              noPadding
              id="user-name"
            >
              {`${user.first_name} ${user.last_name}`}
            </DescriptionStyled>
            <DescriptionStyled
              noPadding
              color="secondary"
              size="md"
              id="user-email"
            >
              {user.email}
            </DescriptionStyled>
          </Box>
        </Box>
      </MenuItem>
      {
        <MenuItem
          className={classNames({
            [classes.menuItemRoot]: true,
            [classes.borderStyle]: !isUserTypeAdmin,
          })}
        >
          <Box className={classes.flexRowSpaceBetween}>
            <Box className={classNames({[classes.flexRowSimple]: true})}>
              {planIconsMap(activePlanKey, "sm")}
              <Box className={classes.menuNameBox}>
                <DescriptionStyled size="lg" bold>
                  {isTaxPackage
                    ? `${t(activePlanKey as string)} ${t("plan")}`
                    : `${t(FREE)} ${t("plan")}`}
                </DescriptionStyled>
                <DescriptionStyled
                  size="md"
                  color="secondary"
                  noPadding
                  className={classes.intervalMargin}
                >
                  {isTaxPackage ? "" : t("no_tax_package")}
                </DescriptionStyled>
              </Box>
            </Box>
            <CustomButton
              text={isAdvancedTax ? t("buy_another") : t("upgrade")}
              onClick={() => {
                mixpanel.track("Payment plans accessed", {
                  widget: "header",
                  email: user.email,
                });
                navigate("/settings/payment-plans", {state: {reset: true}});
                handleCloseMenu();
              }}
              className={classes.menuUpgradeButton}
              disableRipple
            />
          </Box>
        </MenuItem>
      }
      {isUserTypeAdmin && (
        <MenuItem
          className={classNames({
            [classes.menuItemRoot]: true,
            [classes.borderStyle]: true,
          })}
          onClick={() => {
            handleSwitchApp();
            handleCloseMenu();
          }}
        >
          <Box className={classes.flexRow}>
            <SwitchIcon />
            <DescriptionStyled color="primary" size="lg">
              {t("switch_to")}{" "}
              {R.equals(appType, "client") ? t("admin") : t("capital_bee")} App
            </DescriptionStyled>
          </Box>
        </MenuItem>
      )}

      <MenuItem
        className={classes.menuItemRoot}
        onClick={() => {
          handleSettings();
          handleCloseMenu();
        }}
        id="settings-btn"
      >
        <Box className={classes.flexRow}>
          <SettingsIcon />
          <DescriptionStyled color="primary" size="lg" id="title">
            {t("settings")}
          </DescriptionStyled>
        </Box>
      </MenuItem>

      <MenuItem
        className={classes.menuItemRoot}
        onClick={() => {
          handleHelp();
          handleCloseMenu();
        }}
        id="help-support-btn"
      >
        <Box className={classes.flexRow}>
          <HelpAndSupportIcon />
          <Box className={classes.flexedContainer}>
            <DescriptionStyled color="primary" size="lg" id="title">
              {t("help_&_support")}
            </DescriptionStyled>
            <CustomChip
              label={t("coming_soon")}
              color={"primary"}
              variant="outlined"
              size="small"
              className={classes.comingSoonChip}
            />
          </Box>
        </Box>{" "}
      </MenuItem>
      <MenuItem className={classes.menuItemRoot} id="contact_tax_advisor-btn">
        <Box className={classes.flexRow}>
          <ContactTaxAdvisorIcon />
          <Box className={classes.flexedContainer}>
            <DescriptionStyled color="primary" size="lg" id="title">
              {t("contact_tax_advisor")}
            </DescriptionStyled>
            <CustomChip
              label={t("coming_soon")}
              color={"primary"}
              variant="outlined"
              size="small"
              className={classes.comingSoonChip}
            />
          </Box>
        </Box>
      </MenuItem>
      <MenuItem
        className={classNames({
          [classes.borderStyle]: true,
          [classes.menuItemRoot]: true,
        })}
        id="refer-btn"
      >
        <Box className={classes.flexRow}>
          <InviteFriendsIcon />
          <Box
            className={classes.itemContainer}
            onClick={() => {
              handleDiscount();
              handleCloseMenu();
            }}
          >
            <div className={classes.flexedContainer}>
              <DescriptionStyled
                color="primary"
                size="lg"
                id="title"
                className={classes.referFriendText}
              >
                {t("refer_a_friend")}
              </DescriptionStyled>

              <CustomChip
                label={t("coming_soon")}
                color={"primary"}
                variant="outlined"
                size="small"
                className={classes.comingSoonChip}
              />
            </div>
          </Box>
        </Box>
      </MenuItem>

      <MenuItem
        onClick={handleLogout}
        className={classes.menuItemRoot}
        id="sign-out-btn"
      >
        <Box className={classes.flexRow}>
          <LogOutIcon />
          <DescriptionStyled
            className={classes.logout}
            color="primary"
            size="lg"
            id="title"
          >
            {t("sign_out")}
          </DescriptionStyled>
        </Box>
      </MenuItem>
    </Menu>
  );
};
