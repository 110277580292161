// lib
import {Box} from "@mui/material";
import classNames from "classnames";
// src
import {useSettingsStyles} from "screens/settings/settings.styles";
import TextSkeleton from "screens/dashboard/components/skeleton/textSkeleton";

export const PlanCardSkeleton = () => {
  const classes = useSettingsStyles();
  return (
    <Box
      className={classNames({
        [classes.planBoxStyle]: true,
      })}
      key={1}
    >
      <Box className={classes.planChip}>
        <TextSkeleton
          styling={false}
          width={70}
          height={38}
          sx={{borderRadius: 4}}
        />
      </Box>

      <Box
        className={classNames({
          [classes.flexRowSimple]: true,
          [classes.fullWidth]: true,
        })}
      >
        <TextSkeleton styling={false} width={75} height={112} />
        <Box className={classes.planNameBox}>
          <TextSkeleton styling={false} width={138} height={21} />
          <Box className={classes.flexRowSimple}>
            <TextSkeleton styling={false} width={90} height={70} />
            <TextSkeleton styling={false} width={50} height={30} />
          </Box>
        </Box>
      </Box>
      <TextSkeleton styling={false} width={250} height={20} />
      <TextSkeleton styling={false} width={250} height={20} />
      <TextSkeleton styling={false} width={250} height={20} />
      <TextSkeleton styling={false} width={150} height={20} />

      <Box className={classes.skeletonColumnFlex}>
        <TextSkeleton styling={false} width={250} height={20} />
        <TextSkeleton styling={false} width={200} height={20} />
        <TextSkeleton styling={false} width={170} height={20} />
        <TextSkeleton styling={false} width={150} height={20} />
        <TextSkeleton styling={false} width={170} height={20} />
        <TextSkeleton styling={false} width={100} height={20} />
      </Box>

      <TextSkeleton styling={false} width={150} />
    </Box>
  );
};
