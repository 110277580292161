import * as R from "ramda";
export const DefaultLinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M9 3.5 7.942 4.558l4.185 4.192H3v1.5h9.127l-4.185 4.193L9 15.5l6-6-6-6z"
        fill="#1B2126"
      />
    </svg>
  );
};

export const RedLinkIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 0.460938L7.34 1.87094L12.92 7.46094H0.75V9.46094H12.92L7.34 15.0509L8.75 16.4609L16.75 8.46094L8.75 0.460938Z"
        fill="#D94A41"
      />
    </svg>
  );
};

export const RedInvertedLinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        d="m1.49 12.034-.048-1.9 7.917-.205L.525 1.94 1.91.557l8.834 7.989-.198-7.53 2.013-.05.273 10.786-11.341.282z"
        fill="#D94A41"
      />
    </svg>
  );
};

export const BlueLinkIcon = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.75 0.460938L7.34 1.87094L12.92 7.46094H0.75V9.46094H12.92L7.34 15.0509L8.75 16.4609L16.75 8.46094L8.75 0.460938Z"
        fill="#2988DF"
      />
    </svg>
  );
};

export const GreenLinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="13"
      viewBox="0 0 14 13"
      fill="none"
    >
      <path
        d="m1.807.966-.048 1.9 7.918.205-8.834 7.99 1.383 1.383 8.834-7.99-.197 7.53 2.013.05.273-10.786L1.807.966z"
        fill="#1DA868"
      />
    </svg>
  );
};

export const CrossSvgChip = () => {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.625 6.223 15.391 4.98 10.5 9.905 5.609 4.98 4.375 6.223l4.891 4.924-4.891 4.925 1.234 1.242 4.891-4.925 4.891 4.925 1.234-1.242-4.891-4.925 4.891-4.924z"
        fill="#fff"
      />
    </svg>
  );
};

interface TopTraderBadgeProps {
  colorType?: "primary" | "secondary";
}

export const TopTraderBadge = (props: TopTraderBadgeProps) => {
  const {colorType = "primary"} = props;
  const color = colorType === "primary" ? "#fbbf21" : "#2988df";
  return (
    <svg
      id="Layer_1"
      width="21"
      height="22"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16.54 21.12"
    >
      <path
        d="M79.32,120.27a8.28,8.28,0,1,0,8.27,8.28A8.28,8.28,0,0,0,79.32,120.27Zm4.48,11.24a.77.77,0,0,1-.26.07l-2.42.08-1.29,2.05a.59.59,0,0,1-1,0l-1.29-2.05-2.42-.08a.62.62,0,0,1-.58-.63.59.59,0,0,1,.07-.26l1.14-2.14-1.14-2.14a.6.6,0,0,1,.24-.82.68.68,0,0,1,.27-.07l2.42-.09,1.29-2.05a.6.6,0,0,1,.83-.19.5.5,0,0,1,.19.19l1.29,2.05,2.42.09a.61.61,0,0,1,.58.63.59.59,0,0,1-.07.26l-1.14,2.14,1.14,2.14A.6.6,0,0,1,83.8,131.51Z"
        transform="translate(-71.05 -120.27)"
        fill={color}
      />
      <path
        d="M79.16,138.16A9.56,9.56,0,0,1,74.52,137v3.84a.6.6,0,0,0,.6.6.53.53,0,0,0,.25,0l3.79-1.71L83,141.34a.62.62,0,0,0,.86-.55V137A9.57,9.57,0,0,1,79.16,138.16Z"
        transform="translate(-71.05 -120.27)"
        fill={color}
      />
      <path
        d="M81.7,128.26l.83-1.56-1.77-.07a.59.59,0,0,1-.5-.28l-.94-1.51-.94,1.51a.58.58,0,0,1-.49.28l-1.77.07.83,1.56a.64.64,0,0,1,0,.57l-.83,1.57,1.77.06a.6.6,0,0,1,.49.28l.94,1.51.94-1.51a.62.62,0,0,1,.5-.28l1.77-.06-.83-1.57A.58.58,0,0,1,81.7,128.26Z"
        transform="translate(-71.05 -120.27)"
        fill={color}
      />
    </svg>
  );
};

type FilterEyeSvgProps = {
  filled?: boolean;
  crossed?: boolean;
};
export const FilterEyeSvg = (props: FilterEyeSvgProps) => {
  const {filled = false, crossed = false} = props;
  const backgroundColor = filled ? "#2988df" : "#BEC2C4";
  return (
    <svg
      width="17"
      height="11"
      viewBox="0 0 17 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      {!crossed && (
        <path
          d="M8.175 0C4.46 0 1.285 2.273 0 5.482c1.286 3.208 4.46 5.481 8.175 5.481 3.716 0 6.89-2.273 8.176-5.481C15.065 2.273 11.89 0 8.175 0zm0 9.136c-2.051 0-3.716-1.637-3.716-3.654 0-2.018 1.665-3.655 3.716-3.655 2.052 0 3.716 1.637 3.716 3.655 0 2.017-1.664 3.654-3.716 3.654zm0-5.847c-1.233 0-2.23.98-2.23 2.193s.997 2.192 2.23 2.192c1.234 0 2.23-.98 2.23-2.192 0-1.214-.996-2.193-2.23-2.193z"
          fill={backgroundColor}
        />
      )}
      {crossed && (
        <path
          d="M11.91 6.233a7.867 7.867 0 0 0-2.072-2.72l1.516-1.516-.708-.708L9 2.934a5.892 5.892 0 0 0-3-.791c-3.75 0-5.472 3.13-5.91 4.09a.985.985 0 0 0 0 .82 7.868 7.868 0 0 0 2.072 2.72L.646 11.29l.707.707L3 10.351c.911.527 1.947.8 3 .792 3.75 0 5.471-3.13 5.91-4.09a.983.983 0 0 0 0-.82zM1 6.648c.375-.822 1.846-3.505 5-3.505a4.923 4.923 0 0 1 2.259.534l-.883.882a2.496 2.496 0 0 0-3.46 3.46l-1.04 1.04A6.823 6.823 0 0 1 1 6.649zm6.5-.005a1.5 1.5 0 0 1-1.5 1.5c-.223-.001-.442-.053-.643-.15L7.35 6c.098.2.15.42.15.643zm-3 0a1.5 1.5 0 0 1 1.5-1.5c.223 0 .442.052.642.15L4.65 7.285c-.098-.2-.15-.42-.15-.642zm1.5 3.5a4.923 4.923 0 0 1-2.26-.534l.883-.883a2.496 2.496 0 0 0 3.46-3.46l1.04-1.039A6.792 6.792 0 0 1 11 6.643c-.382.828-1.854 3.5-5 3.5z"
          fill={backgroundColor}
        />
      )}
    </svg>
  );
};
type viewProps = {
  filled?: boolean;
};
export const GridViewSvg = (props: viewProps) => {
  const {filled = false} = props;
  const backgroundColor = filled ? "#2988df" : "#BEC2C4";
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill={"none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.5 10.5H8.5V0.5H0.5V10.5ZM0.5 18.5H8.5V12.5H0.5V18.5ZM10.5 18.5H18.5V8.5H10.5V18.5ZM10.5 0.5V6.5H18.5V0.5H10.5Z"
        fill={backgroundColor}
      />
    </svg>
  );
};

export const ListViewSvg = (props: viewProps) => {
  const {filled = false} = props;
  const backgroundColor = filled ? "#2988df" : "#BEC2C4";
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.4583 0.625H2.54167C1.2125 0.625 0.125 1.7125 0.125 3.04167V19.9583C0.125 21.2875 1.2125 22.375 2.54167 22.375H19.4583C20.7875 22.375 21.875 21.2875 21.875 19.9583V3.04167C21.875 1.7125 20.7875 0.625 19.4583 0.625ZM7.375 17.5417H4.95833V9.08333H7.375V17.5417ZM12.2083 17.5417H9.79167V5.45833H12.2083V17.5417ZM17.0417 17.5417H14.625V12.7083H17.0417V17.5417Z"
        fill={backgroundColor}
      />
    </svg>
  );
};

export const NoWalletOrTransactionsSvg = () => {
  return (
    <svg
      width="137"
      height="137"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.2" filter="url(#filter0_d_2354_26936)">
        <circle cx="63.7739" cy="62.7739" r="45.7739" fill="white" />
      </g>
      <path
        d="M74.6552 88.007H72.1982V87.1868H74.6304L74.6552 88.007ZM69.7657 88.007H67.3329V87.1864H69.7657V88.007ZM64.9007 88.007H62.4682V87.1864H64.9007V88.007ZM60.0357 88.007H57.6035V87.1864H60.0357V88.007ZM55.1707 88.007H52.7382V87.1864H55.1707V88.007ZM50.306 88.007H47.8735V87.1864H50.306V88.007ZM45.5492 86.5532C45.3771 86.2001 45.2901 85.8201 45.2897 85.4239V83.9748H46.1099V85.4235C46.1103 85.6943 46.1695 85.9535 46.2864 86.1944L45.5492 86.5532ZM76.9672 86.5254L76.2261 86.1742C76.337 85.9392 76.3929 85.6867 76.3929 85.4235V83.9479H77.2138V85.4235C77.2138 85.8092 77.1307 86.1799 76.9672 86.5254ZM46.1099 81.5419H45.2891V79.1094H46.1099V81.5419ZM77.2138 81.5158H76.3933V79.0833H77.2138V81.5158ZM46.1099 76.6772H45.2891V74.2447H46.1099V76.6772ZM77.2138 76.6508H76.3933V74.2183H77.2138V76.6508ZM46.1099 71.8122H45.2891V69.3794H46.1099V71.8122ZM77.2138 71.7858H76.3933V69.3533H77.2138V71.7858ZM46.1099 66.9472H45.2891V64.5147H46.1099V66.9472ZM77.2138 66.9211H76.3933V64.4882H77.2138V66.9211ZM46.1099 62.0826H45.2891V59.65H46.1099V62.0826ZM77.2138 62.0561H76.3933V59.6236H77.2138V62.0561ZM46.1099 57.2175H45.2891V54.785H46.1099V57.2175ZM77.2138 57.1914H76.3933V54.7589H77.2138V57.1914ZM46.1099 52.3525H45.2891V49.92H46.1099V52.3525ZM77.2138 52.3261H76.3933V49.8936H77.2138V52.3261ZM46.1099 47.4879H45.2897V46.0248C45.2897 45.6349 45.3745 45.2602 45.5416 44.91L46.2821 45.2642C46.1679 45.5025 46.1099 45.7584 46.1099 46.0248V47.4879ZM77.2138 47.4614H76.3929V46.0248C76.3929 45.7511 76.3324 45.489 76.2122 45.2453L76.9477 44.8819C77.1245 45.2394 77.2138 45.624 77.2138 46.0248V47.4614ZM74.6171 44.2619H72.1846V43.4414H74.6171V44.2619ZM69.7525 44.2619H67.3196V43.4414H69.7525V44.2619ZM64.8871 44.2619H62.4546V43.4414H64.8871V44.2619ZM60.0228 44.2619H57.5903V43.4414H60.0228V44.2619ZM55.1574 44.2619H52.7249V43.4414H55.1574V44.2619ZM50.2928 44.2619H47.8728L47.8599 43.4414H50.2928V44.2619Z"
        fill="#2988DF"
      />
      <path
        d="M80.5966 83.4767H52.4356C51.1722 83.4767 50.1484 82.4526 50.1484 81.1898V39.7244C50.1484 38.4616 51.1722 37.4375 52.4356 37.4375H71.6722C75.3864 43.5646 80.0498 46.1434 82.8835 47.7147V81.1898C82.8835 82.4526 81.8594 83.4767 80.5966 83.4767Z"
        fill="#2988DF"
      />
      <path
        d="M71.6719 37.4375V46.0805C71.6719 46.9832 72.4037 47.715 73.306 47.715H82.8834L71.6719 37.4375Z"
        fill="#E5E6EA"
      />
      <path
        d="M70.9728 67.6317L76.1541 72.8133C76.5297 73.1889 76.5297 73.7981 76.1541 74.1736C75.7784 74.5492 75.1696 74.5492 74.7938 74.1736L69.6125 68.992C68.3094 70.0049 66.6722 70.6079 64.8941 70.6079C60.6445 70.6079 57.1992 67.1631 57.1992 62.9134C57.1992 58.6638 60.6445 55.2188 64.8941 55.2188C69.1437 55.2188 72.5888 58.6638 72.5888 62.9134C72.5888 64.6915 71.9856 66.3288 70.9728 67.6317ZM64.8941 68.6843C68.0814 68.6843 70.6649 66.1006 70.6649 62.9134C70.6649 59.7261 68.0814 57.1422 64.8941 57.1422C61.7068 57.1422 59.1231 59.7261 59.1231 62.9134C59.1231 66.1006 61.7068 68.6843 64.8941 68.6843Z"
        fill="#F2F5FA"
      />
      <defs>
        <filter
          id="filter0_d_2354_26936"
          x="0"
          y="0"
          width="127.547"
          height="127.547"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="1" />
          <feGaussianBlur stdDeviation="9" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.160784 0 0 0 0 0.533333 0 0 0 0 0.87451 0 0 0 0.6 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_2354_26936"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_2354_26936"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export const PenSvg = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="m4.806 15.585 8.005-8.058-2.35-2.349L2.423 13.2l2.384 2.385zm0 0a.402.402 0 0 1-.285.116H2.306m2.5-.116-2.5.116m0 0v-2.216c0-.105.04-.208.116-.285l-.116 2.5zm-.914-3.514a1.834 1.834 0 0 0-.542 1.298v2.632c0 .583.478 1.04 1.04 1.04h2.614c.483 0 .966-.193 1.315-.542L16.597 5.8a1.84 1.84 0 0 0 .018-2.613l-1.777-1.777A1.78 1.78 0 0 0 13.52.85a1.86 1.86 0 0 0-1.316.542L1.392 12.187zm14.192-7.416-1.743 1.727-2.349-2.333 1.743-1.743a.39.39 0 0 1 .286-.116.39.39 0 0 1 .285.116l1.779 1.779a.408.408 0 0 1 0 .57z"
        fill="#2988DF"
        stroke="#2988DF"
        strokeWidth=".3"
      />
    </svg>
  );
};

export const SuccessRateSvg = () => {
  return (
    <svg
      width="55"
      height="55"
      viewBox="0 0 55 55"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M48.575 22.87h3.416A24.937 24.937 0 0 0 32.133 3.012v3.416A21.535 21.535 0 0 1 48.575 22.87zM22.87 6.428V3.012A24.937 24.937 0 0 0 3.012 22.87h3.416A21.535 21.535 0 0 1 22.87 6.428zM32.133 48.575v3.416A24.937 24.937 0 0 0 51.99 32.133h-3.416a21.535 21.535 0 0 1-16.442 16.442zM6.428 32.133H3.012A24.937 24.937 0 0 0 22.87 51.99v-3.416A21.535 21.535 0 0 1 6.428 32.133z"
        fill="#1DA868"
      />
      <path
        d="M53.321 25.821h-7.874c-.752-8.626-7.642-15.516-16.268-16.268V1.679a1.68 1.68 0 0 0-3.358 0v7.874c-8.626.752-15.516 7.642-16.268 16.268H1.679a1.68 1.68 0 0 0 0 3.358h7.874c.752 8.626 7.642 15.516 16.268 16.268v7.874a1.68 1.68 0 0 0 3.358 0v-7.874c8.626-.752 15.516-7.642 16.268-16.268h7.874a1.68 1.68 0 0 0 0-3.358zm-17.89-.579-8.452 8.453c-.463.463-.984.637-1.563.637a2.237 2.237 0 0 1-1.563-.637l-4.227-4.227a2.272 2.272 0 0 1 0-3.184 2.272 2.272 0 0 1 3.184 0l2.664 2.663 6.89-6.89a2.272 2.272 0 0 1 3.183 0c.753.927.753 2.317-.115 3.185z"
        fill="#1DA868"
      />
    </svg>
  );
};

export const SuccessRateLossSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="56"
      height="56"
      viewBox="0 0 56 56"
      fill="none"
    >
      <path
        d="M48.912 23.33h3.407C50.414 13.258 42.56 5.384 32.511 3.474v3.415c8.2 1.795 14.61 8.222 16.401 16.443zM23.27 6.888V3.473C13.221 5.383 5.367 13.257 3.461 23.33H6.87c1.79-8.221 8.2-14.648 16.401-16.443zM32.51 49.036v3.416c10.05-1.91 17.904-9.785 19.81-19.858h-3.408c-1.79 8.22-8.2 14.647-16.401 16.442zM6.869 32.594H3.46c1.906 10.073 9.76 17.947 19.81 19.858v-3.416C15.068 47.24 8.658 40.815 6.868 32.594z"
        fill="#D94A41"
      />
      <path
        d="M45.792 26.282h7.855a1.678 1.678 0 0 1 0 3.358h-7.855c-.75 8.626-7.623 15.516-16.228 16.268v7.874c0 .926-.75 1.679-1.675 1.679a1.678 1.678 0 0 1-1.674-1.679v-7.874c-8.605-.752-15.478-7.642-16.229-16.268H2.132a1.678 1.678 0 0 1 0-3.358h7.854c.751-8.626 7.624-15.516 16.229-16.268V2.14c0-.926.75-1.68 1.674-1.68.924 0 1.675.754 1.675 1.68v7.874c8.605.752 15.478 7.642 16.228 16.268z"
        fill="#D94A41"
      />
      <rect x="20" y="26" width="16" height="4" rx="2" fill="#fff" />
    </svg>
  );
};

export const InfoSvg = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7 0C3.13359 0 0 3.13359 0 7C0 10.8664 3.13359 14 7 14C10.8664 14 14 10.8664 14 7C14 3.13359 10.8664 0 7 0ZM7 3.5C7.48316 3.5 7.875 3.89184 7.875 4.375C7.875 4.85816 7.48316 5.25 7 5.25C6.51684 5.25 6.125 4.85898 6.125 4.375C6.125 3.89102 6.51602 3.5 7 3.5ZM8.09375 10.5H5.90625C5.54531 10.5 5.25 10.2074 5.25 9.84375C5.25 9.48008 5.54395 9.1875 5.90625 9.1875H6.34375V7.4375H6.125C5.7627 7.4375 5.46875 7.14355 5.46875 6.78125C5.46875 6.41895 5.76406 6.125 6.125 6.125H7C7.3623 6.125 7.65625 6.41895 7.65625 6.78125V9.1875H8.09375C8.45605 9.1875 8.75 9.48145 8.75 9.84375C8.75 10.2061 8.45742 10.5 8.09375 10.5Z"
        fill="#BEC2C4"
      />
    </svg>
  );
};

export const WalletSvg = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity="0.2"
        cx="23"
        cy="23"
        r="23"
        transform="rotate(-180 23 23)"
        fill="#FBC22D"
      />
      <path
        d="M30.7617 28V28.8333C30.7617 29.75 30.0117 30.5 29.0951 30.5H17.4284C16.5034 30.5 15.7617 29.75 15.7617 28.8333V17.1667C15.7617 16.25 16.5034 15.5 17.4284 15.5H29.0951C30.0117 15.5 30.7617 16.25 30.7617 17.1667V18H23.2617C22.3367 18 21.5951 18.75 21.5951 19.6667V26.3333C21.5951 27.25 22.3367 28 23.2617 28H30.7617ZM23.2617 26.3333H31.5951V19.6667H23.2617V26.3333ZM26.5951 24.25C25.9034 24.25 25.3451 23.6917 25.3451 23C25.3451 22.3083 25.9034 21.75 26.5951 21.75C27.2867 21.75 27.8451 22.3083 27.8451 23C27.8451 23.6917 27.2867 24.25 26.5951 24.25Z"
        fill="#FBC22D"
      />
    </svg>
  );
};

export const ProfitSvg = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle opacity=".1" cx="23" cy="23" r="23" fill="#1DA868" />
      <path
        d="m13.519 21.979 1.808 1.915 6.64-6.184v15.134h2.719V17.71l5.76 6.197 2.041-1.928-8.865-9.471-10.103 9.47z"
        fill="#1DA868"
      />
    </svg>
  );
};

export const LossSvg = () => {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        opacity=".1"
        cx="23"
        cy="23"
        r="23"
        transform="rotate(-180 23 23)"
        fill="#D94A41"
      />
      <path
        d="m32.481 24.021-1.808-1.915-6.64 6.184V13.156h-2.719V28.29l-5.76-6.197-2.042 1.928 8.866 9.471 10.103-9.47z"
        fill="#D94A41"
      />
    </svg>
  );
};

export const AssetHoldingBackgroundSvg = () => {
  const svgHeight = "350";
  return (
    <svg
      width="259"
      height={svgHeight}
      viewBox={`0 0 259 ${svgHeight}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3967_78729)">
        <rect
          width="530"
          height="530"
          transform="translate(-241.767 108) rotate(-45)"
          fill="white"
        />
        <line
          x1="-238.938"
          y1="105.172"
          x2="135.828"
          y2="479.938"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-230.421"
          y1="96.654"
          x2="144.346"
          y2="471.421"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-221.904"
          y1="88.1364"
          x2="152.863"
          y2="462.903"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-213.386"
          y1="79.6188"
          x2="161.38"
          y2="454.385"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-204.869"
          y1="71.1022"
          x2="169.898"
          y2="445.869"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-196.351"
          y1="62.5847"
          x2="178.415"
          y2="437.351"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-187.834"
          y1="54.0671"
          x2="186.933"
          y2="428.834"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-179.316"
          y1="45.5495"
          x2="195.45"
          y2="420.316"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-170.799"
          y1="37.0319"
          x2="203.968"
          y2="411.799"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-162.282"
          y1="28.5143"
          x2="212.485"
          y2="403.281"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-153.764"
          y1="19.9977"
          x2="221.002"
          y2="394.764"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-145.247"
          y1="11.4802"
          x2="229.52"
          y2="386.247"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-136.729"
          y1="2.96259"
          x2="238.037"
          y2="377.729"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-128.212"
          y1="-5.55499"
          x2="246.555"
          y2="369.212"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-119.695"
          y1="-14.0726"
          x2="255.072"
          y2="360.694"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-111.177"
          y1="-22.5901"
          x2="263.59"
          y2="352.176"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-102.66"
          y1="-31.1067"
          x2="272.107"
          y2="343.66"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-94.1423"
          y1="-39.6248"
          x2="280.624"
          y2="335.142"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-85.6247"
          y1="-48.1419"
          x2="289.142"
          y2="326.625"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-77.1074"
          y1="-56.6595"
          x2="297.659"
          y2="318.107"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-68.59"
          y1="-65.1771"
          x2="306.177"
          y2="309.59"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-60.0725"
          y1="-73.6941"
          x2="314.694"
          y2="301.072"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-51.5551"
          y1="-82.2117"
          x2="323.211"
          y2="292.555"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-43.0378"
          y1="-90.7293"
          x2="331.729"
          y2="284.037"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-34.5202"
          y1="-99.2464"
          x2="340.246"
          y2="275.52"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-26.0029"
          y1="-107.764"
          x2="348.764"
          y2="267.003"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-17.4855"
          y1="-116.282"
          x2="357.281"
          y2="258.485"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-8.96796"
          y1="-124.799"
          x2="365.799"
          y2="249.968"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="-0.450625"
          y1="-133.316"
          x2="374.316"
          y2="241.45"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="8.06671"
          y1="-141.834"
          x2="382.833"
          y2="232.933"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="16.5843"
          y1="-150.351"
          x2="391.351"
          y2="224.416"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="25.1016"
          y1="-158.868"
          x2="399.868"
          y2="215.898"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="33.619"
          y1="-167.386"
          x2="408.386"
          y2="207.381"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="42.1365"
          y1="-175.903"
          x2="416.903"
          y2="198.863"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="50.6539"
          y1="-184.421"
          x2="425.42"
          y2="190.346"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="59.1712"
          y1="-192.938"
          x2="433.938"
          y2="181.828"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="67.6888"
          y1="-201.455"
          x2="442.455"
          y2="173.311"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="76.2061"
          y1="-209.973"
          x2="450.973"
          y2="164.794"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="84.7234"
          y1="-218.491"
          x2="459.49"
          y2="156.276"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="93.241"
          y1="-227.008"
          x2="468.008"
          y2="147.759"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="101.758"
          y1="-235.525"
          x2="476.525"
          y2="139.241"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="110.276"
          y1="-244.043"
          x2="485.042"
          y2="130.724"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="118.793"
          y1="-252.56"
          x2="493.56"
          y2="122.207"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="127.311"
          y1="-261.077"
          x2="502.077"
          y2="113.689"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
        <line
          x1="135.828"
          y1="-269.595"
          x2="510.595"
          y2="105.172"
          stroke="#D5FFEC"
          strokeWidth="8"
        />
      </g>
      <line
        x1="0.5"
        y1="1.79835e-08"
        x2="0.499988"
        y2={svgHeight}
        stroke="#BEC2C4"
      />
      <defs>
        <clipPath id="clip0_3967_78729">
          <rect
            width="258"
            height={svgHeight}
            fill="white"
            transform="translate(1)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

interface arrowRightType {
  variant: "filled" | "outlined";
  disabled?: boolean;
}

export const ArrowRight = (props: arrowRightType) => {
  const {variant, disabled = false} = props;
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#zeb92bohja)">
        <path
          d="m8 13.555-.94-.94 3.72-3.726H2.667V7.555h8.113L7.06 3.83l.94-.94 5.333 5.333L8 13.555z"
          fill={`${
            R.equals(variant, "filled")
              ? "#2988df"
              : disabled
              ? "#BEC2C4"
              : "#6C7780"
          }`}
          // "#BEC2C4"
        />
      </g>
      <defs>
        <clipPath id="zeb92bohja">
          <path
            fill="#fff"
            transform="matrix(1 0 0 -1 0 16.222)"
            d="M0 0h16v16H0z"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const ArrowLeft = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#gc339d6nfa)">
        <path
          d="M13.333 7.333H5.22l3.727-3.727L8 2.666 2.667 7.999 8 13.333l.94-.94-3.72-3.727h8.113V7.333z"
          fill="#2988DF"
        />
      </g>
      <defs>
        <clipPath id="gc339d6nfa">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Best5Trades = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.460938" width="24" height="24" rx="12" fill="#DFF4EA" />
    <g clipPath="url(#clip0_701_89259)">
      <path
        d="M7.1665 12.4608L8.1065 13.4008L11.8332 9.68077V17.7941H13.1665V9.68077L16.8865 13.4074L17.8332 12.4608L12.4998 7.12744L7.1665 12.4608Z"
        fill="#1DA868"
      />
    </g>
    <defs>
      <clipPath id="clip0_701_89259">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(4.5 4.46094)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Worst5Trades = () => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" y="0.921875" width="24" height="24" rx="12" fill="#F8EFEF" />
    <g clipPath="url(#clip0_701_89263)">
      <path
        d="M17.8332 12.9217L16.8932 11.9817L13.1665 15.7017V7.58838H11.8332V15.7017L8.11317 11.975L7.1665 12.9217L12.4998 18.255L17.8332 12.9217Z"
        fill="#D94A41"
      />
    </g>
    <defs>
      <clipPath id="clip0_701_89263">
        <rect
          width="16"
          height="16"
          fill="white"
          transform="translate(4.5 4.92188)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const Inflows = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m1.807.966-.048 1.9 7.918.205L.843 11.06l1.383 1.383 8.834-7.989-.197 7.53 2.013.05.273-10.786L1.807.966z"
      fill="#1DA868"
    />
  </svg>
);

export const Outflows = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m1.49 12.034-.048-1.9 7.917-.205L.525 1.94 1.91.557l8.834 7.989-.198-7.53 2.013-.05.273 10.786-11.341.282z"
      fill="#D94A41"
    />
  </svg>
);

export const InfoIconBlue = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
  >
    <g clip-path="url(#clip0_2888_677)">
      <path
        d="M12 2.14453C6.48 2.14453 2 6.62453 2 12.1445C2 17.6645 6.48 22.1445 12 22.1445C17.52 22.1445 22 17.6645 22 12.1445C22 6.62453 17.52 2.14453 12 2.14453ZM13 17.1445H11V11.1445H13V17.1445ZM13 9.14453H11V7.14453H13V9.14453Z"
        fill="#2988DF"
      />
    </g>
    <defs>
      <clipPath id="clip0_2888_677">
        <rect
          width="24"
          height="24"
          fill="white"
          transform="translate(0 0.144531)"
        />
      </clipPath>
    </defs>
  </svg>
);

interface InfoIconLeftType {
  width: string;
}

export const InfoIconLeft = (props: InfoIconLeftType) => {
  const {width} = props;
  return (
    <svg
      width={width}
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1760_16603)">
        <path
          d="M11 7H13V9H11V7ZM11 11H13V17H11V11ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z"
          fill="#BEC2C4"
        />
      </g>
      <defs>
        <clipPath id="clip0_1760_16603">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
