//lib
import * as R from "ramda";
import classNames from "classnames";
import {useQueryClient} from "react-query";
import {Box, Divider} from "@mui/material";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import {useNavigate, useSearchParams, useLocation} from "react-router-dom";

//src
import {
  AdvancedPortfolioIcon,
  AdvancedTaxIcon,
  BasicPortfolioIcon,
  BasicTaxIcon,
} from "assets/svgs/Plans";
import {
  useFetchInvoicesDetails,
  useFetchPaymentPlans,
  useUnsubscribePackage,
} from "services/api/settings";
import {
  getFormattedCurrency,
  getIsMobileView,
  getTimeAgo,
  isSomething,
  mapIndexed,
} from "utils";
import {setToaster} from "store/ui";
import PaymentPlansComparison from "./payment-plans-comparison";
import {getFormattedYearValueWithMMM} from "utils";
import CustomChip from "components/custom-chip/custom-chip";
import {useGetTaxYearsWithSubscriptionInfo} from "utils/meta-hooks";
import {useFetchTransactionsCountYearly} from "services/api/dashboard";
import {queryConstants, FREE, PRO, BEGINNER, ADVANCED} from "constants/";
import {CustomTooltip, DescriptionStyled, TitleStyled} from "styles/common";
import PaymentPlanYearSelectionModal from "./payment-plan-year-selection-modal";
import {TaxYearsProps} from "screens/taxes/tax-report/components/tax-date/tax-date";
import CancelPaymentPlanModal from "./cancel-payment-plan-modal/cancel-payment-plan-modal";
import {CustomButton} from "submodules/capital-bee-web-ui-kit/src/components/CustomButton";
import {RenderFeatures} from "../../offered-plans/components/offered-plans-modal/components/render-plan-cards";
//styles
import {RootState} from "../../../../../store";
import {useSettingsStyles} from "../../../settings.styles";
import TickIcon from "../../../../../assets/svgs/TickIcon";
import {FireIcon} from "../../../../../assets/svgs/Common";
import {PlanCardSkeleton} from "./skeletons/plan-card-skeleton";
import {useGetActiveProductInfo, useGetUserLanguage} from "utils/meta-hooks";
import {taxYearType} from "../../../../taxes/tax-report/components/tax-date/tax-date";

interface planFeaturesType {
  cpa_advice: string;
  num_wallets: string;
  portfolio_plan: string;
  num_transactions: string;
}

interface PaymentPlansType {
  setisCheckout: (a: boolean) => void;
  setProductID: (a: string) => void;
  setPriceID: (a: string) => void;
  setTaxYearID: (a: string) => void;
  selectedPlan: {};
  setSelectedPlan: React.Dispatch<React.SetStateAction<{}>>;
  handleInvoiceGeneration: () => void;
  setSelectedYearTitle: (a: string) => void;
}

interface PlanCardType {
  plan: any;
  index: number;
  handleUpgrade: any;
  setisCheckout: (a: boolean) => void;
  switchToCheckout: any;
  id?: string;
  subscribedYearsCount: number;
  isBlueTicksPlan: boolean;
  isAdvancePortfolioSubscribed: boolean;
  subscribedForYears: string[];
  recentTaxYear: string;
  productPlans: any[];
}

const iconSizeMap: any = {
  sm: "48",
  md: "75",
};

export const planIconsMap: any = (
  key: string,
  size: string = "sm",
  isInactive = false,
) => {
  const heightWidth = iconSizeMap[size];
  const svgs = {
    beginner_product: (
      <AdvancedPortfolioIcon
        background="#fff7e1"
        height={heightWidth}
        width={heightWidth}
        borderRadius="8px"
      />
    ),
    advanced_product: (
      <BasicTaxIcon
        background="#fff7e1"
        height={heightWidth}
        width={heightWidth}
        borderRadius="8px"
      />
    ),
    pro_product: (
      <AdvancedTaxIcon
        background="#fff7e1"
        height={heightWidth}
        width={heightWidth}
        borderRadius="8px"
      />
    ),
    // basic_portfolio: isInactive ? (
    //   <BasicPortfolioInactiveIcon />
    // ) : (
    //   <BasicPortfolioIcon
    //     background="#fff7e1"
    //     height={heightWidth}
    //     width={heightWidth}
    //     borderRadius="8px"
    //   />
    // ),
    free_product: (
      <BasicPortfolioIcon
        background="#fff7e1"
        height={heightWidth}
        width={heightWidth}
        borderRadius="8px"
      />
    ),
  };

  return R.propOr("", key, svgs);
};

const PlanCard = (props: PlanCardType) => {
  const {
    plan,
    index,
    handleUpgrade,
    switchToCheckout,
    id,
    subscribedYearsCount,
    isBlueTicksPlan = false,
    isAdvancePortfolioSubscribed = false,
    subscribedForYears,
    recentTaxYear,
    productPlans,
  } = props;

  const queryClient = useQueryClient();
  const dispatch = useDispatch();
  const planId = R.pathOr("", ["id"], plan);
  const {unSubscribe} = useUnsubscribePackage(planId);

  const [isCancelPaymentPlanModalOpen, setIsCancelPaymentPlanModalOpen] =
    useState(false);

  const {data: transactionsCountData} =
    useFetchTransactionsCountYearly(recentTaxYear);

  const totalTransactionsForRecentTaxYear: number = R.pathOr(
    0,
    ["data", "total_transactions"],
    transactionsCountData,
  );

  function findProductForTransactionThreshold(
    productArray: any[],
    totalTransactions: number,
  ): string | null {
    if (!isSomething(totalTransactions)) return null;

    let previousPlanTransactions = 0;

    const targetPlan = productArray.find((plan: any) => {
      const currentPlanTransactions = parseInt(plan.metadata.num_transactions);
      const transactionsInRange =
        totalTransactions > previousPlanTransactions &&
        totalTransactions <= currentPlanTransactions;

      previousPlanTransactions = currentPlanTransactions;

      return transactionsInRange;
    });

    return targetPlan ? targetPlan.metadata.product : null;
  }

  const recommendedPlanKey: string | null = findProductForTransactionThreshold(
    productPlans,
    totalTransactionsForRecentTaxYear,
  );

  const activeProduct = useGetActiveProductInfo();
  const activeProductID = R.propOr("", "productId", activeProduct);
  const activeProductKey = R.propOr("", "productKey", activeProduct);
  const activePlanExpiryDate = R.propOr("", "productExpiryDate", activeProduct);

  const isFreeTier = R.equals(activeProductKey, FREE);

  const {languageCode} = useGetUserLanguage();
  const activePeriod = getTimeAgo(activePlanExpiryDate, languageCode);

  const plansFeatures: planFeaturesType | null = R.propOr(
    null,
    "metadata",
    plan,
  );
  const pricesArray = R.pipe(
    R.propOr({}, "prices"),
    R.filter((x: any) => R.propEq("active", true, x)),
  )(plan);
  const price = R.pipe(
    R.pathOr(0, ["0", "unit_amount"]),
    R.divide(R.__, 100),
  )(pricesArray);

  const currency = R.pathOr("eur", ["0", "currency"], pricesArray);
  const planID = R.propOr("", "id", plan);

  const isSubscribed = R.equals(planID, activeProductID);

  const isMonthlyPlan = R.pathEq(
    ["prices", 0, "recurring", "interval"],
    "month",
    plan,
  );

  const planKey: string = R.pathOr("", ["metadata", "product"], plan);
  const planDescription: string = R.propOr("-", "description", plan);

  const isPlanRecommended =
    R.equals(recommendedPlanKey, planKey) &&
    !R.equals(recommendedPlanKey, FREE);

  const isBestSelling =
    R.equals(planKey, ADVANCED) &&
    !isPlanRecommended &&
    isSomething(recommendedPlanKey);

  const classes = useSettingsStyles();
  const {t} = useTranslation();

  const handleCancelPlan = () => {
    unSubscribe(
      {},
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryConstants.GET_USER_INFO);
          queryClient.invalidateQueries(queryConstants.USER_WALLETS_SUMMARY);
          dispatch(
            setToaster({
              type: "success",
              message: t("plan_unsubscribed_success_toast_message"),
            }),
          );
        },
        onSettled: () => {
          setIsCancelPaymentPlanModalOpen(false);
        },
      },
    );
  };

  const isAdvancedPortfolio = R.equals(planKey, "advanced_portfolio");
  const isTaxPlanActive = R.includes(activeProductKey, [
    PRO,
    BEGINNER,
    ADVANCED,
  ]);

  const subscriptionChipTooltipTitle = () => {
    const sortedYears = subscribedForYears.sort((a: any, b: any) => a - b);
    const yearsExceptLast = sortedYears.slice(0, -1);
    const initialText = R.join(", ", yearsExceptLast);
    const finalYearText = `${
      subscribedForYears[R.length(subscribedForYears) - 1]
    }`;

    return `${initialText} ${t("and")} ${finalYearText}`;
  };

  const subscribedYearsCountChipText = `${subscribedYearsCount} ${
    R.equals(subscribedYearsCount, 1) ? t("year") : t("years")
  }`;

  const subscribedYearsCountToltipText = () => {
    if (R.equals(subscribedYearsCount, 1)) {
      return `${t("subscribed_plan_tooltip_text", {
        planKey: `${t(planKey)} ${t("plan")}`,
      })} ${subscribedForYears[0]}`;
    }
    return `${t("subscribed_plan_tooltip_text", {
      planKey: `${t(planKey)} ${t("plan")}`,
    })} ${subscriptionChipTooltipTitle()}`;
  };

  const isBasicPortfolio = R.equals(planKey, FREE);

  const renderButtonText = () => {
    if (!isTaxPlanActive && isAdvancedPortfolio) {
      return t("subscribe");
    }
    if (
      isTaxPlanActive &&
      isAdvancedPortfolio &&
      isAdvancePortfolioSubscribed
    ) {
      return t("cancel_subscription");
    }
    if (
      isTaxPlanActive &&
      isAdvancedPortfolio &&
      !isAdvancePortfolioSubscribed
    ) {
      return t("subscribe");
    }
    if (isSubscribed || R.gt(subscribedYearsCount, 0)) {
      return t("buy_another");
    }

    return t("buy_plan");
  };

  const isBought =
    isTaxPlanActive &&
    !isBasicPortfolio &&
    !isAdvancedPortfolio &&
    R.gt(subscribedYearsCount, 0);

  const showTwoChips = (isBestSelling || isPlanRecommended) && isBought;

  const recommendedChip = isPlanRecommended ? (
    <CustomChip
      label={
        <CustomTooltip
          borderRadius="8px"
          title={
            t("recommended_plan_tooltip", {taxYear: recentTaxYear}) as string
          }
          arrow
          placement="top"
        >
          <Box className={classes.planCardChip}>
            <span>{t("recommended")}</span>
          </Box>
        </CustomTooltip>
      }
      color={"default"}
      variant="filled"
      customDefaultBgColor={"rgba(255, 100, 26, 0.1)"}
      customDefaultTextColor={"#1B2126"}
      className={classes.chipStyle}
    />
  ) : null;

  const bestSellingChip =
    isBestSelling && !isPlanRecommended ? (
      <CustomChip
        key={index}
        label={
          <Box className={classes.planCardChip}>
            <FireIcon /> <span>{t("best_selling")}</span>
          </Box>
        }
        color={"default"}
        variant="filled"
        className={classes.chipStyle}
        customDefaultBgColor={"rgba(255, 100, 26, 0.1)"}
        customDefaultTextColor={"#1B2126"}
      />
    ) : null;

  return (
    <Box
      id={id}
      className={classNames({
        [classes.greenBorder]:
          (isFreeTier && isBasicPortfolio) || isPlanRecommended,
        [classes.planBoxStyle]: true,
        [classes.bestSellingPlanBorder]: isBestSelling,
        [classes.currentPlanBorder]: isSubscribed,
      })}
      key={index}
    >
      <Box
        className={classNames({
          [classes.planChip]: true,
          [classes.justifyStart]:
            (isBestSelling || isPlanRecommended) && !isBought,
          [classes.justifyBetween]: showTwoChips,
        })}
        id={`${planKey}_plan`}
      >
        {bestSellingChip}
        {recommendedChip}
        {isBought && (
          <CustomChip
            id="current-plan"
            key={index}
            label={
              <CustomTooltip
                borderRadius="8px"
                title={subscribedYearsCountToltipText()}
                arrow
                placement="top"
              >
                <Box className={classes.planCardChip}>
                  <TickIcon size={"14"} />{" "}
                  <span>
                    {R.gt(subscribedYearsCount, 0)
                      ? !isTaxPlanActive && isAdvancedPortfolio
                        ? t("current")
                        : subscribedYearsCountChipText
                      : t("current")}
                  </span>
                </Box>
              </CustomTooltip>
            }
            color={"success"}
            variant="outlined"
            className={classNames({
              [classes.chipStyle]: true,
              [classes.chipMargin]: isBestSelling,
            })}
          />
        )}

        {isFreeTier && isBasicPortfolio && (
          <CustomChip
            key={index}
            label={
              <Box className={classes.planCardChip}>
                <span>{t("current_plan")}</span>
              </Box>
            }
            color={"success"}
            variant="outlined"
            className={classes.chipStyle}
          />
        )}
      </Box>

      <Box
        className={classNames({
          [classes.flexRowSimple]: true,
          [classes.fullWidth]: true,
        })}
      >
        {planIconsMap(planKey, "md")}
        <Box className={classes.planNameBox}>
          <DescriptionStyled size="md" color="secondary" noPadding id="title">
            {t(planKey)}
          </DescriptionStyled>
          <Box className={classes.flexRowSimple}>
            <TitleStyled size="xl1" mediumBold noPadding id="price">
              <span>{currency === "eur" ? "€" : "$"}</span>
              {`${getFormattedCurrency(price)}`}
            </TitleStyled>
            <DescriptionStyled
              size="md"
              color="secondary"
              noPadding
              className={classes.intervalMargin}
            >
              {`/${isMonthlyPlan ? "month" : "tax year"}`}
            </DescriptionStyled>
          </Box>
        </Box>
      </Box>
      <DescriptionStyled
        size="md"
        color="secondary"
        className={classes.planDesp}
        id="description"
      >
        {t(planDescription)}
      </DescriptionStyled>
      <RenderFeatures
        planFeatures={plansFeatures}
        isBlueTicksPlan={isBlueTicksPlan}
      />
      {!isBasicPortfolio && (
        <CustomButton
          id="upgrade-btn"
          text={
            isAdvancedPortfolio && isTaxPlanActive ? (
              <CustomTooltip
                borderRadius="8px"
                padding="16px"
                title={`Advanced/Basic tax plan is purchased, you have ${activePeriod} of free advanced portfolio added.`}
                arrow
                placement="top"
              >
                <span>{renderButtonText()}</span>
              </CustomTooltip>
            ) : (
              <span>{renderButtonText()}</span>
            )
          }
          variant={isBestSelling ? "primary" : "outlined"}
          disableRipple
          disabled={isTaxPlanActive && isAdvancedPortfolio}
          // else condition to be added
          onClick={
            !isMonthlyPlan
              ? () => handleUpgrade(plan)
              : () => {
                  switchToCheckout(plan);
                }
          }
          className={classNames({
            [classes.upgradePlanOutlinedButton]: !isBestSelling,
            [classes.upgradePlanButton]: isBestSelling && !isMonthlyPlan,
            [classes.disabledStyle]: isTaxPlanActive && isAdvancedPortfolio,
            [classes.buttonsMargin]: true,
          })}
        />
      )}

      {isCancelPaymentPlanModalOpen && (
        <CancelPaymentPlanModal
          isOpen={isCancelPaymentPlanModalOpen}
          handleCancel={handleCancelPlan}
          handleClose={() => setIsCancelPaymentPlanModalOpen(false)}
        />
      )}
    </Box>
  );
};

const PaymentPlans = (props: PaymentPlansType) => {
  const {
    setisCheckout,
    setProductID,
    setPriceID,
    setTaxYearID,
    selectedPlan,
    setSelectedPlan,
    handleInvoiceGeneration,
    setSelectedYearTitle,
  } = props;

  const classes = useSettingsStyles();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const {state} = useLocation();

  const handleBackNavigation = () => {
    const {backNavLink = ""} = state;
    if (isSomething(backNavLink)) {
      navigate(backNavLink);
    }
  };

  const backBtnName = R.pathOr("", ["backNavPage"], state);

  const selectedPlanID: string = searchParams.get("plan") || "";
  const planContainer = document.getElementById(`${selectedPlanID}`);

  const [openPlanYearModal, setPlanYearModal] = useState(false);
  const [selectedYearID, setSelectedYearID] = useState<number>(-1);
  const [selectedYearFormattedValue, setSelectedYearFormattedValue] =
    useState<string>("");

  const handleUpgrade = (plan: any) => {
    setSelectedPlan(plan);
    setProductID(R.propOr("", "id", plan));
    setPriceID(R.pathOr("", ["prices", 0, "id"], plan));
    setPlanYearModal(true);
  };

  const switchToCheckout = async (plan: any) => {
    setSelectedPlan(plan);

    setTaxYearID("");
    setSelectedYearTitle("");
    await setProductID(R.propOr("", "id", plan));
    await setPriceID(R.pathOr("", ["prices", 0, "id"], plan));
    await handleInvoiceGeneration();
  };

  const {data, isLoading, isFetching} = useFetchPaymentPlans();
  const productPlans: any = R.pipe(
    //@ts-ignore
    R.pathOr([], ["data", "results"]),
  )(data);

  const {yearsList}: TaxYearsProps = useGetTaxYearsWithSubscriptionInfo();

  useEffect(() => {
    if (!isSomething(selectedPlanID)) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
    if (isSomething(yearsList) && state?.year) {
      const {plan, year} = state;
      const yearTitle = R.pipe(
        R.filter(R.propEq("id", year)),
        R.head,
        R.propOr("", "year"),
      )(yearsList);

      const result = productPlans.find(
        (item: any) => item.metadata.product === plan,
      );

      handleUpgrade(result);
      setSelectedYearTitle(yearTitle as string);
      setTaxYearID(year);

      setSelectedYearFormattedValue(
        getFormattedYearValueWithMMM(year, yearsList),
      );
      setSelectedYearID(Number(year));
    }
  }, []);

  const isLoadingAndFetching = isLoading || isFetching;
  const isMobileView = getIsMobileView();
  const DEFAULT_CARDS_COUNT = 4;

  const response = useFetchInvoicesDetails();
  const invoicesData = R.pathOr([], ["data", "data", "results"], response);

  const {app} = useSelector((state: RootState) => state);
  const taxYears: taxYearType[] = R.propOr([], "taxYears", app);

  const recentTaxYear: string = R.pathOr(
    "",
    [taxYears.length - 2, "title"],
    taxYears,
  );

  const activeProduct = useGetActiveProductInfo();
  const activeProductKey = R.propOr("", "productKey", activeProduct);

  const transformedData = invoicesData.reduce((acc: any, invoice: any) => {
    const productName = R.pathOr("", ["product", "name"], invoice);
    const subscribedYearId = R.pathOr("", ["metadata", "tax_year_id"], invoice);

    if (!acc.has(productName)) {
      const subscribedYearTitle = R.pipe(
        R.filter(R.propEq("id", Number(subscribedYearId))),
        R.head,
        R.propOr("", "title"),
      )(taxYears);

      acc.set(productName, {
        name: productName,
        subscribedYears: [subscribedYearTitle],
      });
    } else {
      const subscribedYearTitle = R.pipe(
        R.filter(R.propEq("id", Number(subscribedYearId))),
        R.head,
        R.propOr("", "title"),
      )(taxYears);

      acc.get(productName).subscribedYears.push(subscribedYearTitle);
    }

    return acc;
  }, new Map());

  const transformedSubscriptionData = Array.from(transformedData.values());

  const bestSellingPlan = () => {
    if (
      !R.equals(activeProductKey, ADVANCED) &&
      !R.equals(activeProductKey, PRO)
    ) {
      return ADVANCED;
    }
    if (R.includes(activeProductKey, [ADVANCED, PRO])) {
      return PRO;
    }

    return "";
  };

  const blueTicksPlansList = () => {
    if (
      !R.equals(activeProductKey, ADVANCED) &&
      !R.equals(activeProductKey, PRO) &&
      !R.equals(activeProductKey, "advanced_portfolio")
    ) {
      return [ADVANCED, PRO, "advanced_portfolio"];
    }
    if (R.equals(activeProductKey, "advanced_portfolio")) {
      return [ADVANCED, PRO];
    }
    if (R.equals(activeProductKey, ADVANCED)) {
      return [PRO];
    }
    if (R.equals(activeProductKey, PRO)) {
      return [];
    }
    return [];
  };

  useEffect(() => {
    if (isSomething(selectedPlanID)) {
      if (!!planContainer) {
        planContainer?.scrollIntoView({block: "start", behavior: "smooth"});
      }
    }
  }, [planContainer]);

  return (
    <Box className={classes.paymentPlanRoot}>
      {isSomething(backBtnName) && (
        <Box
          className={classNames({
            [classes.flexCol]: true,
            [classes.header]: true,
          })}
        >
          <CustomButton
            text={t(backBtnName)}
            variant="info"
            startIcon={<ArrowBackSharpIcon />}
            onClick={handleBackNavigation}
          />
        </Box>
      )}
      <TitleStyled
        size={isMobileView ? "xsm" : "md"}
        className={classes.centeredTitle}
        id="title"
      >
        {t("upgrade_to_manage_and_save_your_taxes")}
      </TitleStyled>
      <Box
        className={classNames({
          [classes.flexRow]: true,
          [classes.plansBoxRoot]: true,
          [classes.planCardsMobileView]: isMobileView,
        })}
      >
        {!isLoadingAndFetching &&
          mapIndexed((plan: any, index: number) => {
            const planName = R.pathOr("--", ["name"], plan);

            const planKey: string = R.pathOr("", ["metadata", "product"], plan);
            const isBlueTicksPlan = R.includes(planKey, blueTicksPlansList());
            const isAdvancePortfolioSubscribed =
              R.findIndex(R.propEq("name", "Beginner"))(
                transformedSubscriptionData as any,
              ) !== -1;

            // @ts-ignore
            const subscribedForYears: [] = R.pipe(
              R.filter(R.propEq("name", planName)),
              R.head,
              R.propOr([], "subscribedYears"),
            )(transformedSubscriptionData);

            return (
              <PlanCard
                plan={plan}
                key={index}
                index={index}
                handleUpgrade={handleUpgrade}
                subscribedYearsCount={R.length(subscribedForYears)}
                setisCheckout={setisCheckout}
                switchToCheckout={switchToCheckout}
                id={`plan-card-${index + 1}`}
                isBlueTicksPlan={isBlueTicksPlan}
                isAdvancePortfolioSubscribed={isAdvancePortfolioSubscribed}
                subscribedForYears={subscribedForYears}
                recentTaxYear={recentTaxYear}
                productPlans={productPlans}
              />
            );
          }, productPlans)}
        {isLoadingAndFetching &&
          R.map(() => <PlanCardSkeleton />, [...Array(DEFAULT_CARDS_COUNT)])}
      </Box>

      <Box marginTop={"48px"}>
        <PaymentPlansComparison
          productPlans={productPlans}
          handleUpgrade={handleUpgrade}
        />
      </Box>

      {!isMobileView && (
        <Box className={classes.footer}>
          <Divider />
          <Box className={classes.footerButtonContainer}>
            <CustomButton
              text={t("back")}
              variant="outlined"
              onClick={() => navigate("/settings/plan-&-payment")}
            />
          </Box>
        </Box>
      )}
      {openPlanYearModal && (
        <PaymentPlanYearSelectionModal
          showBackButton={isSomething(state?.year)}
          selectedYearID={selectedYearID}
          selectedYearFormattedValue={selectedYearFormattedValue}
          setSelectedYearID={setSelectedYearID}
          setSelectedYearFormattedValue={setSelectedYearFormattedValue}
          open={openPlanYearModal}
          handleClose={setPlanYearModal}
          handleOrderReview={() => {
            handleInvoiceGeneration();
          }}
          planType={R.propOr("", "name", selectedPlan)}
          setTaxYearID={setTaxYearID}
          setSelectedYearTitle={setSelectedYearTitle}
          planKey={R.pathOr("", ["metadata", "product"], selectedPlan)}
          transactionsLimit={R.pathOr(
            "",
            ["metadata", "num_transactions"],
            selectedPlan,
          )}
        />
      )}
    </Box>
  );
};

export default PaymentPlans;
