// lib
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

// src
import {AppState, metaType, metaCostBasisTrackingObject} from "store/app/types";

export const metaInitialState = {
  currencies: [],
  taxResidences: [],
  languages: [],
  costBasis: [],
  costTracking: [],
};
const initialState: AppState = {
  meta: metaInitialState,
  cryptoCurrenciesList: [],
  fiatCurrenciesList: [],
  tagsList: [],
  taxYears: [],
};

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setMeta(state, {payload}: PayloadAction<metaType>) {
      state.meta = {...state.meta, ...payload};
    },
    setCostBasis(
      state,
      {payload}: PayloadAction<metaCostBasisTrackingObject[]>,
    ) {
      state.meta = {...state.meta, costBasis: payload};
    },
    setCostTracking(
      state,
      {payload}: PayloadAction<metaCostBasisTrackingObject[]>,
    ) {
      state.meta = {...state.meta, costTracking: payload};
    },
    setCryptoCurrenciesList(state, {payload}: PayloadAction<[]>) {
      state.cryptoCurrenciesList = payload;
    },
    setFiatCurrenciesList(state, {payload}: PayloadAction<[]>) {
      state.fiatCurrenciesList = payload;
    },
    setTagsList(state, {payload}: PayloadAction<[]>) {
      state.tagsList = payload;
    },
    setTaxYears(state, {payload}: PayloadAction<[]>) {
      state.taxYears = payload;
    },
  },
});

export const {
  setMeta,
  setCryptoCurrenciesList,
  setFiatCurrenciesList,
  setCostBasis,
  setCostTracking,
  setTaxYears,
  setTagsList,
} = appSlice.actions;

export default appSlice.reducer;
