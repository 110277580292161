// lib
import {createSlice} from "@reduxjs/toolkit";

// src
import {UiStateType} from "store/ui/types";
import {toasterType} from "components/toaster/toaster";

const initialState: UiStateType = {
  loading: false,
  snackBarState: null,
  langChanged: false,
};

const uiSlice = createSlice({
  name: "ui",
  initialState: initialState,
  reducers: {
    setLoadingState(state, {payload}: any) {
      state.loading = payload;
    },
    setToaster(state, {payload}: {payload: toasterType | null}) {
      state.snackBarState = payload;
    },
    setHasLanguageChanged(state, {payload}: any) {
      state.langChanged = payload;
    },
  },
});

export const {setLoadingState, setToaster, setHasLanguageChanged} =
  uiSlice.actions;

export default uiSlice.reducer;
// export default loadingSlice.reducer;
