// lib
import * as React from "react";
import {useSelector} from "react-redux";
import {BrowserRouter} from "react-router-dom";
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import * as R from "ramda";
import {GoogleOAuthProvider} from "@react-oauth/google";
import mixpanel from "mixpanel-browser";

// src
import {RootState} from "./store";
import {Header} from "./components/header";
import {loadStripe} from "@stripe/stripe-js";
import {Elements} from "@stripe/react-stripe-js";
import Toaster from "./components/toaster/toaster";
import {getIsMobileView, getLocalStorageLanguage, isSomething} from "./utils";
import {useGetUserLanguage} from "./utils/meta-hooks";
import LoadingState from "./components/loader/loading-state";
import {useAuthenticateUser} from "services/api/settings";

// styles
import {AppTheme} from "./theme/theme";
import "./app.css";
import classNames from "classnames";
import {useEffect} from "react";
import {useFetchMetaData} from "services/api/meta";

const PUBLISHED_KEY = process.env.REACT_APP_STRIPE_PUBLISHED_KEY || "";
const stripePromise = loadStripe(PUBLISHED_KEY);

const AuthenticatedApp = React.lazy(
  () => import(/* webpackPrefetch: true */ "./authenticated-app"),
);
const UnauthenticatedApp = React.lazy(() => import("./un-authenticated-app"));

// initialize mixpanel
mixpanel.init(process.env.REACT_APP_MIXPANEL_PROJECT_TOKEN || "", {
  debug: true,
});

function App(props: any) {
  const {i18n} = props;
  const {token, user, appType} = useSelector((state: RootState) => state.auth);
  const {loading, snackBarState} = useSelector((state: RootState) => state.ui);
  const {languageCode, languageFileName} = useGetUserLanguage();
  const userEmail = R.pathOr("", ["email"], user);
  const contentLanguage = getLocalStorageLanguage();

  useFetchMetaData({});

  useEffect(() => {
    console.log("change called:::", languageFileName);
    if (isSomething(languageFileName) && i18n) {
      i18n.changeLanguage(languageFileName);
    } else if (isSomething(contentLanguage) && i18n) {
      i18n.changeLanguage(contentLanguage);
    }
  }, [languageCode, languageFileName, i18n, contentLanguage]);

  useEffect(() => {
    const scriptWithKey = document.createElement("script");
    const key = process.env.REACT_APP_ZENDESK_KEY || "";

    scriptWithKey.src = `https://static.zdassets.com/ekr/snippet.js?key=${key}`;
    scriptWithKey.id = "ze-snippet";

    const settingsScript = document.createElement("script");
    scriptWithKey.onload = () => {
      settingsScript.innerHTML = `
        window.zESettings = {
          launcher: {
            label: {
              'en-US': 'Feedback',
              de: "Feedback"
            }
          },
          color: {
            theme: '#FBBF21',
            launcher: '#FBBF21',
            launcherText: '#1B2126 !important',
            button: '#FBBF21',
            buttonText: '#FFFFFF !important',
            buttonTextColor: '#FFFFFF !important',
            header: '#FBBF21',
          },
          contactForm: {
            fields: [
              { id: 'email', prefill: { '*': '${userEmail}' } },
            ]
          }
        };
  
        // Set the locale to user's language
        zE('webWidget', 'setLocale', '${contentLanguage}');
  
        // Update the widget settings
        zE('webWidget', 'updateSettings', window.zESettings);
      `;
      document.body.appendChild(settingsScript);
    };

    document.body.appendChild(scriptWithKey);
    return () => {
      document.body.removeChild(scriptWithKey);
      document.body.removeChild(settingsScript);
    };
  }, [contentLanguage, userEmail]);

  const {isLoading} = useAuthenticateUser({});

  const userPermissionsList = R.pathOr("", ["role", "permissions"], user);
  const isSuperUser = R.pathEq(["is_superuser"], true, user);
  const isUserTypeAdmin = isSomething(userPermissionsList) || isSuperUser;

  const isAdminAppView = R.equals(appType, "admin") && isUserTypeAdmin;

  const isMobileView = getIsMobileView();
  const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_SSO_CLIENT_ID || "";

  return (
    <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
      <React.Suspense fallback={<LoadingState />}>
        <div
          className={classNames({
            App: true,
            "no-top-padding": isMobileView,
          })}
        >
          <ThemeProvider theme={AppTheme}>
            <CssBaseline />
            {(loading || isLoading) && <LoadingState />}
            {snackBarState && <Toaster state={snackBarState} />}
            {!isLoading && (
              <BrowserRouter>
                <Elements stripe={stripePromise}>
                  {!isMobileView && (
                    <Header
                      isLogIn={token ? true : false} //'token ? true : false' can be simplified to '!!token'
                      user={R.propOr("", "name", user)}
                      isAdminAppView={isAdminAppView}
                      isUserTypeAdmin={isUserTypeAdmin}
                      i18n={i18n}
                    />
                  )}
                  <main
                    id="main"
                    className={classNames({
                      "main-container": true,
                      "less-padding": isMobileView,
                    })}
                  >
                    {token ? (
                      <AuthenticatedApp
                        user={user}
                        isAdminAppView={isAdminAppView}
                        userPermissionsList={userPermissionsList}
                        isSuperUser={isSuperUser}
                      />
                    ) : (
                      <UnauthenticatedApp />
                    )}
                  </main>
                </Elements>
              </BrowserRouter>
            )}
          </ThemeProvider>
        </div>
      </React.Suspense>
    </GoogleOAuthProvider>
  );
}

export default App;
