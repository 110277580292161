// lib
import {Box, Modal} from "@mui/material";
import {useTranslation} from "react-i18next";
// src
import classNames from "classnames";
import {getIsMobileView} from "utils";
import {DescriptionStyled, TitleStyled} from "styles/common";
import {CustomButton} from "submodules/capital-bee-web-ui-kit/src/components/CustomButton";
// styles
import {useCancelPaymentModalStyles} from "./cancel-payment-plan-modal.styles";

interface DeletePaymentMethodModalProps {
  handleCancel: () => void;
  handleClose: () => void;
  isOpen: boolean;
}

const CancelPaymentPlanModal = (props: DeletePaymentMethodModalProps) => {
  const {handleCancel, isOpen, handleClose} = props;
  const classes = useCancelPaymentModalStyles();
  const {t} = useTranslation();
  const isMobileView = getIsMobileView();

  return (
    <Modal open={isOpen} onClose={handleClose}>
      <Box
        className={classNames({
          [classes.removeModalWrapper]: true,
          [classes.mobileViewWrapper]: isMobileView,
        })}
      >
        <TitleStyled size="md" bold>
          {t("cancel_plan")}
        </TitleStyled>
        <DescriptionStyled size="lg" color="secondary">
          {t("cancel_plan_desp")}
        </DescriptionStyled>
        <Box className={classes.buttonWrap}>
          <CustomButton
            text={t("remove")}
            onClick={() => handleCancel()}
            variant="danger"
          />
          <CustomButton
            text={t("cancel")}
            variant="outlined"
            onClick={handleClose}
          />
        </Box>
      </Box>
    </Modal>
  );
};
export default CancelPaymentPlanModal;
