//lib
import * as R from "ramda";
import {useQuery} from "react-query";
import {useDispatch} from "react-redux";

//src
import {axiosCall} from "services/api/";
import {endpoints, queryConstants} from "constants/";
import {
  metaInitialState,
  setMeta,
  setTaxYears,
  setCryptoCurrenciesList,
  setFiatCurrenciesList,
} from "store/app/app-slice";
import {setLocalStorageLanguage} from "utils";

//************************************************************************************************************************* */

type useFetchMetaDataProps = {
  handleSuccess?: (responseData: any) => void;
};

export const useFetchMetaData = (props: useFetchMetaDataProps) => {
  const {handleSuccess} = props;
  const dispatch = useDispatch();

  return useQuery(
    queryConstants.META_DATA_QUERY,
    () => {
      return axiosCall({url: endpoints.META_DATA});
    },
    {
      onSuccess: (data: any) => {
        const contentLanguage = R.pathOr(
          "",
          ["headers", "content-language"],
          data,
        );
        setLocalStorageLanguage(contentLanguage);

        handleSuccess && handleSuccess(data);
        const metaDataForStore = R.pathOr(metaInitialState, ["data"], data);
        dispatch(setMeta(metaDataForStore));
      },
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

type useFetchTransactionCurrenciesProps = {
  handleSuccess?: (responseData: any) => void;
  type: string;
};

export const useFetchTransactionCurrencies = (
  props: useFetchTransactionCurrenciesProps,
) => {
  const {handleSuccess, type} = props;
  const dispatch = useDispatch();
  return useQuery(
    [queryConstants.CURRENCIES_LIST, type],
    () => {
      return axiosCall({
        url: endpoints.TRANSACTION_CURRENCIES(type),
      });
    },
    {
      onSuccess: (data: any) => {
        const currenciesList: any = R.pathOr([], ["data", "results"], data);

        handleSuccess && handleSuccess(currenciesList);

        R.equals(type, "crypto") &&
          dispatch(setCryptoCurrenciesList(currenciesList));

        R.equals(type, "fiat") &&
          dispatch(setFiatCurrenciesList(currenciesList));
      },
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};

type useFetchTaxYearsProps = {
  handleSuccess?: (responseData: any) => void;
};

export const useFetchTaxYears = (props: useFetchTaxYearsProps) => {
  const {handleSuccess} = props;
  const dispatch = useDispatch();

  return useQuery(
    queryConstants.TAX_YEARS,
    () => {
      return axiosCall({url: endpoints.TAX_YEARS});
    },
    {
      onSuccess: (data: any) => {
        handleSuccess && handleSuccess(data.data.results);
        dispatch(setTaxYears(data.data.results));
      },
      onError: (error: any) => {
        console.log(error);
      },
    },
  );
};
