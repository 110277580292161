export const HeaderLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="187"
      height="30"
      viewBox="0 0 187 30"
      fill="none"
    >
      <path
        d="m67.682 21.328-2.106-3.539H58.92l1.16-1.995h4.315l-3.133-5.263-6.385 10.794H52.07l8.085-13.466c.126-.226.298-.423.504-.58.202-.145.447-.22.697-.212a1.09 1.09 0 0 1 .686.213c.203.157.37.354.494.579l8.105 13.466-2.96.003zM76.39 17.42v-2.378h5.652a2.713 2.713 0 0 0 2.021-.736 2.634 2.634 0 0 0 .723-1.936 2.59 2.59 0 0 0-.723-1.933 2.753 2.753 0 0 0-2.021-.716H74.59v11.608h-2.437V7.264h9.888a6.179 6.179 0 0 1 2.175.357 4.534 4.534 0 0 1 1.615 1.007c.448.445.791.983 1.005 1.577a6.378 6.378 0 0 1 0 4.167 4.436 4.436 0 0 1-2.617 2.672 5.887 5.887 0 0 1-2.175.376H76.39zM89.064 21.329V7.264h2.456V21.33h-2.456zM98.602 21.328V9.714H93.26V7.257H106.4v2.457h-5.341v11.608l-2.456.006zM120.039 21.328l-2.11-3.539h-6.656l1.161-1.995h4.314l-3.13-5.263-6.385 10.794h-2.806l8.089-13.466c.124-.227.296-.425.504-.579a1.13 1.13 0 0 1 .696-.213c.246-.007.488.067.687.213.201.158.368.355.491.579l8.108 13.466-2.963.003zM124.507 21.329V7.264h2.456v11.608h9.463v2.457h-11.919zM135.186 15.002v-1.635h4.645v1.635h-4.645z"
        fill="#fff"
      />
      <path
        d="M141.551 21.328V7.264h10.294c.63-.008 1.258.07 1.867.232a4.161 4.161 0 0 1 1.443.687c.397.3.716.691.929 1.141.226.495.338 1.034.327 1.577.008.4-.044.797-.154 1.18-.092.31-.235.6-.425.861a2.598 2.598 0 0 1-.628.599c-.229.157-.48.278-.746.36.634.197 1.189.59 1.586 1.121.431.595.649 1.318.619 2.051a4.637 4.637 0 0 1-.327 1.75 3.812 3.812 0 0 1-.91 1.344c-.411.385-.9.678-1.432.86a5.474 5.474 0 0 1-1.868.301h-10.575zm4.252-6.113v-2.07h6.074a2.543 2.543 0 0 0 1.551-.386c.327-.259.5-.697.5-1.309a1.662 1.662 0 0 0-.212-.899 1.364 1.364 0 0 0-.609-.52 2.986 2.986 0 0 0-.948-.242 11.66 11.66 0 0 0-1.253-.075h-6.895v9.158h7.117a7.05 7.05 0 0 0 1.22-.098 3.1 3.1 0 0 0 .982-.327c.254-.134.47-.331.628-.573a1.61 1.61 0 0 0 .222-.87 1.7 1.7 0 0 0-.118-.733 1.698 1.698 0 0 0-.422-.61 2.346 2.346 0 0 0-1.589-.475l-6.248.03zM158.812 21.329V7.264h12.556v2.45h-10.12v9.158h10.14v2.457h-12.576zm4.235-6.114v-2.07h7.811v2.07h-7.811zM173.495 21.329V7.264h12.556v2.45h-10.116v9.158h10.139v2.457h-12.579zm4.252-6.114v-2.07h7.797v2.07h-7.797z"
        fill="#FBBF21"
      />
      <path
        d="M43.403 21.328a6.61 6.61 0 0 1-2.718-.552 6.673 6.673 0 0 1-3.598-3.782 7.725 7.725 0 0 1-.514-2.842 7.479 7.479 0 0 1 .514-2.826c.32-.818.806-1.56 1.43-2.178a6.44 6.44 0 0 1 2.161-1.397 7.291 7.291 0 0 1 2.718-.494h7.621v2.457h-7.62a4.51 4.51 0 0 0-1.75.327c-.52.215-.992.534-1.384.938-.39.42-.694.912-.893 1.45a4.907 4.907 0 0 0-.327 1.838 5.002 5.002 0 0 0 .327 1.838c.203.543.512 1.04.91 1.462.39.409.862.734 1.383.955a4.344 4.344 0 0 0 1.75.35h7.62v2.456h-7.63z"
        fill="#fff"
      />
      <path
        d="M42.997 3.908h-1.495v3.925h1.495V3.908zM48.561 3.908h-1.495v3.925h1.495V3.908zM42.997 20.894h-1.495v3.925h1.495v-3.925zM48.561 20.894h-1.495v3.925h1.495v-3.925z"
        fill="#fff"
      />
      <path
        d="M20.48 19.658c-1.899 0-4.169-1.645-5.208-6.343a.336.336 0 0 1 .51-.356.34.34 0 0 1 .148.212c1.013 4.566 3.164 5.994 4.849 5.813a2.53 2.53 0 0 0 2.28-2.497c0-2.507-5.702-5.23-8.05-6.132-2.35.901-8.05 3.618-8.05 6.132a2.53 2.53 0 0 0 2.28 2.497c1.68.184 3.835-1.247 4.848-5.813a.337.337 0 0 1 .658.145c-1.108 4.99-3.619 6.536-5.563 6.322a3.188 3.188 0 0 1-2.872-3.151c-.03-3.527 8.228-6.669 8.557-6.8a.345.345 0 0 1 .23 0c.352.131 8.597 3.273 8.597 6.797a3.224 3.224 0 0 1-3.224 3.171l.01.003z"
        fill="#FBBF21"
      />
      <path
        d="M14.989 24.801a.33.33 0 0 1-.178-.053 12.502 12.502 0 0 1-2.632-2.23c-.51-.658-.704-2.116-.723-2.28a.332.332 0 0 1 .658-.082c.049.398.25 1.523.588 1.974.68.754 1.454 1.417 2.303 1.974.85-.557 1.624-1.22 2.303-1.974.33-.435.54-1.56.59-1.974a.331.331 0 1 1 .657.082c-.02.165-.214 1.645-.724 2.28a12.428 12.428 0 0 1-2.631 2.23.33.33 0 0 1-.211.053z"
        fill="#fff"
      />
      <path
        d="M16.792 21.7h-3.595a.33.33 0 0 1 0-.658h3.595a.33.33 0 0 1 0 .658zM16.434 19.252h-2.885a.329.329 0 1 1 0-.658h2.888a.329.329 0 1 1 0 .658h-.003zM15.653 16.945h-1.316a.329.329 0 0 1 0-.658h1.316a.329.329 0 0 1 0 .658z"
        fill="#fff"
      />
      <path
        d="M17.193 9.462a.33.33 0 0 1-.329-.29c-.177-1.53-1.224-2.24-1.875-2.24s-1.694.71-1.875 2.24a.329.329 0 0 1-.655-.078c.207-1.767 1.451-2.823 2.53-2.823 1.08 0 2.323 1.056 2.53 2.823a.33.33 0 0 1-.286.368h-.04zM15.024 6.02c-1.092-.096-1.974-1.037-2.01-1.077a.33.33 0 1 1 .484-.447s.75.793 1.526.865c.72-.072 1.46-.858 1.467-.865a.33.33 0 1 1 .484.447c-.036.04-.918.98-1.951 1.076z"
        fill="#FBBF21"
      />
      <path
        d="M14.992 30A15.025 15.025 0 0 0 29.94 16.278a1.132 1.132 0 0 0 .033-2.135A14.996 14.996 0 0 0 4.642 4.155a1.135 1.135 0 0 0-1.444 1.596A14.979 14.979 0 0 0 .53 18.949a.658.658 0 0 0-.158.428.678.678 0 0 0 1.355 0 .658.658 0 0 0-.411-.622 14.318 14.318 0 0 1-.503-3.763c0-3.16 1.056-6.23 3.003-8.718.12.044.247.068.375.069a1.138 1.138 0 0 0 1.027-1.619 14.18 14.18 0 0 1 23.946 9.422 1.138 1.138 0 0 0-.033 2.11A14.19 14.19 0 0 1 8.255 27.482a.678.678 0 1 0-.368.622l-.043.082A15.038 15.038 0 0 0 14.992 30zM4.178 5.8a.599.599 0 1 1 0-1.197.599.599 0 0 1 0 1.197zm24.792 9.406a.599.599 0 1 1 1.198 0 .599.599 0 0 1-1.198 0z"
        fill="#fff"
      />
      <path
        d="M4.541 22.644c.069 0 .137-.006.204-.02a12.778 12.778 0 0 0 15.873 3.85.678.678 0 1 0-.358-.727 11.959 11.959 0 0 1-14.804-3.55 1.129 1.129 0 0 0-.094-1.498 1.142 1.142 0 0 0-1.035-.312A11.962 11.962 0 0 1 21.474 4.925c-.025.094-.038.19-.04.286a1.141 1.141 0 0 0 1.139 1.142c.193-.003.383-.054.552-.148a11.959 11.959 0 0 1 2.85 13.587.658.658 0 1 0 .72.381A12.778 12.778 0 0 0 23.648 5.58a1.139 1.139 0 0 0-1.704-1.316A12.774 12.774 0 0 0 3.64 20.83a1.135 1.135 0 0 0 .914 1.813h-.013zm0-1.737a.599.599 0 1 1 0 1.198.599.599 0 0 1 0-1.198zM22.56 4.616a.599.599 0 1 1 0 1.198.599.599 0 0 1 0-1.198z"
        fill="#fff"
      />
    </svg>
  );
};
