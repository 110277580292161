// lib
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import resourcesToBackend from "i18next-resources-to-backend";
import axios from "axios";

console.dir(`${process.env.PUBLIC_URL}`);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(
    resourcesToBackend((language, namespace, callback) => {
      console.log("=====>>", { namespace, language });
      if (namespace !== "translation") {
        axios
          // .get(`/locales/${namespace}.${language}.json`)
          .get(`/locales/${language}.json`)
          .then(res => {
            if (res.status === 200) {
              callback(null, res.data);
            }
          })
          .catch(error => {
            console.log(error.toString());
            callback(error, null);
          });
      }
    }),
  )
  .init({
    fallbackLng: "en",
    lng: "en",
    ns: ["cba"],
    debug: false,
    // detection: {
    //   lookupLocalStorage: "cba_locale",
    // },
    interpolation: {
      escapeValue: false,
    },
    // backend: {
    //   loadPath: 'http://localhost:3000/locales/{{ns}}.{{lng}}.json'
    // }
  });

export default i18n;
