//lib
import * as R from "ramda";
import Box from "@mui/material/Box";
import React, {useState} from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import {useSelector} from "react-redux";
import ArrowDropDownOutlinedIcon from "@mui/icons-material/ArrowDropDownOutlined";

//src
import {RootState} from "store";
import {useStyles} from "./user-menu.styles";
import {DescriptionStyled} from "styles/common";
import OfferedPlansModal from "../../../screens/settings/components/offered-plans/components/offered-plans-modal/offered-plans-modal";
import {useFetchPaymentPlans} from "../../../services/api/settings";
import {UserMenuDropdown} from "./user-menu-dropdown";
import {isSomething} from "../../../utils";

interface UserMenuPropsType {
  isUserTypeAdmin: boolean;
}

const UserMenu = (props: UserMenuPropsType) => {
  const {isUserTypeAdmin} = props;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [plansData, setPlansData] = useState<any | null>(null);
  const data = useFetchPaymentPlans();
  const productPlans = R.pathOr([], ["data", "data", "results"], data);
  const open = Boolean(anchorEl);

  const {user, appType} = useSelector((state: RootState) => state.auth);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const getUserInitials = (text: string | undefined) => {
    return text ? text.slice(0, 1) : "";
  };

  const avatarTextNameInitials = `${getUserInitials(
    user.first_name,
  )}${getUserInitials(user.last_name)}`;

  const avatarText = isSomething(avatarTextNameInitials)
    ? avatarTextNameInitials
    : getUserInitials(user.email);

  return (
    <Box className={classes.root}>
      <Box>
        <IconButton onClick={handleOpenMenu} size="large">
          <Box className={classes.userContainer}>
            <Avatar className={classes.avatar} id="avatar-text">
              {avatarText}
            </Avatar>
            <Box className={classes.ellipses}>
              <DescriptionStyled color="tertiary" size="md" id="user-email">
                {user.email}
              </DescriptionStyled>
            </Box>
            <ArrowDropDownOutlinedIcon className={classes.icon} />
          </Box>
        </IconButton>
      </Box>

      {open && (
        <UserMenuDropdown
          open
          isUserTypeAdmin={isUserTypeAdmin}
          setAnchorEl={setAnchorEl}
          appType={appType}
          anchorEl={anchorEl}
          setPlansData={setPlansData}
          productPlans={productPlans}
          avatarText={avatarText}
          user={user}
        />
      )}
      {plansData && (
        <OfferedPlansModal
          handleClose={() => setPlansData(null)}
          productPlans={plansData}
        />
      )}
    </Box>
  );
};

export default UserMenu;
